import { useEffect } from "react";
import { useCanvasContext } from "../../../contexts/canvasContext";

const UseOnDragOrbitEffect = (): void => {
    const { data, d3Data } = useCanvasContext();

    useEffect(() => {
        if (d3Data?.g) {
            d3Data.g
                .selectAll(".orbit")
                .on(".dragover", (e: React.DragEvent) => {
                    e.preventDefault();
                })
                .on("mouseup.drag", () => {});
        }

    }, [data.objects]);
};

export default UseOnDragOrbitEffect;
