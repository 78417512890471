/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable flowtype/require-valid-file-annotation */
import { checkEmailFormat, isEmpty } from "@3edges/utils/dist/utils";
import { DocumentNode } from "@apollo/client";
import bcryptjs from 'bcryptjs';
import sha256 from 'crypto-js/sha256';
import { REACT_ENV } from "environmentVariables";
import { forIn, isArray, isObject } from "lodash";
// @flow

export const checkCompanyFormat = (value: string): boolean => {
    const result = value.match(/[a-z]/gi);
    return result && result.length >= 3
};

export function setNameUppercase (value, regexFrom: any = null, regexTo: any = null) {
    value = value?.toUpperCase() as string;
    value = value.replace(/ /gu, '');
    value = value.replace(/_/gu, '-');

    if (regexFrom && regexTo) {
        value = value.replace(regexFrom, regexTo);
    }

    return value;
}

export function setNameLowerCase (value, regexFrom: any = null, regexTo: any = null) {
    value = value?.toLowerCase() as string;
    value = value.replace(/ /gu, '-');
    value = value.replace(/_/gu, '-');

    if (regexFrom && regexTo) {
        value = value.replace(regexFrom, regexTo);
    }

    return value;
}

export const getGqlString = (doc: DocumentNode): any => {
    return doc.loc && doc.loc.source.body;
}

export const hashCode = async (value) => {
    const salt = await bcryptjs.genSaltSync(10);
    return await bcryptjs.hash(value, salt);
}

export const isUrlHttpsValid = (urlString) =>
{
    try {
        const url = new URL(urlString);

        if (!url.protocol.includes("https")) {
            return false
        }

        return true
    } catch {
        return false;
    }
}

export const hashCodeSHA256 = (value) => {
    return sha256(value);
}

export const compareHash = async (originalPassword, hashedPassword) =>
{
    return await bcryptjs.compare(originalPassword, hashedPassword);
}

export const getCookieDomain = () => {
    return window.location.hostname.includes(REACT_ENV.REACT_APP_DOMAIN) ? `.${REACT_ENV.REACT_APP_DOMAIN}` : window.location.hostname
}

const wingspan = 50

export const setCurve = (counter: number): number => {
    return counter % 2 === 1 ? counter * wingspan : counter * -wingspan
}

export const setCurveMap = (relatedTo: string, _id: string, curve: number): [string, number] => {
    return [
        _id < relatedTo ? _id + relatedTo : relatedTo + _id,
        curve < 0 ? curve * -1 + wingspan : (curve + wingspan) * -1
    ]
}

export const setCurveFields = (curveMap: any, relatedTo: string, _id: string): number => {
    return Number(curveMap.get(_id < relatedTo ? _id + relatedTo : relatedTo + _id)) || wingspan;
}

export const validateEmail = (value: string): typeof errors => {
    let errors = "";

    if (!value) {
        errors = "Cannot be blank";
    } else if (!checkEmailFormat(value)) {
        errors = "Invalid email format";
    }
    return errors;
};

export const titleCase = (str: string): string => {
    if (isEmpty(str)) {
        return "";
    }

    if (str.includes("@")) {
        return str;
    }

    const splitStr = str.replace("-", " ").toLowerCase().split(" ");

    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);
    }

    return splitStr.join(" ");
};

export type ArrayElement<ArrayType extends readonly unknown[]> =
    // eslint-disable-next-line @typescript-eslint/no-type-alias
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const isOwner = (data, currUser): boolean => {
    let isOwner = false;

    if (!isOwner && data && data.owners && data.owners.length > 0) {
        isOwner = data.owners.some(o => o.email === currUser.email);
    }

    if (!isOwner && data && data.administrators && data.administrators.length > 0) {
        isOwner = data.administrators.some(o => o.email === currUser.email);
    }

    return isOwner
};

export const isAdminOnThisDataserver = (administrators = [], currUser): any => {
    return administrators && administrators.some((admin) => admin.email === currUser.email)
}

export const omitDeep = (obj: Record<string, unknown>, keyToOmit: string): void => {
    forIn(obj, (value, key) => {
        if (isObject(value)) {
            omitDeep(value as Record<string, unknown>, keyToOmit);
        } else if (key === keyToOmit) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete obj[key];
        } else if (isArray(value)) {
            value.forEach((el: Record<string, unknown>) => {
                omitDeep(el, keyToOmit);
            });
        }
    });
};

export const generateNonceAndStateOIDC = async () => {
    const arrayNonce = new Uint32Array(10);
    const valueNonce = crypto.getRandomValues(arrayNonce).toString().replace(/,/ug, "")
    const nonce = await hashCode(valueNonce)

    const arrayState = new Uint32Array(10);
    const valueState = crypto.getRandomValues(arrayState).toString().replace(/,/ug, "")
    const state = await hashCode(valueState)

    return { nonce, state }
}

export const generatePKCEOIDC = async () => {
    function dec2hex(dec) {
        return ('0' + dec.toString(16)).slice(-2)
      }

    function generateRandomString() {
        const array = new Uint32Array(56/2);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec2hex).join('');
    }

    const code_verifier = generateRandomString();

    function sha256(plain) { // returns promise ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);
        return window.crypto.subtle.digest('SHA-256', data);
      }

    function base64urlencode(a) {
        let str = "";
        const bytes = new Uint8Array(a);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          str += String.fromCharCode(bytes[i]);
        }
        return btoa(str)
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=+$/, "");
    }

    async function challenge_from_verifier(v) {
      const hashed = await sha256(v);
      const base64encoded = base64urlencode(hashed);
      return base64encoded;
    }

    const code_challenge = await challenge_from_verifier(code_verifier);

    return { code_verifier, code_challenge }
}
