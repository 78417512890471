/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-multi-comp */
import { toastError, toastSuccess } from "@3edges/utils/dist/toastify";
import { generateRandomString, isEmpty } from "@3edges/utils/dist/utils";
import { useMutation } from "@apollo/client";
import { IconButton, TextField } from "@material-ui/core";
import { Cached as CachedIcon } from '@material-ui/icons';
import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import { Title } from "components/PanelServer/styled";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "ui-components/modal";
import { ADMIN_SET_PWD } from "../../../../gql";

interface ModalProps {
    userID: string;
    isModalSetPWD: boolean;
    onClose: () => void;
}

export default function ModalSetPWD ({ isModalSetPWD, onClose, userID }: ModalProps): ReactElement {
    const { t } = useTranslation();
    const [setNewPWD] = useMutation(ADMIN_SET_PWD);
    const [pwd, setPWD] = useState("")

    const saveNewPWD = (): void => {
        setNewPWD({
            variables: {
                pwd,
                userID
            }
        }).then(({ errors }: any) =>
        {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }
                return;
            }

            toastSuccess(t("root:btn.done"));
            onClose();
        });
    }

    function RandomPWD() {
        return (
            <IconButton onClick={() => setPWD(generateRandomString())} title="Random string">
                <CachedIcon />
            </IconButton>
        )
    }

    return (
        <Modal
            isOpen={isModalSetPWD}
            onClose={() => {
                onClose();
            }}
            style={{ width: "600px" }}
        >
            <div>
                <Title>
                    {t("adminPage:choosePWD.title")}
                </Title>

                <TextField
                        autoFocus
                        value={pwd}
                        style={{ marginTop: 20, width: "100%" }}
                        InputProps={{ endAdornment: <RandomPWD /> }}
                        onChange={(e) => {
                            setPWD(e.target.value);
                        }}
                        label={t("adminPage:field.password")}
                    />

                <ButtonListFlex>
                    <ConfirmButton onClick={() => saveNewPWD()} disabled={isEmpty(pwd)}>
                        {t("adminPage:btnSave")}
                    </ConfirmButton>
                </ButtonListFlex>
            </div>

        </Modal>
    );
}
