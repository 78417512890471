/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";

interface State {
    url: string;
    isUrlValid: boolean | null;
}

export function UrlField(props: any): React.ReactElement
{
    const { label, value, isValid = null, onChange = undefined, inputProps = undefined, autoFocus = undefined, onBlur = undefined } = props

    const [values, setValues] = useState<State>({
        url: '',
        isUrlValid: null,
    });

    useMemo(() => {
        setValues({ ...values, url: value })
    }, [value])

    useEffect(() => {
        setValues({ ...values, isUrlValid: isValid })
    }, [isValid])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, url: event.target.value });
        onChange(event)
    };

    return (
        <FormControl variant="outlined" style={{ marginBottom: 10, width: '100%' }}>
            <InputLabel htmlFor="outlined-adornment-url">{label}</InputLabel>

            <OutlinedInput
                autoFocus={autoFocus}
                type="text"
                value={values.url}
                label={label}
                inputProps={inputProps}
                onBlur={onBlur}
                data-cy={props["data-cy"] || null}
                onChange={handleChange}
                endAdornment={
                    <InputAdornment position="end">
                        {values.isUrlValid != null &&
                            <IconButton onClick={() => {}} edge="end">
                                {values.url && values.isUrlValid != null ? values.isUrlValid ? <CheckCircle style={{ fill: 'darkgreen' }} /> : <Cancel style={{ fill: 'darkred' }} /> : ""}
                            </IconButton>
                        }
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}
