/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-multi-comp */
import { toastError, toastInfo, toastWarning } from "@3edges/utils/dist/toastify";
import { checkEmailFormat, isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { TextFieldProps } from "@material-ui/core";
import logo from "assets/images/vertical-logo.png";
import NewVerificationCode from "components/NewVerificationCode";
import { REACT_ENV } from "environmentVariables";
import { PageContainer } from "pages/Canvas/styled";
import { LoginBox, LoginCenteredContainer } from "pages/Login/styled";
import publicIP from "public-ip";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { InputLabelStyled } from "ui-components/form/styled";
import { PwdField } from "ui-components/pwdField";
import { TextField } from "ui-components/styleds";
import HorizontalLinearStepper from "../../components/Wizard";
import { PRIM_CHECK_IF_USER_ALREADY_EXISTS, PRIM_CREATE_NEW_USER } from "./gql";
import { Form } from "./styled";

interface Step1Props {
    name: string;
    email: string;
    setName: (name: string) => void;
    setEmail: (email: string) => void;
    setStepAsValid: (state: boolean) => void;
    beforeNext: () => void;
}

function Step1({ email, setEmail, name, setName, setStepAsValid, beforeNext }: Step1Props): React.ReactElement {
    const { t } = useTranslation();
    const [isValidEmail, setIsValidEmail] = useState(false);

    const onChange: TextFieldProps["onChange"] = (e): void => {
        const { value } = e.target;

        setEmail(value);
        setIsValidEmail(checkEmailFormat(value));
    };

    useEffect(() => {
        setStepAsValid(isValidEmail);

    }, [isValidEmail]);

    useEffect(() => {
        setIsValidEmail(checkEmailFormat(email));

    }, []);

    return (
        <Form>
            <TextField
                    autoFocus
                    value={name}
                    name="name"
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    data-cy="registerPage_step1_name"
                    label={t("createNewUser:step1.field.name")}
                />

            <TextField
                    value={email}
                    name="email"
                    type="email"
                    onChange={onChange}
                    data-cy="registerPage_step1_email"
                    label={t("createNewUser:step1.field.email")}
                    onBlur={() =>
                        {
                            if(!isValidEmail && email !== ""){
                                toastWarning(t("createNewUser:step1.field.incorrect.email"));
                            }
                        }}
                    onKeyDown={(e) => {
                        if ((e.key === 'Enter') && isValidEmail) {
                            beforeNext();
                            e.preventDefault();
                        }
                        else if ((e.key === 'Enter') && !isValidEmail && email !== "") {
                            toastWarning(t("createNewUser:step1.field.incorrect.email"));
                        }
                    }}
                />
        </Form>
    );
}

interface Step2Props {
    setStepAsValid: (state: boolean) => void;
    pwd: string;
    setPwd: (pass: string) => void;
}

function Step2({ setStepAsValid, pwd, setPwd }: Step2Props): React.ReactElement {
    const { t } = useTranslation();
    const [confirmPwd, setConfirmPwd] = useState("");

    // eslint-disable-next-line
    const [isValidPassword, setIsValidEmail] = useState(false);
    const [showWrongPWD, setShowWrongPWD] = useState(false);

    const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
    const [isValidCaptcha, setIsValidCaptcha] = useState(false);

    useEffect(() => {
        setStepAsValid(isValidCaptcha && isValidPassword);

    }, [isValidCaptcha, isValidPassword]);

    const onChange = (value: string): void => {
        setIsValidCaptcha(Boolean(value));
    };

    useEffect(() => {
        setShowWrongPWD(false);

        setIsValidEmail(pwd === confirmPwd && pwd !== "");
        if (pwd !== confirmPwd || pwd === "") {
            setIsValidCaptcha(false);
        }

        if (isNotEmpty(pwd) && isNotEmpty(confirmPwd) && pwd !== confirmPwd) {
            setShowWrongPWD(true);
        }
    }, [pwd, confirmPwd]);

    useEffect(() => {
        setIsCaptchaVisible(isValidPassword);
    }, [isValidPassword]);

    return (
        <Form>
            <PwdField
                    autoFocus
                    value={pwd}
                    data-cy="registerPage_step2_password"
                    onChange={(e) => {
                        setPwd(e.target.value);
                    }}
                    label={t("createNewUser:step2.field.password")}
                />

            <PwdField
                    value={confirmPwd}
                    data-cy="registerPage_step2_confirm_password"
                    onChange={(e) => {
                        setConfirmPwd(e.target.value);
                    }}
                    label={t("createNewUser:step2.field.confirm.password")}
                />

            {showWrongPWD && <div>{t("createNewUser:step2.field.confirm.password.wrong")}</div>}

            {isCaptchaVisible && (
                <div style={{ marginTop: 50 }}>
                    <ReCAPTCHA
                        onChange={onChange}
                        sitekey={REACT_ENV.REACT_APP_CAPTCHA_V2}
                    />
                </div>
            )}
        </Form>
    );
}
interface Step3Props {
    name: string;
    email: string;
    pwd: string;
}
function Step3({ email, name, pwd }: Step3Props): React.ReactElement {
    const { t } = useTranslation();
    const history = useHistory();
    const [createUser] = useMutation(PRIM_CREATE_NEW_USER);
    const [ip, setIP] = useState("");

    useEffect(() => {
        async function getIP(): Promise<void> {
            const vIP = await publicIP.v4();
            setIP(vIP);
        }
        void getIP();
    }, []);

    useEffect(() => {
        if (isNotEmpty(ip)) {
            void createUser({
                variables: {
                    name,
                    email,
                    password: pwd,
                    ip,
                    emailOptions: {
                        subject: t("createNewUser:step3.emailOptions.subject"),
                        html: t("createNewUser:step3.emailOptions.html"),
                        text: t("createNewUser:step3.emailOptions.text")
                    }
                }
            }).then(({ errors }: any) =>
            {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }
                    return;
                }

                setTimeout(() => {
                    history.push("/");
                }, 5000);
            });
        }

    }, [ip]);
    return (
        <Form>
            <div>
                <InputLabelStyled data-cy="registerPage_step3_message">
                    {t("createNewUser:step3.content")}
                </InputLabelStyled>
            </div>
        </Form>
    );
}

function CreateNewUser(): React.ReactElement {
    const history = useHistory();
    const { t } = useTranslation();

    const [checkEmail] = useLazyQuery(PRIM_CHECK_IF_USER_ALREADY_EXISTS);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
    const [activeStep, setActiveStep] = React.useState(0);
    const [steps, setSteps] = useState([
        { id: 0, title: t("createNewUser:step1.title"), isValid: false },
        { id: 1, title: t("createNewUser:step2.title"), isValid: false },
        { id: 3, title: t("createNewUser:step3.title") }
    ]);

    const onBeforeNext = (): void => {
        // eslint-disable-next-line unicorn/prefer-switch
        if (currentStep === 0) {
            void checkEmail({
                variables: {
                    email
                }
            }).then(({ data }) => {
                const { isVerified, isExpiredVerifyCode } = data.checkIfUserAlreadyExists;
                if (isVerified) {
                    toastWarning(t("createNewUser:email.already.exists"));
                    return;
                }

                if (isExpiredVerifyCode) {
                    toastInfo(() => <NewVerificationCode email={email} />);
                }

                if (isVerified === null && isExpiredVerifyCode === null) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    if (!isVerified) {
                        toastWarning(t("createNewUser:email.already.exists"));
                        return;
                    }

                    if (!isExpiredVerifyCode) {
                        toastInfo(() => <NewVerificationCode email={email} />);
                        return;
                    }
                }

                setSteps([...steps]);
            });
        } else if (currentStep === 1) {
            setSteps([...steps]);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    const setStepAsValid = (data: boolean): void => {
        steps[currentStep].isValid = data;
        setSteps([...steps]);
    };
    return (
        <PageContainer>
            <LoginCenteredContainer width="50%">
                <img
                    src={logo}
                    alt={t("root:project.logo")}
                    width="auto"
                    height="160"
                    onClick={() => {
                        history.push("/");
                    }}
                    onKeyPress={() => {}}
                    style={{ marginBottom: 30, cursor: "pointer" }}
                />

                <div style={{ fontSize: 25, height: 50, fontWeight: "bold" }}>
                    {t("auth:create-new-user.title")}
                </div>

                <LoginBox>
                    <HorizontalLinearStepper
                        activeStep={activeStep}
                        beforeNext={onBeforeNext}
                        onActiveStep={setCurrentStep}
                        setActiveStep={setActiveStep}
                        steps={steps}
                    >
                        {currentStep === 0 && (
                            <Step1
                                beforeNext={onBeforeNext}
                                name={name}
                                email={email}
                                setEmail={setEmail}
                                setName={setName}
                                setStepAsValid={setStepAsValid}
                            />
                        )}

                        {currentStep === 1 && <Step2 pwd={pwd} setPwd={setPwd} setStepAsValid={setStepAsValid} />}

                        {currentStep === 2 && <Step3 email={email} name={name} pwd={pwd} />}
                    </HorizontalLinearStepper>
                </LoginBox>
            </LoginCenteredContainer>
        </PageContainer>
    );
}
export default CreateNewUser;
