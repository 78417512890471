/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { clearStorage, delStorage, setStorage } from "cache";
import { REACT_ENV } from "environmentVariables";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { generateNonceAndStateOIDC, generatePKCEOIDC } from "utils";
import { removeAllCookiesNoRerender } from "utilsFn";

function Login (): React.ReactElement {
    const location = useLocation();

    const urlParams = new URLSearchParams(location.search);
    const redirectURL = urlParams.get('redirectURL');

    useEffect(() =>
    {
        clearStorage();
        removeAllCookiesNoRerender();

        getAuthorizeOIDC()
        setStorage("isShowDashboard", true);
        setStorage("isShowModeList", false);
        delStorage("tmp-email");
        delStorage(REACT_ENV.REACT_APP_SOCIAL_PROVIDER_LOCAL_STORAGE_NAME);
    }, []);

    const getAuthorizeOIDC = async () => {
        const { state, nonce } = await generateNonceAndStateOIDC()
        const { code_verifier, code_challenge } = await generatePKCEOIDC()

        setStorage("code_verifier", code_verifier);
        setStorage("state", state);

        if (redirectURL) {
            setStorage("redirectURL", redirectURL);
        }

        const OIDCUrl = `${REACT_ENV.REACT_APP_OIDC_URL}${REACT_ENV.REACT_APP_OIDC_AUTH_ENDPOINT}`
        const client_id = REACT_ENV.REACT_APP_OIDC_CLIENT_ID
        const redirect_uri = `${window.location.origin}/code`
        const scope = 'openid profile offline_access'
        const response_type = 'code'
        const response_mode = 'query'
        const code_challenge_method = 'S256'

        const url = `${OIDCUrl}?client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&response_type=${response_type}&response_mode=${response_mode}&code_challenge_method=${code_challenge_method}&code_challenge=${code_challenge}&state=${state}&nonce=${nonce}`
        window.location.replace(url);
    }

    return null;
}

export default Login;
