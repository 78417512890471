import { useMutation } from "@apollo/client";
import { ContextMenuContext } from "contexts/rightClickContext";
import * as d3 from "d3";
import { useContext, useEffect, useState } from "react";
import { useCanvasContext } from "../../../contexts/canvasContext";
import { DataPoint } from "../Zoom/types";
import { restartSwiftSimulation } from "../simulation";
import { SAVE_ORBIT_POSITION } from "./gql";

export const useDrag = (): void => {
    const { enters, d3Data, data, setData } = useCanvasContext();
    const contextMenuState = useContext(ContextMenuContext);

    const [initialPosition, setInitialPosition] = useState(null)
    const [finalPosition, setFinalPosition] = useState(null)
    const [saveOrbitPosition] = useMutation(SAVE_ORBIT_POSITION);

    useEffect(() => {
        if (d3Data.g) {
            d3Data.g.selectAll(".orbit").call(d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended));

            d3Data.g.selectAll("textPath").on("mouseover", (e) => {
                const isPetal = e.target.__data__.source?._id === e.target.__data__.target?._id
                const currentLink = d3Data.g.select(`#relationshipID_${e.target.__data__._id}`)
                currentLink.select('path').classed(isPetal ? "linkHoverPetal" : "linkHover", true);
            });

            d3Data.g.selectAll("textPath").on("mouseleave", (e) => {
                const isPetal = e.target.__data__.source?._id === e.target.__data__.target?._id
                const currentLink = d3Data.g.select(`#relationshipID_${e.target.__data__._id}`)
                currentLink.select('path').classed(isPetal ? "linkHoverPetal" : "linkHover", false);
            });
        }

    }, [
        data.objects,
        data.interfaces,
        data.scripts,
        data.customMutations,
        data.tags,
        data.enums,
        data.nodeRelationships
    ]);

    let hasDraged = false;

    const dragged = (event: DataPoint["source"], d: DataPoint["target"]): void => {
        contextMenuState.setContextMenuState({
            visibility: false
        });

        if (!hasDraged) {
            d3Data.g.attr("cursor", "grabbing");
            hasDraged = true;
        }

        d.x = event.x;
        d.y = event.y;

        d.fx = clamp(event.x, -9999, d.x);
        d.fy = clamp(event.y, -9999, d.y);

        d3Data.g.selectAll(".orbit").call(enters.updateOrbit);
        d3Data.g.selectAll(".link").call(enters.updateLink);
    };

    const dragstarted = (d): void => {
        setInitialPosition(d)
    }

    const dragended = (d): void => {
        setFinalPosition(d)
    };

    useEffect(() => {
        if (initialPosition && finalPosition) {
            d3Data.g.attr("cursor", "default");

            if (initialPosition.x !== finalPosition.x || initialPosition.y !== finalPosition.y) {
                if (hasDraged) {
                    restartSwiftSimulation(d3Data.simulation);
                    hasDraged = false;
                }

                // Save on DB
                saveOrbitPosition({
                    variables: {
                        orbitID: finalPosition.subject._id,
                        x: finalPosition.x,
                        y: finalPosition.y
                    }
                })

                // Overwrite NodeRelationships positions
                if (finalPosition.subject.__typename === "NiamNodeRelationship") {
                    const currentNR: any = data.nodeRelationships.map(nr => {
                        if (nr._id === finalPosition.subject._id) {
                            nr.relation.rel.x = finalPosition.x
                            nr.relation.rel.y = finalPosition.y
                        }

                        return nr
                    })

                    setData({ ...data, nodeRelationships: currentNR })
                }

            }

        }
    }, [finalPosition])
};

function clamp (x: number, lo: number, hi: number): number {
    return x < lo ? lo : x > hi ? hi : x;
}
