import { useMutation } from "@apollo/client";
import { useData } from "contexts/dataContext";
import { ContextMenuContext } from "contexts/rightClickContext";
import * as d3 from "d3";
import { useContext, useEffect } from "react";
import { RPOrbitData } from "typedefs";
import { useCanvasContext } from "../../../contexts/canvasContext";
import { useOnOrbitsRightClick } from "../RightClickHandlers/useOnOrbitsRightClick";
import { applyZoom } from "../Zoom";
import { ApplyZoomReturnType } from "../Zoom/types";
import useZoomOnLinks from "../Zoom/useZoomOnLinks";
import useZoomOnNodes from "../Zoom/useZoomOnNodes";
import dataFormatter from "../dataFormatter";
import { setupSimulation, updateSimulationWithPhysics } from "../simulation";
import { iCleanedData, iD3Selection, iData } from "../types";
import { getShown } from "../utils";
import { useDrag } from "./d3Drag";
import { SAVE_ORBIT_COLLECTION_POSITIONS } from "./gql";
import useOnDragOrbitEffect from "./useOnDragOrbitEffect";

interface ConfigD3DataParams {
    container: d3.Selection<SVGSVGElement, unknown, null, undefined>;
    zoomOnNodesInstance: (event: React.MouseEvent, parent: RPOrbitData) => void;
    zoomOnLinksInstance: ApplyZoomReturnType["zoomOnLinksInstance"];
    setEnablePhysicsEffect?: any;
    enablePhysicsEffect?: boolean;
    saveOrbitCollectionPositions?: any;
    cleanedData: iCleanedData;
    g: d3.Selection<SVGGElement, unknown, null, undefined>;
    enters: {
        enterOrbit: (selection: iD3Selection) => void;
        updateOrbit: (selection: iD3Selection) => void;
        enterLink: (selection: iD3Selection) => void;
        updateLink: (selection: iD3Selection) => void;
    };
}

const configD3Data = ({
    cleanedData,
    g,
    enters,
    zoomOnNodesInstance,
    setEnablePhysicsEffect,
    enablePhysicsEffect,
    container,
    zoomOnLinksInstance,
    saveOrbitCollectionPositions
}: ConfigD3DataParams): {
    d3Links: d3.Selection<SVGGElement, unknown, SVGGElement, unknown>;
    d3ObjectNodes: d3.Selection<SVGGElement, unknown, SVGGElement, unknown>;
    simulation: d3.Simulation<unknown, undefined>;
} => {
    const newData = {
        objects: [...cleanedData.primObjects],
        interfaces: [...cleanedData.primInterfaces],
        scripts: [...cleanedData.primScripts],
        customMutations: [...cleanedData.primCustomMutations],
        tags: [...cleanedData.primTags],
        enums: [...cleanedData.primEnums],
        nodeRelationships: [...cleanedData.primNodeRelationships],
        implementLinks: [...cleanedData.primImplements],
        paths: [...cleanedData.primPaths],
        relLinks: [...cleanedData.primPropertiesRelLinks]
    } as iData;

    const orbitShown = getShown([
        ...newData.objects,
        ...newData.interfaces,
        ...newData.scripts,
        ...newData.customMutations,
        ...newData.tags,
        ...newData.enums,
        ...newData.nodeRelationships
    ]);

    const linkShown = getShown([...newData.implementLinks, ...newData.relLinks, ...newData.paths]).reverse();

    const simulation = setupSimulation(orbitShown, linkShown);

    const d3Nodes = g
        .selectAll(".orbit")
        .data(
            orbitShown as unknown as RPOrbitData[],
            (node: { _id: string }) => node._id
        )
        .enter()
        .append("g")
        .call(enters.enterOrbit)
        .on("click", zoomOnNodesInstance);

    const d3Links = g
        .selectAll(".link")
        .data(
            linkShown,
            (node: { _id: string }) => node._id
        )
        .enter()
        .append("g")
        .call(enters.enterLink)
        .on("click", zoomOnLinksInstance);

    d3Nodes.exit().remove();
    d3Links.exit().remove();

    simulation.on("tick", () => {
        container.selectAll(".orbit").call(enters.updateOrbit);
        container.selectAll(".link").call(enters.updateLink);
    });

    if (enablePhysicsEffect) {
        updateSimulationWithPhysics(simulation, orbitShown, linkShown, saveOrbitCollectionPositions, setEnablePhysicsEffect, enablePhysicsEffect);
    }

    return {
        d3Links,
        d3ObjectNodes: d3Nodes,
        simulation
    };
};

interface UseSetupD3Params {
    ref: React.MutableRefObject<SVGSVGElement>;
    isShowDashboard?: boolean;
    setEnablePhysicsEffect?: boolean;
    enablePhysicsEffect?: boolean;
}

let firstLoad = false

export const useSetupD3 = ({ ref, isShowDashboard, setEnablePhysicsEffect, enablePhysicsEffect }: any): void => {
    const contextMenuState = useContext(ContextMenuContext);
    const { enters, setD3Data, setData, setContainer, data, d3Data } = useCanvasContext();
    const { server: dataCollection, setZoom, setServer } = useData();
    const [saveOrbitCollectionPositions] = useMutation(SAVE_ORBIT_COLLECTION_POSITIONS);

    const zoomOnNodes = useZoomOnNodes();
    const zoomOnLinks = useZoomOnLinks();

    useOnDragOrbitEffect();
    useDrag();

    useOnOrbitsRightClick(isShowDashboard);

    useEffect(() => {
        if (dataCollection) {
            const cleanedData = dataFormatter(dataCollection, data, enablePhysicsEffect, firstLoad);

            if (ref.current) {
                const containerWidth = ref.current.clientWidth;
                const containerHeight = ref.current.clientHeight;
                const container = d3.select(ref.current);
                container.selectAll("g").remove();

                const g = container.append("g");
                setContainer(container);

                const { zoomOnNodesInstance, zoomOnLinksInstance } = applyZoom({
                    g,
                    height: containerHeight,
                    width: containerWidth,
                    setZoom,
                    container,
                    zoomOnNodes,
                    zoomOnLinks,
                    contextMenuState
                });

                const { d3ObjectNodes, simulation, d3Links }: any = configD3Data({
                    cleanedData,
                    g,
                    container,
                    enters,
                    setEnablePhysicsEffect,
                    enablePhysicsEffect,
                    zoomOnNodesInstance,
                    zoomOnLinksInstance,
                    saveOrbitCollectionPositions
                });

                setData({
                    relLinks: cleanedData.primPropertiesRelLinks,
                    objects: cleanedData.primObjects,
                    scripts: cleanedData.primScripts,
                    customMutations: cleanedData.primCustomMutations,
                    tags: cleanedData.primTags,
                    enums: cleanedData.primEnums,
                    nodeRelationships: cleanedData.primNodeRelationships,
                    interfaces: cleanedData.primInterfaces,
                    implementLinks: cleanedData.primImplements,
                    paths: cleanedData.primPaths
                });

                setD3Data({
                    g,
                    nodes: d3ObjectNodes,
                    simulation,
                    links: d3Links,
                    zoomOnLinksInstance,
                    zoomOnNodesInstance
                });
            }

            firstLoad = true
        }
    }, [ref, dataCollection, isShowDashboard, enablePhysicsEffect]);
};
