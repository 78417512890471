import { toastError, toastSuccess } from "@3edges/utils/dist/toastify";
import { useLazyQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import { PRIM_GET_NEW_VERIFICATION_CODE } from "pages/CreateNewUser/gql";
import React from "react";
import { useTranslation } from "react-i18next";

interface NewVerificationCodeProps {
    email: string;
}

function NewVerificationCode({ email }: NewVerificationCodeProps): React.ReactElement {
    const [getNewCode] = useLazyQuery(PRIM_GET_NEW_VERIFICATION_CODE);
    const { t } = useTranslation();

    const getNewVerificationCode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        void getNewCode({
            variables: {
                email,
                emailOptions: {
                    subject: t("createNewUser:step3.emailOptions.subject"),
                    html: t("createNewUser:step3.emailOptions.html"),
                    text: t("createNewUser:step3.emailOptions.text")
                }
            }
        }).then(({ errors }: any) =>
        {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }
                return;
            }
            toastSuccess(t("createNewUser:AccountValidation.resendNewCode"));
        });
    };

    return <Button onClick={getNewVerificationCode}>{t("createNewUser:AccountValidation.DoYouNeedNewCode")}</Button>;
}

export default NewVerificationCode;
