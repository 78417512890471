/* eslint-disable react/react-in-jsx-scope */
import { toastError } from "@3edges/utils/dist/toastify";
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ListItem } from "@material-ui/core";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType, EUpdateType, NiamEnum } from "components/PrimGraphicalCanvas/types";
import { useUpdateD3Items } from "components/PrimGraphicalCanvas/updateD3Items";
import { useData } from "contexts/dataContext";
import { GraphQLError } from "graphql";
import { uniqueId } from "lodash";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "ui-components/styleds";
import { RP_DELETE_ENUM, RP_GET_ENUM, RP_UPDATE_ENUM } from "./gql";
import {
    Divider,
    Form,
    LoadingIconStyled,
    RightPanelAddIconStyled,
    RightPanelDeleteIconStyled,
    RightPanelLoadingStyled,
    RightPanelXIconStyled,
    StyledEnumInput,
    StyledList
} from "./styled";

interface IEnumData {
    niamEnum: NiamEnum;
    updateNiamEnum?: NiamEnum;
}

interface IResProps {
    data?: IEnumData;
    errors: readonly GraphQLError[];
}

interface IEnumerate {
    name: string;
    id: string;
}

function RpEnum({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (value: boolean) => void }): ReactElement {
    const { server, setItemSelected, itemSelected } = useData();
    const onDelete = useDeleteD3Items();
    const onUpdate = useUpdateD3Items();
    const [getData, { data, loading }] = useLazyQuery<IEnumData>(RP_GET_ENUM, { fetchPolicy: "no-cache" });

    const [name, setName] = useState<string>(undefined);
    const [displayName, setDisplayName] = useState<string>(undefined);
    const [enumerates, setEnumerates] = useState<IEnumerate[]>([]);
    const [enumName, setEnumName] = useState<string>("");

    const { t } = useTranslation();
    const [updateEnum, { loading: updLoading }] = useMutation(RP_UPDATE_ENUM);
    const [deleteEnum] = useMutation(RP_DELETE_ENUM);

    const updatedFormProperties = useRef({ enumerates: [] });
    const addedOrRemovedEnum = useRef(false);

    const isComponentUnMounted = useRef(false);

    useEffect(() => {
        // standard procedure for processing componentDidUnmount action
        // here, we set isComponentUnMounted.current flag to true
        return () => {
            isComponentUnMounted.current = true;
        }
    }, []);

    const turnToUnsaved = (): void => {
        setItemSelected({ ...itemSelected, hasSaved: false })
    };

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { _id: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    useEffect(() => {
        if (data && !loading) {
            setName(data.niamEnum.name);
            setDisplayName(data.niamEnum.displayName);

            const loadedEnums = data.niamEnum?.enumerates.map((en) => ({
                name: en,
                id: uniqueId()
            }));

            setEnumerates(loadedEnums);

            updatedFormProperties.current = { ...updatedFormProperties.current, enumerates: [...loadedEnums] };
        }
    }, [loading, data]);

    const addNewEnumerate = (): void => {
        if (enumName.replaceAll(' ', '').length > 0 && !enumerates.some((en) => en.name === enumName.toUpperCase())) {
            const newEnumData = { name: enumName.toUpperCase(), id: uniqueId() };

            setEnumerates([...enumerates, newEnumData]);

            updatedFormProperties.current = { ...updatedFormProperties.current, enumerates: [...enumerates, newEnumData] };
            addedOrRemovedEnum.current = true;
        }

        turnToUnsaved();
        setEnumName("");

        handleOnBlurOrSaveEvent();
    };

    const handleOnBlurOrSaveEvent = (event?: any): void => {
        if (isOpen && !itemSelected.hasSaved || addedOrRemovedEnum.current) {
            updateEnum({
                variables:
                    name === data.niamEnum.name
                        ? {
                              serverID: server._id,
                              _id: data.niamEnum._id,
                              displayName,
                              enumerates: updatedFormProperties.current.enumerates.map((en) => en.name)
                          }
                        : {
                              serverID: server._id,
                              _id: data.niamEnum._id,
                              name,
                              displayName,
                              enumerates: updatedFormProperties.current.enumerates.map((en) => en.name)
                          }
            }).then(({ errors, data: dataUpdated }: any) => {
                if (errors) {
                    for (const e of errors)
                    {
                        if (e.message.includes("NAME_ALREADY_IN_USE"))
                        {
                            toastError(t(`validations:${e.message}`) + displayName);
                            setName(itemSelected.data.displayName)
                        } else
                        {
                            toastError(t(`validations:${e.message}`));
                        }
                    }

                    return;
                }

                onUpdate({
                    id: data.niamEnum._id,
                    type: EUpdateType.ENUM,
                    newData: dataUpdated.updateNiamEnum,
                    rPData: itemSelected.data
                });

                if (isComponentUnMounted.current === false) {
                    setName(dataUpdated.updateNiamEnum.name);
                    setDisplayName(dataUpdated.updateNiamEnum.displayName);

                    itemSelected.data.displayName = dataUpdated.updateNiamEnum.displayName;
                    setItemSelected({ ...itemSelected, hasSaved: true });
                }
            });
        }
    }

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading || updLoading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <Form>
                <div data-cy="cypressHiddenDiv" />

                <TextField
                    data-cy="rpEnumPage_name"
                    label={t("rightPanel:tag.input.name.label")}
                    onChange={(e) => {
                        setName(e.target.value);
                        setDisplayName(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurOrSaveEvent(e);
                    }}
                    value={name || ""}
                />

                <StyledList>
                    <div className="title">
                        {t("rightPanel:enumerates.title")}
                    </div>

                    {enumerates.map((item) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <ListItem key={item.id.toString()}>
                            <StyledEnumInput
                                defaultValue={item.name}
                                onChange={({ target }) => {
                                    setEnumerates(
                                        enumerates.map((en) =>
                                            item.id === en.id
                                                ? {
                                                      ...en,
                                                      name: target.value.toUpperCase()
                                                  }
                                                : { ...en }
                                        )
                                    );

                                    updatedFormProperties.current = {
                                        ...updatedFormProperties.current,
                                        enumerates: enumerates.map((en) => item.id === en.id ? { ...en, name: target.value.toUpperCase() } : { ...en })
                                    };

                                    turnToUnsaved();
                                }}
                                onBlur={(e) => {
                                    handleOnBlurOrSaveEvent(e);
                                }}
                            />

                            <RightPanelXIconStyled
                                onClick={() => {
                                    setEnumerates([...enumerates.filter((en) => en !== item)]);

                                    updatedFormProperties.current = { ...updatedFormProperties.current, enumerates: [...enumerates.filter((en) => en !== item)] };
                                    addedOrRemovedEnum.current = true;

                                    turnToUnsaved();

                                    handleOnBlurOrSaveEvent();
                                }}
                            />
                        </ListItem>
                    ))}

                    <div className="inputEnum">
                        <StyledEnumInput
                            data-cy="rpEnumPage_addNewValue_name"
                            placeholder={t("rightPanel:addNewValue.enum")}
                            value={enumName}
                            onChange={({ target }) => {
                                setEnumName(target.value);
                            }}
                        />

                        <RightPanelAddIconStyled
                            data-cy="rpEnumPage_addNewValue_btnSave"
                            onClick={() => {
                                addNewEnumerate();
                            }}
                        />
                    </div>
                </StyledList>

                <Divider />

                <RightPanelDeleteIconStyled
                    onClick={() => {
                        setIsOpen(false);

                        deleteEnum({
                            variables: {
                                _id: data.niamEnum._id
                            }
                        }).then(async (res) =>
                            res.data.deleteNiamEnum.deletedFieldsIDs.forEach((field) => {
                                onDelete({
                                    id: field,
                                    type: EDeleteType.RELATIONSHIP
                                });
                            })
                        ).then((res) => {
                            onDelete({
                                id: data.niamEnum._id,
                                type: EDeleteType.ENUM
                            });
                        });
                    }}
                />
            </Form>
        </>
    );
}

export default RpEnum;
