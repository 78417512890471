import { Tooltip, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Edit as EditIcon, PeopleOutlineOutlined } from "@material-ui/icons";
import styled from "styled-components";

export const Edit = styled(EditIcon)`
    fill: #bd8e0f;
`;

export const ChangeRole = styled(PeopleOutlineOutlined)`
    fill: #2699fb;
`;

export const ChangeRoleContainer = styled.div<{ rolesView: boolean }>`
    position: relative;
    display: flex;

    .roles {
        border: 1px solid #aaaaaa;
        border-radius: 3px;
        min-width: 140px;
        background: #ffffff;
        z-index: 3;
        transform: translate(-45%, 30px);
        position: absolute;
        display: flex;
        flex-direction: column;
        visibility: ${(props) => (props.rolesView ? "visible" : "hidden")};
    }
`;

export const RoleItem = styled.span`
    text-align: center;
    padding: 2px;
    font-weight: 300;

    &:hover {
        cursor: pointer;
        font-weight: 400;
        background: #e3e3e3;
    }
`;

export const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 5px;

    .editProfile {
        position: absolute;
    }

    svg {
        cursor: pointer;
        width: 24px;
        height: 24px;
    }
`;

export const StyledTooltip = withStyles({
    tooltipPlacementTop: {
        margin: "3px 0"
    }
})(Tooltip);

export const StyledButton = styled(Button)`
    margin-left: 10px !important;
    min-width: unset !important;
    padding: unset !important;
`;
