import styled from "styled-components";

export const Body = styled.div`
    width: 100vw;
    padding: 48px 213px 80px 120px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.background};
    flex: 0;
    display: flex;
    flex-direction: column;
`;

export const TitleContainer = styled.header`
    width: 164px;
    height: 37px;
    font-family: IBM Plex Sans;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
`;

export const FlexList = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px 0px;
    margin: 0px -15px 0px 0px;
    box-sizing: border-box;
`;
