/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable flowtype/require-valid-file-annotation */
import { base64_decode, base64_encode, stringToBase64 } from "@3edges/utils/dist/utils";
import axios from "axios";
import { getStorage, setStorage } from "cache";
import { getCookie } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import Cookies from "js-cookie";
import JWT from 'jsonwebtoken';
import { JwksClient, TokenValidator } from "lib";
import { OIDC, OIDCProps, OIDCResponse } from "types/cache";
import { Role } from "types/operation-result-types";
import { compareHash, generateNonceAndStateOIDC, getCookieDomain, hashCode } from "utils";
// @flow

export enum Clouds {
    LOCALHOST = "Localhost",
    GCP = "Google",
    AWS = "Amazon",
    CUSTOM = "Custom"
}

export const authToken = stringToBase64(`${REACT_ENV.REACT_APP_OIDC_CLIENT_ID}:${REACT_ENV.REACT_APP_OIDC_CLIENT_PWD}`);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const OIDC_Logout = async (currentCookieIdToken) =>
{
    window.location.href = `${REACT_ENV.REACT_APP_OIDC_URL}/session/end?id_token_hint=${base64_decode(currentCookieIdToken)}&post_logout_redirect_uri=${REACT_ENV.REACT_APP_URL_UI}/login`
};

export const verifyTokenHandler = async (token) => {
    try {
        const tokenValidator = new TokenValidator(
            { clientOptions: { clientId: REACT_ENV.REACT_APP_OIDC_CLIENT_ID } },
            REACT_ENV.REACT_APP_OIDC_URL,
            new JwksClient({
                jwksUri: REACT_ENV.REACT_APP_JWKS_URI,
                idpUrl: REACT_ENV.REACT_APP_OIDC_URL,
                REACT_APP_PKEY_COOKIE_NAME: REACT_ENV.REACT_APP_PKEY_COOKIE_NAME
            })
        );

        const verifiedToken = await tokenValidator.claim(token);

        return { status: "SUCCESS", verifiedToken };
    } catch (error) {
        return { status: "ERROR", error: error.name, message: error.message };
    }
}

export const checkToken = async (token) => {
    return await verifyTokenHandler(token)
};

export const oidcProvider = async ({ email, password }: OIDCProps): Promise<OIDCResponse> => {

    const generateNonce = async () => {
        const array = new Uint32Array(10);
        const value = crypto.getRandomValues(array).toString().replace(/,/ug, "")
        const hashed = await hashCode(value);

        const now = new Date();
        now.setTime(now.getTime() + 5 * 60 * 1000); // 5 minutes

        Cookies.set(REACT_ENV.REACT_APP_NONCE_COOKIE_NAME, hashed, {
            path: "/",
            expires: now,
            domain: getCookieDomain(),
            secure: window.location.protocol === 'https:' ? true : false,
            sameSite: 'strict',
        });

        return value
    }

    const getToken = async () => {
        try {
            const hashedOtp = await generateNonce()

            const params = new URLSearchParams();
            params.append("grant_type", "password");
            params.append("username", email);
            params.append("password", password);
            params.append("scope", "openid profile custom");
            params.append("naming", "email");
            params.append("sub_type", "NiamUser");
            params.append("nonce", hashedOtp);

            const res = await axios.post(`${REACT_ENV.REACT_APP_OIDC_URL}/token`, params, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Basic ${authToken}`
                 }
            });

            const { id_token, access_token, refresh_token }: OIDC = res.data
            const { payload: { nonce } } = JWT.decode(id_token, { complete: true });

            const cookieNonce = getCookie(REACT_ENV.REACT_APP_NONCE_COOKIE_NAME)
            const sameNonce = await compareHash(nonce, cookieNonce);

            if (!sameNonce) {
                Cookies.remove(REACT_ENV.REACT_APP_NONCE_COOKIE_NAME)
                Cookies.remove(REACT_ENV.REACT_APP_PKEY_COOKIE_NAME)

                return {
                    OIDCData: null,
                    status: false,
                    data: null,
                    error: 'OIDC.error.invalid.credentials'
                };
            }

            const OIDCData = {
                refresh_token,
                access_token,
                id_token
            }

            return OIDCData;

            // eslint-disable-next-line no-empty
        } catch (error) {
            return error;
        }
    };

    const OIDCData = await getToken();
    const data = await checkToken(OIDCData.id_token);

    if (data.status === "SUCCESS") {
        return {
            OIDCData,
            status: true,
            data,
            error: null
        };
    }

    Cookies.remove(REACT_ENV.REACT_APP_NONCE_COOKIE_NAME)
    Cookies.remove(REACT_ENV.REACT_APP_PKEY_COOKIE_NAME)

    return {
        OIDCData: null,
        status: false,
        data: null,
        error: 'OIDC.error.invalid.credentials'
    };
};

export enum Cluster {
    LOCALHOST = "localhost",
    QA = "qaCluster",
    THREEEDGES = "3edgesCluster",
    NEWCLUSTER = "newCluster"
}

export const isAdmin = (): boolean => {
    const currentUser = getStorage<any>("user");
    return currentUser.roles?.includes(Role.ADMIN);
};

export const isSuperAdmin = (): boolean => {
    const currentUser = getStorage<any>("user");
    return currentUser.roles?.includes(Role.SUPER_ADMIN);
};

export const refreshTokenExchange = async (refresh_token: string): Promise<boolean> => {
    const params = new URLSearchParams();

    params.append("client_id", REACT_ENV.REACT_APP_OIDC_CLIENT_ID);
    params.append("client_secret", REACT_ENV.REACT_APP_OIDC_CLIENT_PWD);
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", refresh_token);
    params.append("scope", "openid profile offline_access");

    try {
        const res = await axios.post(`${REACT_ENV.REACT_APP_OIDC_URL}/token`, params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
             }
        });

        if(res.data.error) {
            return false
        }

        const { id_token, access_token, refresh_token } = res.data

        if(!id_token || !access_token || !refresh_token) {
            return false
        }

        const { payload } = JWT.decode(id_token, { complete: true });
        const { nonce } = await generateNonceAndStateOIDC()

        setStorage("user", {
            ...payload
        });

        const now = Number.parseInt((Date.now() / 1000).toString());
        const minutes = Math.round(payload.exp - now) / 60;

        const nowTokens = new Date();
        nowTokens.setTime(nowTokens.getTime() + minutes * 60 * 1000);

        const nowNonce = new Date();
        nowNonce.setTime(nowNonce.getTime() + 5 * 60 * 1000); // 5 minutes

        Cookies.set(REACT_ENV.REACT_APP_NONCE_COOKIE_NAME, nonce, {
            path: "/",
            expires: now,
            domain: getCookieDomain(),
            secure: window.location.protocol === 'https:' ? true : false,
            sameSite: 'strict',
        });

        await checkToken(id_token)

        Cookies.set(REACT_ENV.REACT_APP_ID_TOKEN_COOKIE_NAME, base64_encode(id_token), {
            path: "/",
            expires: nowTokens,
            domain: getCookieDomain(),
            secure: window.location.protocol === 'https:' ? true : false,
            sameSite: 'strict',
        });

        Cookies.set(REACT_ENV.REACT_APP_ACCESS_TOKEN_COOKIE_NAME, access_token, {
            path: "/",
            expires: nowTokens,
            domain: getCookieDomain(),
            secure: window.location.protocol === 'https:' ? true : false,
            sameSite: 'strict',
        });

        setStorage(REACT_ENV.REACT_APP_REFRESH_TOKEN_LOCAL_STORAGE_NAME ? REACT_ENV.REACT_APP_REFRESH_TOKEN_LOCAL_STORAGE_NAME : "default_nid_r", refresh_token);

        return true
    } catch {
        return false
    }
}

export const removeAllCookiesNoRerender = (): void => {
    const cookiesUI = new Set([REACT_ENV.REACT_APP_ACCESS_TOKEN_COOKIE_NAME, REACT_ENV.REACT_APP_ID_TOKEN_COOKIE_NAME, REACT_ENV.REACT_APP_NONCE_COOKIE_NAME, REACT_ENV.REACT_APP_PKEY_COOKIE_NAME])
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.slice(0, Math.max(0, eqPos)) : cookie;

        // if (!cookiesUI.has(name.trim())) {
        //     continue
        // }

        Cookies.remove(name.trim(), {
            path: "/",
            domain: getCookieDomain()
        });
    }
};
