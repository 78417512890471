import { gql } from "@apollo/client";

export const UPDATE_TO_RELEASED_APIS = gql`
  mutation updateToReleasedEachApis{
    updateToReleasedEachApis
  }
`

export const UPDATE_TO_RELEASED_SPECIFIC_API = gql`
  mutation updateToReleasedSpecificWorkload($clientNameGCP: String!) {
    updateToReleasedSpecificWorkload(input: { clientNameGCP: $clientNameGCP}) {
      status
    }
  }
`
