import { toastError } from "@3edges/utils/dist/toastify";
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MenuItem } from "@material-ui/core";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType, EUpdateType } from "components/PrimGraphicalCanvas/types";
import { useUpdateD3Items } from "components/PrimGraphicalCanvas/updateD3Items";
import { useCanvasContext } from "contexts/canvasContext";
import { useData } from "contexts/dataContext";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    NiamRelationshipTypeEnum,
    rPPropertyRelationshipGet,
    rPUpdateRelationship
} from "types/operation-result-types";
import { TextField } from "ui-components/styleds";
import { RP_DELETE_RELATIONSHIP, RP_GET_PROPERTY_RELATIONSHIP, RP_UPDATE_RELATIONSHIP } from "./gql";
import {
    Divider,
    Form,
    LoadingIconStyled,
    RightPanelBreadCrumbStyled,
    RightPanelDeleteIconStyled,
    RightPanelLoadingStyled,
    SelectFieldStyled
} from "./styled";

interface RPPropertyRelationshipProps {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}

function RPPropertyRelationship({ isOpen, setIsOpen }: RPPropertyRelationshipProps): React.ReactElement {
    const { d3Data } = useCanvasContext();
    const { server, setItemSelected, itemSelected  } = useData();
    const onDelete = useDeleteD3Items();
    const onUpdate = useUpdateD3Items();

    const [getData, { data, called, loading }] = useLazyQuery<rPPropertyRelationshipGet>(RP_GET_PROPERTY_RELATIONSHIP, { fetchPolicy: "no-cache" });
    const [name, setName] = useState<string>(undefined);
    const [displayName, setDisplayName] = useState<string>(undefined);
    const [eventType, setEventType] = useState<NiamRelationshipTypeEnum>();

    const { t } = useTranslation();

    const [updateRelationship, { loading: updLoading }] = useMutation<rPUpdateRelationship>(RP_UPDATE_RELATIONSHIP);
    const [deleteRel] = useMutation(RP_DELETE_RELATIONSHIP);

    const isComponentUnMounted = useRef(false);

    useEffect(() => {
        // standard procedure for processing componentDidUnmount action
        // here, we set isComponentUnMounted.current flag to true
        return () => {
            isComponentUnMounted.current = true;
        }
    }, []);

    const turnToUnsaved = (): void => {
        setItemSelected({ ...itemSelected, hasSaved: false })
    };

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { relID: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    useEffect(() => {
        if (data && !loading) {
            setName(data.niamRelationship.name);
            setDisplayName(data.niamRelationship.displayName);
            setEventType(data.niamRelationship.eventType);
        }
    }, [loading, called]);

    const handleOnBlurEvent = (event: any): void => {
        updateRelationship({
            variables: {
                relID: itemSelected.data._id,
                scriptID: itemSelected.data.type === "PropertyRelationship" && itemSelected.data.parent._id,
                relatedTo: itemSelected.data.type === "PropertyRelationship" && itemSelected.data.relatedTo._id,
                params: {
                    displayName,
                    name,
                    eventType
                }
            }
        }).then(({ errors, data: dataUpdated }: any) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            onUpdate({
                id: data.niamRelationship._id,
                type: EUpdateType.RELATIONSHIP,
                newData: dataUpdated.updateNiamRel,
                rPData: itemSelected.data
            });

            if (isComponentUnMounted.current === false) {
                setName(dataUpdated.updateNiamRel.name);
                setDisplayName(dataUpdated.updateNiamRel.displayName);
                setEventType(dataUpdated.updateNiamRel.eventType);

                itemSelected.data.displayName = dataUpdated.updateNiamRel.displayName
                setItemSelected({ ...itemSelected, hasSaved: true });
            }
        });
    }

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading || updLoading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <RightPanelBreadCrumbStyled
                $isShowing={isOpen}
                onClick={(event) => {
                    d3Data.zoomOnNodesInstance(
                        event,
                        itemSelected.data.type === "PropertyRelationship" && itemSelected.data.parent
                    );
                }}
            >
                {itemSelected.data.type === "PropertyRelationship" && itemSelected.data.parent.displayName}
            </RightPanelBreadCrumbStyled>

            <Form>
                <div data-cy="cypressHiddenDiv" />

                <TextField
                    data-cy="rpPropertyRelationshipPage_name"
                    label={t("rightPanel:property.input.name.label")}
                    value={name || ""}
                    onChange={(e) => {
                        setName(e.target.value);
                        setDisplayName(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                />

                <SelectFieldStyled
                    data-cy="rpPropertyRelationshipPage_eventType"
                    value={eventType || ""}
                    label={t("rightPanel:property.input.eventType.label")}
                    onChange={(event) => {
                        setEventType(event.target.value as NiamRelationshipTypeEnum);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                >
                    {Object.keys(NiamRelationshipTypeEnum).map((relationshipType) => (
                        <MenuItem key={`relationshipType-${relationshipType}`} data-cy={`relationshipType-${relationshipType}`} value={relationshipType}>
                            {relationshipType}
                        </MenuItem>
                    ))}
                </SelectFieldStyled>

                <Divider />

                <RightPanelDeleteIconStyled
                    onClick={() => {
                        setIsOpen(false);

                        deleteRel({
                            variables: {
                                relID: data.niamRelationship._id
                            }
                        }).then(({ errors }: any) => {
                            if (errors) {
                                for (const e of errors) {
                                    toastError(t(`validations:${e.message}`));
                                }
                                return;
                            }

                            onDelete({
                                id: data.niamRelationship._id,
                                type: EDeleteType.RELATIONSHIP
                            });
                        });
                    }}
                />
            </Form>
        </>
    );
}

export default RPPropertyRelationship;
