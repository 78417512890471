import { zoom } from "d3-zoom";
import { iZoom } from "../types";
import { ApplyZoomParams, ApplyZoomReturnType } from "./types";

export const applyZoom = ({
    setZoom,
    width,
    height,
    g,
    container,
    zoomOnNodes,
    zoomOnLinks,
    contextMenuState
}: ApplyZoomParams): ApplyZoomReturnType => {
    const zoomed = ({ transform }): void => {
        contextMenuState.setContextMenuState({
            visibility: false
        });

        setZoom(transform as iZoom);
        g.attr("transform", transform as string);
    };

    const zoomInstance = zoom()
        .extent([
            [0, 0],
            [width, height]
        ])
        .scaleExtent([0.1, 3])
        .on("zoom", zoomed);

    const zoomOnNodesInstance = zoomOnNodes({
        container,
        height,
        width,
        zoomInstance
    });

    const zoomOnLinksInstance = zoomOnLinks({
        container,
        height,
        width,
        zoomInstance
    });

    container.call(zoomInstance);

    return {
        zoomOnNodesInstance,
        zoomOnLinksInstance
    };
};
