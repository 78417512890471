/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable prefer-const */
/* eslint-disable unicorn/prefer-negative-index */
/* eslint-disable no-loop-func */
import { toastError, toastWarning } from "@3edges/utils/dist/toastify";
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { setDefaultName } from "components/PrimGraphicalCanvas/d3UseEffects/draw";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType, EUpdateType } from "components/PrimGraphicalCanvas/types";
import { useUpdateD3Items } from "components/PrimGraphicalCanvas/updateD3Items";
import { useCanvasContext } from "contexts/canvasContext";
import { useData } from "contexts/dataContext";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import
    {
        NiamFieldTypeEnum,
        rPAddFieldToObject as RPAddFieldToObject,
        rPObjectGet as RPObjectGet,
        rPUpdateObject as RPUpdateObject
    } from "types/operation-result-types";
import { TextField } from "ui-components/styleds";
import RPPropertyBox from "./RpPropertyBox";
import { RP_ADD_FIELD_TO_OBJECT, RP_DELETE_OBJECT, RP_GET_OBJECT, RP_UPDATE_OBJECT } from "./gql";
import
    {
        Divider,
        Form,
        LoadingIconStyled,
        RPHeader,
        RPTitle,
        RightPanelAddIconStyled,
        RightPanelDeleteIconStyled,
        RightPanelLoadingStyled
    } from "./styled";

interface RPObjectProps {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}


function RPObject({ isOpen, setIsOpen }: RPObjectProps): React.ReactElement {
    const { server, setItemSelected, itemSelected } = useData();
    const onDelete = useDeleteD3Items();
    const onUpdate = useUpdateD3Items();
    const [getData, { data, loading }] = useLazyQuery<RPObjectGet>(RP_GET_OBJECT, { fetchPolicy: "no-cache" });
    const { data: dataContext, setData } = useCanvasContext();

    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [displayName, setDisplayName] = useState<string>("");

    const [fields, setFields] = useState<any>([] as any);
    const { t } = useTranslation();

    const [updateObject, { loading: updLoading }] = useMutation<RPUpdateObject>(RP_UPDATE_OBJECT);
    const [deleteObject] = useMutation(RP_DELETE_OBJECT);
    const [addFieldToObject] = useMutation<RPAddFieldToObject>(RP_ADD_FIELD_TO_OBJECT);

    const isComponentUnMounted = useRef(false);

    useEffect(() => {
        // standard procedure for processing componentDidUnmount action
        // here, we set isComponentUnMounted.current flag to true
        return () => {
            isComponentUnMounted.current = true;
        }
    }, []);

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { objectID: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    const turnToUnsaved = (): void => {
        setItemSelected({ ...itemSelected, hasSaved: false })
    };

    useEffect(() => {
        if (data && !loading) {
            setName(data.niamObject.name);
            setDescription(data.niamObject.description);
            setDisplayName(data.niamObject.displayName);
            setFields(data.niamObject.fields);
        }
    }, [loading, data]);

    const handleOnBlurEvent = (event: any): void => {
        if (isOpen && !itemSelected.hasSaved) {
            updateObject({
                variables: {
                    serverID: server._id,
                    objectID: data.niamObject._id,
                    params: { displayName, name, description }
                }
            }).then(({ errors, data: dataUpdated }: any) => {

                if (errors) {
                    for (const e of errors)
                    {
                        if (e.message.includes("NAME_ALREADY_IN_USE"))
                        {
                            toastError(t(`validations:${e.message}`) + displayName);
                            setName(itemSelected.data.displayName)
                        } else
                        {
                            toastError(t(`validations:${e.message}`));
                        }
                    }

                    return;
                }

                onUpdate({
                    id: data.niamObject._id,
                    type: EUpdateType.OBJECT,
                    newData: dataUpdated.updateNiamObject,
                    rPData: itemSelected.data
                });

                // No need to reset useState variables when this component has unmounted, namely when isComponentUnMounted.current flag is true
                if (isComponentUnMounted.current === false) {
                    setName(dataUpdated.updateNiamObject.name);
                    setDescription(dataUpdated.updateNiamObject.description);
                    setDisplayName(dataUpdated.updateNiamObject.displayName);
                    setFields(dataUpdated.updateNiamObject.fields);

                    itemSelected.data.displayName = dataUpdated.updateNiamObject.displayName
                    setItemSelected({ ...itemSelected, hasSaved: true })
                }
            });
        }
    }

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading || updLoading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <Form>
                <div data-cy="cypressHiddenDiv" />

                <TextField
                    data-cy="rpObjectPage_name"
                    label={t("rightPanel:object.input.name.label")}
                    onChange={(e) => {
                        setName(e.target.value);
                        setDisplayName(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                    value={name || ""}
                />

                <TextField
                    data-cy="rpObjectPage_description"
                    label={t("rightPanel:object.input.description.label")}
                    inputProps={{ maxLength: 2000 }}
                    onChange={(e) => {
                        if (e.target.value.length === 2000 ) {
                            toastWarning(t("rightPanel:description.length.warning"));
                        }
                        setDescription(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                    value={description || ""}
                />

                <Divider />

                <RPHeader>
                    <RPTitle>
                        Properties
                    </RPTitle>

                    <RightPanelAddIconStyled
                        data-cy="rpObjectPage_btnAddField"
                        onClick={() => {
                            const { defaultName } = setDefaultName(`Field`, null, fields);

                            void addFieldToObject({
                                variables: {
                                    NiamObjectID: itemSelected.data._id,
                                    newNiamFieldInput: {
                                        name: defaultName,
                                        displayName: defaultName,
                                        fieldType: NiamFieldTypeEnum.String,
                                        isArray: false,
                                        isNaming: false,
                                        isSecret: false,
                                        isDisplay: false,
                                        isRequired: false
                                    }
                                }
                            }).then((res: any) => {
                                setFields([...fields, res.data.addNewFieldToNiamObject]);

                                let newDataObjects = [...dataContext.objects]

                                newDataObjects = newDataObjects.map(item => {
                                    if (item._id === itemSelected.data._id) {
                                        item.fields.push(res.data.addNewFieldToNiamObject)
                                    }

                                    return item
                                })

                                const newData = {
                                    ...dataContext,
                                    objects: newDataObjects
                                }

                                setData(newData)
                            });
                        }}
                    />
                </RPHeader>

                {data?.niamObject.interfaces.map((niamInterface) =>
                    niamInterface.fields.map((field) => {
                        if (field.fieldType === "Relationship") {
                            return null;
                        }
                        return (
                            <RPPropertyBox
                                canEdit={false}
                                canDelete={false}
                                field={field}
                                key={`ObjectField_${itemSelected.data._id}_${field._id}_from_${niamInterface._id}`}
                                orbitData={{
                                    __typename: itemSelected.data.__typename,
                                    _id: itemSelected.data._id,
                                    displayName: itemSelected.data.displayName,
                                    type: "Object",
                                    x: 0,
                                    y: 0
                                }}
                            />
                        );
                    })
                )}

                {fields.map((field) => {
                    if (field.fieldType === "Relationship") {
                        return null;
                    }
                    return (
                        <RPPropertyBox
                            canDelete
                            field={field}
                            key={`ObjectField_${itemSelected.data._id}_${field._id}`}
                            orbitData={{
                                __typename: itemSelected.data.__typename,
                                _id: itemSelected.data._id,
                                displayName: itemSelected.data.displayName,
                                type: "Object",
                                x: 0,
                                y: 0
                            }}
                            refetch={({ id }) => {
                                const nFields = [...fields.filter((f) => f._id !== id)];
                                setFields(nFields);
                            }}
                        />
                    );
                })}

                <Divider />

                <RightPanelDeleteIconStyled
                    onClick={() => {
                        setIsOpen(false);

                        deleteObject({
                            variables: {
                                ObjectID: data.niamObject._id
                            }
                        }).then(({ errors }: any) => {
                            if (errors) {
                                for (const e of errors) {
                                    toastError(t(`validations:${e.message}`));
                                }
                                return;
                            }

                            onDelete({
                                id: data.niamObject._id,
                                type: EDeleteType.OBJECT
                            });
                        });
                    }}
                />
            </Form>
        </>
    );
}

export default RPObject;
