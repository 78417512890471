import JWT from 'jsonwebtoken';
import { JwksClient } from "./jwksClient";
import { TokenClaimsValidator } from './tokenClaimsValidator';
import { JwtError } from "./types/customErrors";
import { AccessToken, IdToken, TokenValidatorOptions } from "./types/types";

export class TokenValidator {
    private readonly options: TokenValidatorOptions;

    private readonly providerUrl: string;

    public constructor (options: TokenValidatorOptions, providerUrl: string, private readonly client: JwksClient) {
        this.options = options;
        this.providerUrl = providerUrl;
    }

    /**
     * Decode the provided token, whether using AM or the OIDC Provider.
     * @param token {string} - token to validate
     * @returns {Promise<IdToken | AccessToken>} - decoded token
     */
    private async decode (token: string): Promise<IdToken | AccessToken> {
        // eslint-disable-next-line unicorn/better-regex
        const isIdToken = token.match(/.*[.].*[.].*/gm);

        if (isIdToken) {
            const { header: { kid } } = JWT.decode(token, { complete: true });

            const key = await this.client.getSigningKey(kid);
            const signingKey = key.publicKey || key.rsaPublicKey;

            const decoded = new Promise<IdToken>((resolve, reject) => {
                JWT.verify(token, signingKey, (err, decodedJwt) => {
                    if (err || !decodedJwt) {
                        reject(new JwtError(err.message || "Jwt malformed"));
                    }

                    resolve((decodedJwt as unknown) as IdToken);
                });
            });

            return decoded;
        }

        throw new JwtError("Invalid token");
    }

    /**
     * Validate the claims of the provided token.
     * @param token {IdToken} - token to verify
     * @returns {IdToken | AccessToken} - validated token
     */
    private verify (token: IdToken | AccessToken): IdToken | AccessToken {
        TokenClaimsValidator.validateIssuer(token, this.providerUrl);
        TokenClaimsValidator.validateAudience(token, this.options.clientOptions.clientId);
        TokenClaimsValidator.validateAlg(token);
        TokenClaimsValidator.validateTimestamps(token, this.options.iatTtl);

        return token;
    }

    /**
     * Decode and fully validate provided token.
     * @param token {IdToken} - token to validate
     * @returns {Promise<IdToken | AccessToken>} - validated token
     */
    public async claim (token: string): Promise<IdToken | AccessToken> {
        return this.verify(await this.decode(token));
    }
}
