/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-multi-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-newline */
/* eslint-disable jsx-a11y/no-autofocus */
import { toastError, toastSuccess } from "@3edges/utils/dist/toastify";
import { decryptText, stringToBase64 } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import axios from "axios";
import { AlertDSName, ConfirmButton } from "components/ModalDataServer/styled";
import { SwitchBoxStyled, SwitchButton, SwitchLabelStyled } from "components/RightPanel/styled";
import { REACT_ENV } from "environmentVariables";
import { Form } from "pages/CreateNewUser/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OIDCProviderEnum } from "typedefs";
import { Modal } from "ui-components/modal";
import { PwdField } from "ui-components/pwdField";
import { TextField } from "ui-components/styleds";
import { GET_CREDENTIALS_INFO, SAVE_API_KEY_DISABLED_ENABLED } from "./gql";

interface ModalCredentialsInfoProps {
    isConnectionValid: boolean;
    isModalOpen: boolean;
    OIDC_PROVIDER: string;
    serverID?: string;
    OIDC_URL?: string;
    randomName: string;
    setIsModalOpen: (obj: any) => void;
}

export default function ModalCredentialsInfo ({ OIDC_URL, OIDC_PROVIDER, isConnectionValid, randomName, isModalOpen, serverID, setIsModalOpen }: ModalCredentialsInfoProps): React.ReactElement {
    const { t } = useTranslation();

    const [apiKey, setApiKey] = useState("");
    const [apiEnabled, setApiEnabled] = useState(false);
    // const [superUserID, setSuperUserID] = useState("");
    const [niamSvcAcct, setNiamSvcAcct] = useState({ username: "", pwd: "" });
    const [niamSvcAcctClient, setNiamSvcAcctClient] = useState({ client_id: "", client_secret: "", ENC_KEY: "" });
    const [authz3edgesCredentials, setAuthz3edgesCredentials] = useState({ client_id: "", client_secret: "" });
    const [proxy3edgesCredentials, setProxy3edgesCredentials] = useState({ client_id: "", client_secret: "" });
    const [authzCredentials, setAuthzCredentials] = useState({ client_id: "", client_secret: "" });
    const [proxyCredentials, setProxyCredentials] = useState({ client_id: "", client_secret: "" });
    const [getCredentialsInfo, { data, loading }] = useLazyQuery(GET_CREDENTIALS_INFO, { fetchPolicy: "no-cache" });
    const [expanded, setExpanded] = React.useState(false);
    const [expandedApi, setExpandedApi] = React.useState(false);
    // const [saveSuperUserID] = useMutation(SAVE_SUPER_USER_ID);
    const [saveApiKeyDisabledEnabled] = useMutation(SAVE_API_KEY_DISABLED_ENABLED);

    useEffect(() => {
        if (isModalOpen) {
            getCredentialsInfo({ variables: { serverID } })
        } else {
            setExpanded(false)
            setExpandedApi(false)
        }
    }, [isModalOpen]);

    useEffect(() =>
    {
        if (data) {
            const {
                apiKey: ak,
                // superUserID: suID,
                apiEnabled: ae,
                niamSvcAcct,
                authz3edgesID, authz3edgesPWD,
                proxy3edgesID, proxy3edgesPWD,
                authzClientID, authzClientPWD,
                proxyClientID, proxyClientPWD,
                niamSvcAcctClient
            } = data.getCredentialsInfo

            if (ak) {
                setApiKey(ak)
                // setSuperUserID(suID)
                setApiEnabled(ae)
            }

            if (niamSvcAcct) {
                const pwdChanged = decryptText(niamSvcAcct.pwd, REACT_ENV.REACT_APP_INTERNAL_SECRET)
                setNiamSvcAcct({ username: decryptText(niamSvcAcct.username, REACT_ENV.REACT_APP_INTERNAL_SECRET), pwd: pwdChanged })
            }

            if (niamSvcAcctClient) {
                const pwdClientChanged = decryptText(niamSvcAcctClient.client_secret, REACT_ENV.REACT_APP_INTERNAL_SECRET)
                const enc_keyChanged = decryptText(niamSvcAcctClient.ENC_KEY, REACT_ENV.REACT_APP_INTERNAL_SECRET)
                setNiamSvcAcctClient({ client_id: decryptText(niamSvcAcctClient.client_id, REACT_ENV.REACT_APP_INTERNAL_SECRET), client_secret: pwdClientChanged, ENC_KEY: enc_keyChanged })
            }

            if (authz3edgesID && authz3edgesPWD) {
                setAuthz3edgesCredentials({ client_id: authz3edgesID, client_secret: decryptText(authz3edgesPWD, REACT_ENV.REACT_APP_INTERNAL_SECRET) })
            }

            if (proxy3edgesID && proxy3edgesPWD) {
                setProxy3edgesCredentials({ client_id: proxy3edgesID, client_secret: decryptText(proxy3edgesPWD, REACT_ENV.REACT_APP_INTERNAL_SECRET) })
            }

            if (authzClientID && authzClientPWD) {
                setAuthzCredentials({ client_id: authzClientID, client_secret: decryptText(authzClientPWD, REACT_ENV.REACT_APP_INTERNAL_SECRET) })
            }

            if (proxyClientID && proxyClientPWD) {
                setProxyCredentials({ client_id: proxyClientID, client_secret: decryptText(proxyClientPWD, REACT_ENV.REACT_APP_INTERNAL_SECRET) })
            }
        }
    }, [data])

    const onGetAccessToken = (origin, clientId, clientSecret): void => {
        const params = new URLSearchParams();
        params.append('grant_type', 'client_credentials');

        const clientCredentials = stringToBase64(`${clientId}:${clientSecret}`)

        const idpAddress = origin === "3edges" ?
            `${REACT_ENV.REACT_APP_OIDC_URL}${REACT_ENV.REACT_APP_OIDC_TOKEN_ENDPOINT}` :
            `${OIDC_URL}${REACT_ENV.REACT_APP_OIDC_TOKEN_ENDPOINT}`

        axios.post(idpAddress, params, {
            headers: {
                Authorization: `Basic ${clientCredentials}`
            }
        }).then((res) => {
            const { data } = res

            if (data.access_token && data.refresh_token)
            {
                navigator.clipboard.writeText(data.access_token)
                toastSuccess("Copied to clipboard!");
            } else {
                toastError("Something went wrong, please try to Update your Schema");
            }

        }).catch(error => {
            if(error?.response?.data?.message) {
                console.log(error?.response?.data?.message);
            } else if (error?.response?.data?.error_description) {
                console.log(error?.response?.data?.error_description);
            } else {
                console.log(error.message);
            }
            toastError("Something went wrong, please try to Update your Schema");
        });
    }

    if (loading) {
        return null;
    }

    // const BtnSaveField = ({ disabled, dataCy }: any) => {
    //     return (
    //         <IconButton edge="end" disabled={disabled} data-cy={dataCy || null} onClick={async () => {
    //             await saveSuperUserID({ variables: { serverID, superUserID } })
    //             toastSuccess(t("root:btn.done"));
    //         }}>
    //             <SaveIcon />
    //         </IconButton>
    //     )
    // }

    return (
        <Modal
            dataCy="modal_btnCloseCredentials"
            isOpen={isModalOpen}
            onClose={() => {
                setIsModalOpen({
                    isOpen: false,
                    dbValid: isConnectionValid
                });
            }}
            style={{ width: "600px" }}
        >
            <h1>{!serverID ? t("panel:header.btn.tenant.info") : t("panel:header.btn.apiserver.info")}</h1>

            <AlertDSName $color="gray" $noMargin>
                <h3>Deployment ID: {randomName}</h3>
            </AlertDSName>

            <h3>
                - {t("panel:apiKey.title")}
            </h3>

            <SwitchBoxStyled style={{ padding: '0 0 20px 0', width: 'fit-content' }}>
                <SwitchButton
                    data-cy="modalCredentialsInfo_apiEnabled"
                    checked={apiEnabled}
                    onChange={async (event) =>
                    {
                        setApiEnabled(event.target.checked);
                        saveApiKeyDisabledEnabled({ variables: { serverID, apiEnabled: event.target.checked } })
                    }}
                />

                <SwitchLabelStyled style={{ cursor: 'pointer' }} onClick={() => {
                    const currentValue = !apiEnabled
                    setApiEnabled(currentValue)
                    saveApiKeyDisabledEnabled({ variables: { serverID, apiEnabled: currentValue } })
                }}>
                    <b>
                        {t("panel:apiKey.isOn")}
                    </b>
                </SwitchLabelStyled>
            </SwitchBoxStyled>

            <PwdField
                data-cy="modalCredentialsInfo_apikey"
                label={t("panel:apiKey.basic")}
                value={apiKey || t("panel:apiKey.notfound")}
                inputProps={{ readOnly: true }}
                disabled={!apiEnabled}
            />

            {/* <TextField
                data-cy="modalCredentialsInfo_superUserID"
                label={t("panel:apiKey.super-user")}
                value={superUserID || ""}
                InputProps={{ endAdornment: <BtnSaveField disabled={!apiEnabled} dataCy="modalCredentialsInfo_superUserID_btn" /> }}
                onChange={(e) => {
                    setSuperUserID(e.target.value)
                }}
                disabled={!apiEnabled}
            /> */}

            {OIDC_PROVIDER === OIDCProviderEnum.THREEEDGES && (
                <>
                    {niamSvcAcct.username && (
                        <>
                            <h3>
                                - {t("panel:niamSvcAcct.title")}
                            </h3>

                            <TextField
                                label={t("panel:niamSvcAcct.username")}
                                value={niamSvcAcct.username || t("panel:niamClient.notfound")}
                                inputProps={{ readOnly: true }}
                            />
                            <PwdField
                                label={t("panel:niamSvcAcct.pwd")}
                                value={niamSvcAcct.pwd || t("panel:niamClient.notfound")}
                                inputProps={{ readOnly: true }}
                            />

                            {/* {niamSvcAcctClient.client_id && (
                                <>
                                    <h3>
                                        - {t("panel:niamSvcAcctClient.title")}
                                    </h3>
                                    <TextField
                                        label={t("panel:niamSvcAcctClient.client_id")}
                                        value={niamSvcAcctClient.client_id || t("panel:niamSvcAcctClient.notfound")}
                                        inputProps={{ readOnly: true }}
                                    />
                                    <PwdField
                                        label={t("panel:niamSvcAcctClient.client_secret")}
                                        value={niamSvcAcctClient.client_secret || t("panel:niamSvcAcctClient.notfound")}
                                        inputProps={{ readOnly: true }}
                                    />
                                    <PwdField
                                        label={t("panel:niamSvcAcctClient.enc_key")}
                                        value={niamSvcAcctClient.ENC_KEY || t("panel:niamSvcAcctClient.notfound")}
                                        inputProps={{ readOnly: true }}
                                    />
                                </>
                            )} */}
                        </>
                    )}

                    {/* <h3>
                        - {t("panel:niamClient.idp.title")}
                    </h3>

                    <Accordion expanded={expanded} onChange={() => { setExpanded(!expanded) }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                                Click to expand or collapse the panel
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography component="div">
                                <Form>
                                    <TextField
                                label={t("panel:niamClient.idp.authz.id")}
                                value={authz3edgesCredentials.client_id || t("panel:niamClient.notfound")}
                                inputProps={{ readOnly: true }}
                            />
                                    <PwdField
                                label={t("panel:niamClient.idp.authz.secret")}
                                value={authz3edgesCredentials.client_secret || t("panel:niamClient.notfound")}
                                inputProps={{ readOnly: true }}
                            />
                                    <ConfirmButton onClick={() => onGetAccessToken('3edges', authz3edgesCredentials.client_id, authz3edgesCredentials.client_secret)} style={{ margin: "0 10px 30px 10px" }}>
                                        Get Access Token
                                    </ConfirmButton>

                                    <TextField
                                label={t("panel:niamClient.idp.proxy.id")}
                                value={proxy3edgesCredentials.client_id || t("panel:niamClient.notfound")}
                                inputProps={{ readOnly: true }}
                            />
                                    <PwdField
                                label={t("panel:niamClient.idp.proxy.secret")}
                                value={proxy3edgesCredentials.client_secret || t("panel:niamClient.notfound")}
                                inputProps={{ readOnly: true }}
                            />
                                    <ConfirmButton onClick={() => onGetAccessToken('3edges', proxy3edgesCredentials.client_id, proxy3edgesCredentials.client_secret)} style={{ margin: "0 10px 30px 10px" }}>
                                        Get Access Token
                                    </ConfirmButton>
                                </Form>
                            </Typography>
                        </AccordionDetails>
                    </Accordion> */}

                    {serverID && authzCredentials.client_id && proxyCredentials.client_id && isConnectionValid && (
                        <>
                            <h3>
                                - {t("panel:niamClient.api-idp.title")}
                            </h3>

                            <Accordion expanded={expandedApi} onChange={() => { setExpandedApi(!expandedApi) }}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography>
                                        Click to expand or collapse the panel
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography component="div">
                                        <Form>
                                            <TextField
                                            label={t("panel:niamClient.api-idp.authz.id")}
                                            value={authzCredentials.client_id || t("panel:niamClient.notfound")}
                                            inputProps={{ readOnly: true }}
                                        />
                                            <PwdField
                                            label={t("panel:niamClient.api-idp.authz.secret")}
                                            value={authzCredentials.client_secret || t("panel:niamClient.notfound")}
                                            inputProps={{ readOnly: true }}
                                        />
                                            <ConfirmButton onClick={() => onGetAccessToken('client', authzCredentials.client_id, authzCredentials.client_secret)} style={{ margin: "0 10px 30px 10px" }}>
                                                Get Access Token
                                            </ConfirmButton>

                                            <TextField
                                            label={t("panel:niamClient.api-idp.proxy.id")}
                                            value={proxyCredentials.client_id || t("panel:niamClient.notfound")}
                                            inputProps={{ readOnly: true }}
                                        />
                                            <PwdField
                                            label={t("panel:niamClient.api-idp.proxy.secret")}
                                            value={proxyCredentials.client_secret || t("panel:niamClient.notfound")}
                                            inputProps={{ readOnly: true }}
                                        />
                                            <ConfirmButton onClick={() => onGetAccessToken('client', proxyCredentials.client_id, proxyCredentials.client_secret)} style={{ margin: "0 10px 30px 10px" }}>
                                                Get Access Token
                                            </ConfirmButton>
                                        </Form>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
}
