/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-no-constructed-context-values */
import { useLazyQuery } from "@apollo/client";
import { GET_TENANT_BY_ID } from "components/PanelTenant/gql";
import { GET_PRIM_INFO, GET_TENANT_BY_API_ID } from "components/PrimGraphicalCanvas/gql";
import { iZoom } from "components/PrimGraphicalCanvas/types";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { IDropData, IItemSelected } from "typedefs";

interface IContextProps {
    loadingDSinfo: boolean;
    errorDSinfo: any;
    getDataServerInfo: (value: any) => void;
    reload: () => void;

    zoom: any;
    setZoom: any;
    targetData: IDropData["targetData"];
    sourceData: IDropData["sourceData"];
    setSourceData: (data) => void;
    setTargetData: (data) => void;
    isDrawing: boolean;
    setIsDrawing: (data) => void;

    setItemSelected: (value: any) => void;
    itemSelected: IItemSelected;

    loadingDS: boolean;
    setServer: (value: any) => void;
    getDataServer: (value: any) => void;
    errorDS: any;
    server: any;
    currentServerID: string;

    loadingTenant: boolean;
    setTenant: (value: any) => void;
    getTenant: (value: any) => void;
    errorTenant: any;
    tenant: any;
    currentTenantID: string;
}

const DataContext = createContext({} as IContextProps);
interface IProps {
    children: ReactNode;
}

export function DataProvider({ children }: IProps): React.ReactElement {
    const [currentTenantID, setCurrentTenantID] = useState(null)
    const [tenant, setTenant] = useState(null)
    const [currentServerID, setCurrentServerID] = useState(null)
    const [server, setServer] = useState(null)

    // Used on right panel
    const [itemSelected, setItemSelected] = useState<IItemSelected>(null)

    const [zoom, setZoom] = useState<iZoom>({ k: 1, x: 0, y: 0 });
    const [getNiamDataServerInfo, { loading: loadingDSinfo, error: errorDSinfo, data: dataDSinfo }] = useLazyQuery(GET_TENANT_BY_API_ID, { fetchPolicy: "no-cache" });
    const [getNiamDataServer, { loading: loadingDS, error: errorDS, data: dataDS, refetch: reload }] = useLazyQuery(GET_PRIM_INFO, { fetchPolicy: "no-cache" });
    const [getNiamTenant, { loading: loadingTenant, error: errorTenant, data: dataTenant }] = useLazyQuery(GET_TENANT_BY_ID, { fetchPolicy: "no-cache" });

    // Used to draw relationships
    const [sourceData, setSourceData] = useState<IDropData["sourceData"]>(undefined);
    const [targetData, setTargetData] = useState<IDropData["targetData"]>(undefined);
    const [isDrawing, setIsDrawing] = useState(false);

    useEffect(() => {
        if (dataDSinfo) {
            setServer(dataDSinfo.getTenantByApiID)

            if (dataDSinfo.getTenantByApiID.tenant) {
                const tenantID = dataDSinfo.getTenantByApiID.tenant._id
                setCurrentTenantID(tenantID)
                getTenant(tenantID)
            }
        } else {
            setServer(null)
        }
    }, [dataDSinfo]);

    useEffect(() => {
        if (dataDS) {
            setServer(dataDS.niamDataServer)

            if (dataDS.niamDataServer.tenant && !currentTenantID) {
                getTenant(dataDS.niamDataServer.tenant._id)
            }
        } else {
            setServer(null)
        }
    }, [dataDS]);

    useEffect(() => {
        if (dataTenant) {
            setTenant(dataTenant.niamTenant)
        } else {
            setTenant(null)
        }
    }, [dataTenant]);

    const getDataServer = (serverID: any) => {
        if (serverID) {
            setCurrentServerID(serverID)
            getNiamDataServer({ variables: { serverID } })
        }
    }

    const getDataServerInfo = (serverID: any) => {
        if (serverID) {
            setCurrentServerID(serverID)
            getNiamDataServerInfo({ variables: { serverID } })
        }
    }

    const getTenant = (tenantID: any) => {
        if (tenantID) {
            setCurrentTenantID(tenantID)
            getNiamTenant({ variables: { tenantID } })
        }
    }

    const values = {
        loadingDS,
        loadingTenant,
        loadingDSinfo,
        reload,
        server,
        tenant,
        getDataServerInfo,
        getDataServer,
        setServer,
        setTenant,
        getTenant,

        itemSelected,
        setItemSelected,
        zoom,
        setZoom,

        targetData,
        sourceData,
        setSourceData,
        setTargetData,
        isDrawing,
        setIsDrawing,

        errorDSinfo,
        errorDS,
        errorTenant,
        currentServerID,
        currentTenantID
    }

    return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
}

export function useData(): IContextProps {
    return useContext(DataContext);
}
