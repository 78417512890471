import { toastError } from "@3edges/utils/dist/toastify";
import { useMutation } from "@apollo/client";
import { LockOpenOutlined, VisibilityOutlined, VpnKeyOutlined } from "@material-ui/icons";
import { deleteD3Field } from "components/PrimGraphicalCanvas/deleteD3Items/field";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType, NodeTypename } from "components/PrimGraphicalCanvas/types";
import { useCanvasContext } from "contexts/canvasContext";
import { useData } from "contexts/dataContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { RPOrbitData } from "typedefs";
import { FieldsFields } from "types/operation-result-types";
import { RP_DELETE_FIELD } from "./gql";
import { RPPropBox, RPPropIcon, RPPropTitle, RPPropType, RightPanelXIconStyled } from "./styled";
interface RPPropertyBoxProps {
    field: FieldsFields;
    orbitData: RPOrbitData;
    canEdit?: boolean;
    canDelete: boolean;
    refetch?: (value: { id: string }) => void;
}

function RPPropertyBox({ field, canEdit = true, orbitData, canDelete, refetch }: RPPropertyBoxProps): React.ReactElement {
    const { setItemSelected } = useData();
    const onDelete = useDeleteD3Items();
    const { d3Data, data, setData } = useCanvasContext();

    const { t } = useTranslation();
    const [deleteField] = useMutation(RP_DELETE_FIELD);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const fieldType = `${field.isArray ? `[${field.fieldType}]` : field.fieldType === NodeTypename.NIAM_ENUM ? "Enum" : field.fieldType}${field.isRequired ? "!" : ""}`;

    const openDetails = (): void => {
        setItemSelected({
            data: {
                _id: field._id,
                type: "Property",
                parent: {
                    _id: orbitData._id,
                    __typename: orbitData.__typename,
                    displayName: orbitData.displayName,
                    type: orbitData.type,
                    x: orbitData.x,
                    y: orbitData.y
                }
            }
        });
    }

    return (
        <RPPropBox $canEdit={canEdit}>
            {field.isDisplay &&
                <RPPropIcon>
                    <VisibilityOutlined style={{ width: 18, height: 18, fill: 'orange' }} />
                </RPPropIcon>
            }

            {field.isSecret &&
                <RPPropIcon>
                    <LockOpenOutlined style={{ width: 18, height: 18, fill: 'orange' }} />
                </RPPropIcon>
            }

            {field.isNaming &&
                <RPPropIcon>
                    <VpnKeyOutlined style={{ transform: 'rotate(90deg)', width: 18, height: 18, fill: 'orange' }} />
                </RPPropIcon>
            }

            <RPPropTitle $canEdit={canEdit} data-cy={`rpPropertyBoxPage_${field.displayName}`} onClick={() => canEdit ? openDetails() : null}>
                {field.displayName}
            </RPPropTitle>

            <RPPropType>
                {fieldType}
            </RPPropType>

            {canDelete && (
                <RightPanelXIconStyled
                    onClick={() => {
                        void deleteField({ variables: { fieldID: field._id } }).then(({ errors }: any) =>
                        {
                            if (errors) {
                                for (const e of errors) {
                                    toastError(t(`validations:${e.message}`));
                                }
                                return;
                            }

                            if (refetch) {
                                refetch({ id: field._id });
                            }

                            deleteD3Field({
                                d3Data,
                                data,
                                setData,
                                fieldId: field._id
                            });

                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            if(field.fieldType === NodeTypename.NIAM_ENUM) {
                                onDelete({
                                    id: field._id,
                                    type: EDeleteType.RELATIONSHIP
                                });
                            }
                        });
                    }}
                />
            )}
        </RPPropBox>
    );
}

export default RPPropertyBox;
