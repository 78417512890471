import { toastError, toastSuccess, toastWarning } from "@3edges/utils/dist/toastify";
import { base64_encode, isEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { setStorage } from "cache";
import ModalChangePWD from "components/PanelProfile/ModalChangePwd";
import { UPDATE_PWD } from "components/PanelProfile/gql";
import { getCookie, useCookie } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import JWT from 'jsonwebtoken';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { oidcProvider } from "utilsFn";
import { PRIM_SET_AS_VERIFIED } from "./gql";

interface ActivationProps {
    withSetPwd?: boolean;
}

function Activation({ withSetPwd }: ActivationProps): React.ReactElement {
    const history = useHistory();
    const { t } = useTranslation();
    const { setCookie, removeAllCookies } = useCookie();

    const [isModalOpenPWD, setIsModalOpenPWD] = useState(false);
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
    const [currentUserID, setCurrentUserID] = useState<string>();

    const [setAsVerified] = useLazyQuery(PRIM_SET_AS_VERIFIED);
    const [updatePWD, { loading: waitPWD }] = useMutation(UPDATE_PWD);

    const ref = new URLSearchParams(useLocation().search).get("ref");
    const code = new URLSearchParams(useLocation().search).get("code");

    const onConfirmPWD = (): void => {
        if (isEmpty(newPassword)) {
            toastWarning(t("profile:password.empty"));
            return;
        }

        if (newPassword !== confirmNewPassword || isEmpty(newPassword)) {
            toastWarning(t("profile:newPassword.different.confirmNewPassword"));
            return;
        }

        void updatePWD({
            variables: {
                userID: currentUserID,
                params: {
                    newPassword
                }
            }
        }).then(async ({ errors }: any) => {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }
                    return;
                }

                toastSuccess(t("validations:saved.success"));
                setIsModalOpenPWD(false);

                const { OIDCData, status, error } = await oidcProvider({ email: ref, password: newPassword });

                if (status) {
                    const { payload } = JWT.decode(OIDCData.id_token, { complete: true });

                    setStorage("user", {
                        ...payload
                    });

                    const now = Number.parseInt((Date.now() / 1000).toString());
                    const minutes = Math.round(payload.exp - now) / 60;
                    setCookie(REACT_ENV.REACT_APP_ID_TOKEN_COOKIE_NAME, base64_encode(OIDCData.id_token), minutes);
                    setCookie(REACT_ENV.REACT_APP_NONCE_COOKIE_NAME, getCookie(REACT_ENV.REACT_APP_NONCE_COOKIE_NAME), minutes);
                    setCookie(REACT_ENV.REACT_APP_ACCESS_TOKEN_COOKIE_NAME, OIDCData.access_token, minutes);
                    setCookie(REACT_ENV.REACT_APP_REFRESH_TOKEN_COOKIE_NAME, OIDCData.refresh_token, minutes);
                } else {
                    if (error) {
                        toastError(t(`validations:${error}`));
                        removeAllCookies()
                    }
                }

                history.push("/");
        }).catch(() => toastError(t("validations:out.of.service")));
    };

    useEffect(() => {
        if (ref) {
            void setAsVerified({
                variables: {
                    email: ref,
                    randomCode: code
                }
            }).then(({ data, errors }: any) => {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }

                    history.push("/");
                    return;
                }

                if (data.setAccountAsVerified.isVerified) {
                    toastSuccess(t("createNewUser:activation.email.verified"));
                    setStorage("tmp-email", ref);

                    if (withSetPwd) {
                        setCurrentUserID(data.setAccountAsVerified._id);
                        setIsModalOpenPWD(true);
                    } else {
                        history.push("/");
                    }
                }
            });
        }

    }, [ref, code]);

    if (!ref || !code) {
        history.push("/");
    }

    return (
        <ModalChangePWD
            adminPage
            isModalOpenPWD={isModalOpenPWD}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmNewPassword={confirmNewPassword}
            setConfirmNewPassword={setConfirmNewPassword}
            waitPWD={waitPWD}
            onSave={onConfirmPWD}
            onClose={() => {
                setIsModalOpenPWD(false);
            }}
        />
    );
}

export default Activation;
