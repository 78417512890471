/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import
    {
        Button,
        ButtonProps, CircularProgress,
        CircularProgressProps, Input, List, Switch,
        SwitchProps,
        TextField,
        TextFieldProps
    } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Check, DeleteOutline, AddRounded as RightPanelAddIcon } from "@material-ui/icons";
import { ReactComponent as LoadingIcon } from "assets/images/loading.svg";

import { ReactComponent as RightPanelCloserIcon } from "assets/images/icon-right-panel-closer.svg";
import { ReactComponent as RightPanelOpenerIcon } from "assets/images/icon-right-panel-opener.svg";
import styled from "styled-components";


export const RightPanelLoadingStyled = styled.div<{ $isShowing: boolean }>`
    background-color: rgba(0, 0, 0);
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    align-items: center;
    justify-content: center;
    display: ${(props) => (props.$isShowing ? 'flex' : 'none')};
`

export const LoadingIconStyled = styled(LoadingIcon)`
    padding: 2px 12px;
    width: 48px;
    height: 48px;
`

export const RightPanelOpenerIconStyled = styled(RightPanelOpenerIcon)<{ $isShowing: boolean }>`
    padding: 2px 12px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 ${({ theme }) => theme.colors.shadow};
    position: absolute;
    right: 16px;
    top: 16px;
    width: 48px;
    height: 48px;
    cursor: w-resize;
    opacity: ${(props) => (props.$isShowing ? 1 : 0)};
    z-index: ${(props) => (props.$isShowing ? 1 : -1)};
    :hover {
        fill: ${({ theme }) => theme.colors.primary};
    }
`;

export const RightPanelCloserIconStyled = styled(RightPanelCloserIcon)<{ $isShowing: boolean }>`
    padding: 2px 12px;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 48px;
    height: 48px;
    cursor: e-resize;
    opacity: ${(props) => (props.$isShowing ? 1 : 0)};
    z-index: ${(props) => (props.$isShowing ? 1 : -1)};
    :hover {
        .colorable {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }
`;
export const RightPanelConfirmIconStyled = styled(Check)<{ $isShowing: boolean }>`
    padding: 2px 12px;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 42px !important;
    height: 42px !important;
    cursor: pointer;
    opacity: ${(props) => (props.$isShowing ? 1 : 0)};
    z-index: ${(props) => (props.$isShowing ? 1 : -1)};
    :hover {
        .colorable {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }
`;
export const RightPanelBreadCrumbStyled = styled.span<{ $isShowing: boolean }>`
    padding: 13px 12px;
    position: absolute;
    left: 16px;
    top: 16px;
    height: 20px;
    opacity: ${(props) => (props.$isShowing ? 1 : 0)};
    z-index: ${(props) => (props.$isShowing ? 1 : -1)};
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
`;
export const RightPanelDeleteIconStyled = styled(DeleteOutline)`
    margin: 0 auto;
    padding: 12px 12px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    :hover {
        filter: drop-shadow(0px 0px 4px #e53935);
    }
`;
export const RightPanelAddIconStyled = styled(RightPanelAddIcon)`
    height: 24px;
    min-width: 24px;
    flex: 1;
    cursor: pointer;
    :hover {
        .colorable {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export const RightPanelContainer = styled.div<{ $isShowing: boolean }>`
    width: 340px;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 0px;
    right: ${(props) => (props.$isShowing ? "0" : "-340px")};
    box-shadow: 0 3px 6px ${({ theme }) => theme.colors.shadow};
    transition: right 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    overflow: auto;
    && form {
        padding-bottom: 90px;
    }
`;
export const Form = styled.form`
    box-sizing: border-box;
    flex: 0;
    display: flex;
    flex-direction: column;
    padding: 12px;
`;
export const TextFieldStyled = styled(TextField).attrs<TextFieldProps>(() => <TextFieldProps>{
            variant: "outlined"
        })<TextFieldProps & { $shouldBeLarge?: boolean }>`
    && {
        margin: 12px 0;
    }
    && input {
        width: 292px;
        height: ${(props) => (props.$shouldBeLarge ? "79px" : "63px")};
        max-height: ${(props) => (props.$shouldBeLarge ? "79px" : "63px")};
        box-sizing: border-box;
        padding: 22.2px 24px 24.8px;
        font-family: IBM Plex Sans;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        ::placeholder {
            color: rgba(0, 0, 0, 0.6);
        }
        color: ${({ theme }) => theme.colors.fontNormal};
    }
`;

export const CircularProgressStyled = styled(CircularProgress).attrs<CircularProgressProps>(() => <CircularProgressProps>{
            disableShrink: true
        })``;

export const SelectFieldStyled = styled(TextField).attrs<TextFieldProps>(() => <TextFieldProps>{
            variant: "outlined",
            select: true
        })<TextFieldProps & { $shouldBeLarge?: boolean }>`
    && {
        margin: 12px 0;
    }
    && input {
        width: 292px;
        height: ${(props) => (props.$shouldBeLarge ? "79px" : "63px")};
        max-height: ${(props) => (props.$shouldBeLarge ? "79px" : "63px")};
        box-sizing: border-box;
        padding: 22.2px 24px 24.8px;
        font-family: IBM Plex Sans;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        ::placeholder {
            color: rgba(0, 0, 0, 0.6);
        }
        /* color: ${({ theme }) => theme.colors.fontNormal}; */
        color: red;
        background-color: purple;
    }
`;
export const RPHeader = styled.div`
    flex: 0;
    min-height: 72px;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 13px 24px;
    box-sizing: border-box;
    align-items: center;
`;
export const Divider = styled.div`
    width: 100%+24px;
    height: 8px;
    background-color: #f7f9fa;
    flex: 0;
    min-height: 8px;
    margin: 12px -12px 8px;
    box-sizing: content-box;
`;
export const SwitchBoxStyled = styled.div`
    display: flex;
    flex: 0;
    width: 100%;
    padding: 13px 24px;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
`;
export const SwitchLabelStyled = styled.span`
    flex: 1;
    white-space: nowrap;
    font-family: IBM Plex Sans;
`;
export const SwitchButton = styled(Switch)<SwitchProps>`
    && {
        .Mui-checked {
            color: ${({ theme }) => theme.colors.primary};
        }
        .MuiSwitch-track {
            background-color: "#00000061";
        }
        .MuiSwitch-switchBase {
            color: ${({ checked, theme }) => (checked ? theme.colors.primary : theme.colors.white)};
        }
    }
`;

export const OntologySwitchButton = styled(Switch)<SwitchProps>`
    && {
        .MuiSwitch-thumb {
            border-radius: 0;
            width: 12px;
            height: 12px;
            padding: 2px;
            margin: 2px;
        }
        .MuiSwitch-switchBase {
            color: ${({ theme }) => theme.colors.primary};
        }
        .MuiSwitch-track {
            background-color: ${({ theme }) => theme.colors.red};
            border-radius: 0;
        }
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
            background-color: ${({ checked, theme }) => (!checked ? theme.colors.primary : theme.colors.secondary)};
        }
    }
`;

export const RPTitle = styled.div`
    flex: 1;
    width: 72px;
    height: 21px;
    margin: 8.8px 190px 10.2px 0;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.colors.fontNormal};
`;
export const RPPropBox = styled.div<{ $canEdit: boolean }>`
    flex: 0;
    min-height: 48px;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 13px 24px;
    box-sizing: border-box;
    align-items: center;
    cursor: ${(props) => (props.$canEdit ? "pointer" : "default")};
    :hover {
        filter: ${(props) => (props.$canEdit ? "drop-shadow(0px 0px 4px " + props.theme.colors.primary + ")" : "none")};
    }
`;
export const RPPropIcon = styled.p`
    width: 20px;
    height: 19px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #1e88e5;
    margin: 0;
`

export const RPPropTitle = styled.p<{ $canEdit: boolean }>`
    flex: 1;
    height: 19px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => (props.$canEdit ? "#1e88e5" : "#666")};
    margin: 0;
`;

export const RPPropType = styled.p`
    margin: 0;
    height: 19px;
    font-family: IBM Plex Mono;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: right;
    color: rgba(0, 0, 0, 0.6);
`;
export const RightPanelXIconStyled = styled(RightPanelAddIcon)`
    transform: rotate(45deg);
    height: 24px;
    min-width: 24px;
    margin: 0 -12px 0 12px;
    flex: 0;
    :hover {
        .colorable {
            fill: red;
        }
    }
`;

export const StyledFormControl = styled(FormControl)`
    margin-top: 15px !important;
    div.MuiInput-input {
        width: 100px !important;
        padding-left: 16px !important;
    }
`;

export const StyledList = styled(List)`
    margin-top: 20px !important;
    padding-left: 8px !important;
    .title {
        margin-bottom: 20px;
    }
    .inputEnum {
        padding: 10px 16px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        svg {
            margin: 0 -12px 0 12px;
        }
        input {
            width: 80%;
        }
    }
    li {
        display: flex;
        align-items: center;
        align-content: center;
    }
`;

export const AddButton = styled(Button).attrs((prop) => {
    const props: ButtonProps = {
        color: prop.color ? prop.color : "primary",
        variant: "contained"
    };
    return props;
})`
    && {
        display: flex;
        justify-content: flex-end;
        align-self: flex-end;
        margin-top: 20px;
        height: 38px;
        padding: 10px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }
`;

export const StyledEnumInput = styled(Input)`
    width: 100% !important;

    input {
        text-transform: uppercase !important;
    }
`;
