import { ValidationError } from "./types/customErrors";
import { AccessToken, IdToken } from "./types/types";

/**
 * ID Token claims validation done is in OIDC spec:
 * https://openid.net/specs/openid-connect-core-1_0.html#IDTokenValidation
 */
export const TokenClaimsValidator = {
    validateIssuer (token: IdToken | AccessToken, issuer: string): void {
        if (
            token?.iss !== issuer &&
            token?.iss !== issuer.slice(0, issuer.lastIndexOf(":"))
        ) {
            throw new ValidationError("invalid issuer");
        }
    },

    validateAudience (token: IdToken | AccessToken, audience: string): void {
        if (!token?.aud) {
            return;
        }

        const { aud, azp } = token;
        const audArr = (Array.isArray(aud) ? aud : [aud]) as string[];

        if (!audArr.includes(audience)) {
            throw new ValidationError("invalid audience");
        }

        if (azp && !audArr.includes(azp)) {
            throw new ValidationError("invalid authz party");
        }
    },

    validateAlg (token: IdToken | AccessToken): void {
        // validate alg
        // TODO: validate
    },

    validateTimestamps (token: IdToken | AccessToken, iatLimit?: number): void {
        let { iat, exp, auth_time } = token;

        // Map timestamps if they are in sec format (10 digits instead of 13)
        if (iat.toString().length < 13) {
            iat = Number(`${iat}000`);
        }
        if (exp.toString().length < 13) {
            exp = Number(`${exp}000`);
        }
        if (auth_time && auth_time.toString().length < 13) {
            auth_time = Number(`${auth_time}000`);
        }
        if (iat > Date.now() || (auth_time && auth_time > Date.now())) {
            throw new ValidationError("iat/auth_time from future");
        }
        if (iatLimit && Date.now() - iat < iatLimit) {
            throw new ValidationError("iat is too old");
        }
        if (exp < Date.now()) {
            throw new ValidationError("exp from past");
        }
    }
};
