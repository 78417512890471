/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-has-content */
import { toastLoading, toastSuccess, toastUpdate, toastWarning } from "@3edges/utils/dist/toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import { Button, Divider, Menu, MenuItem } from "@material-ui/core";
import { DeleteOutline, Edit, GetApp, MoreVert as MoreVertIcon } from "@material-ui/icons";
import { COLORS } from "assets/defaultVariables";
import { DELETE_SCHEMA_DB } from "components/ModalDataServer/gql";
import { EXPORT_PRIM_TENANT, IMPORT_PRIM_TENANT } from "components/PanelTenant/gql";
import { useData } from "contexts/dataContext";
import { ContextMenuContext } from "contexts/rightClickContext";
import React, { ChangeEvent, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { exportConfig, exportConfig_exportConfig as exportConfigType, importConfig } from "types/operation-result-types";
import { omitDeep } from "utils";
import { StyledInput } from "./styled";

interface IConfigurationActions {
    dataCollection: any;
    refetchPrimInfo?: () => void;
    openConfig: () => void;
}

export function ConfigurationActions ({ dataCollection, refetchPrimInfo, openConfig }: IConfigurationActions): ReactElement {
    const linkRef = useRef<HTMLAnchorElement>(null);
    const [configData, setConfigData] = useState<{ elements: exportConfigType }>(null);
    const { t } = useTranslation();
    const { server, getDataServer, setItemSelected } = useData();
    const [deleteSchemaDB] = useMutation(DELETE_SCHEMA_DB);
    const contextMenuState = useContext(ContextMenuContext);

    const [exportConfigRequest] = useLazyQuery<exportConfig>(EXPORT_PRIM_TENANT);
    const [importConfigReq] = useMutation<importConfig>(IMPORT_PRIM_TENANT);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleExport = (): void => {
        const toastID = toastLoading()

        exportConfigRequest({ variables: { serverID: server._id } }).then((res: any) => {
            if (res.data.exportConfig) {
                const editedData = cloneDeep(res.data.exportConfig);

                editedData.edges.map((d) => {
                    if (d.data.properties.name !== null) {
                        return d;
                    }

                    delete d.data.properties;
                    return d;
                });

                omitDeep(editedData as unknown as Record<string, unknown>, "__typename");
                setConfigData({ elements: editedData });

                toastUpdate(toastID, t("panel:export.successExport"), "success")
                refetchPrimInfo();
            } else {
                toastUpdate(toastID, t("panel:export.failed"), "error")
            }
        });
    };

    useEffect(() => {
        if (configData)
        {
            if (configData.elements.nodes.length > 0) {
                linkRef.current.click();
            } else {
                toastWarning(t("panel:export.empty"));
            }
        }
    }, [configData]);

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>): void => {
        const [file] = e.target.files;

        if (file) {
            const toastID = toastLoading()

            setItemSelected(null)
            // eslint-disable-next-line react/destructuring-assignment
            contextMenuState.setContextMenuState({
                visibility: false
            });

            importConfigReq({ variables: { serverID: server._id, file } }).then((res: any) => {
                if (res.data.importConfig) {
                    toastUpdate(toastID, t("panel:import.successImport"), "success")
                    refetchPrimInfo();
                } else {
                    toastUpdate(toastID, t("panel:import.failed"), "error")
                }
            });
        }
    };

    const deleteCurrentSchema = async () => {
        setItemSelected(null)
        // eslint-disable-next-line react/destructuring-assignment
        contextMenuState.setContextMenuState({
            visibility: false
        });

        const { isConfirmed } = await Swal.fire({
            icon: "error",
            title: t("dataserver:ModalDataServer.confirm-delete-schema-database"),
            showConfirmButton: true,
            confirmButtonText: t("dataserver:try.to.delete.yes"),
            showCancelButton: true,
            cancelButtonText: t("dataserver:try.to.delete.no"),
            confirmButtonColor: COLORS.GREEN,
            cancelButtonColor: COLORS.RED
        })

        if (isConfirmed) {
            await deleteSchemaDB({ variables: { serverID: server._id } })
            toastSuccess(t("root:btn.done"));

            if (refetchPrimInfo) {
                getDataServer(server._id)
            }
        }
    }

    return (
        <div style={{ marginRight: 15, marginLeft: 15 }}>
            <Button onClick={handleClick} style={{ border: '1px solid #BBB', minWidth: 'auto' }}>
                <MoreVertIcon />
            </Button>

            <a
                ref={linkRef}
                style={{ display: "none" }}
                href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(configData))}`}
                download={`${dataCollection?.name}-config.json`}
            />

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <StyledInput onChange={handleFileUpload} onClick={(event)=> { event.currentTarget.value = null }} type="file" accept="application/JSON" />

                    <GetApp style={{ margin: "0 16px 0 0" }} />

                    {t("panel:import.btn")}
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleExport();
                        handleClose();
                    }}
                >
                    <GetApp style={{ transform: 'rotate(-180deg)', margin: "0 16px 0 0" }} />

                    {t("panel:export.btn")}
                </MenuItem>

                <Divider />

                <MenuItem
                    onClick={() => {
                        deleteCurrentSchema();
                        handleClose();
                    }}
                >
                    <DeleteOutline style={{ margin: "0 16px 0 0" }} />

                    {t("dataserver:ModalDataServer.delete-schema-database")}
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        openConfig();
                        handleClose();
                    }}
                >
                    <Edit style={{ margin: "0 16px 0 0" }} />

                    {t("panel:go.to.config")}
                </MenuItem>

            </Menu>
        </div>
    );
}
