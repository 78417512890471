import
    {
        addNewNodeRelationship_addNewNodeRelationship as NewNodeRelationship,
        getPrimGInfo_niamDataServer_niamObjects_nodeRelationships as NodeRelationShip
    } from "types/operation-result-types";
import { iD3Data, iD3Selection, iData } from "../types";
import { getShown } from "../utils";
import { NewDataItem } from "./types";
import { EAddType } from "./useAddD3Items";

interface AddD3OrbitParams {
    d3Data: iD3Data;
    data: iData;
    type: EAddType;
    enters: {
        enterOrbit: (selection: iD3Selection) => void;
        updateOrbit: (selection: iD3Selection) => void;
        enterLink: (selection: iD3Selection) => void;
        updateLink: (selection: iD3Selection) => void;
    };
    newItemData: NewDataItem;
    injectData?: Partial<iData & NewNodeRelationship>;
}

export const addD3Orbit = ({ d3Data, data, type, enters, newItemData, injectData }: AddD3OrbitParams): any => {
    const newOrbit: any = newItemData;

    newOrbit.fields = [];
    newOrbit.interfaces = [];
    newOrbit.objects = [];
    newOrbit.scripts = [];
    newOrbit.customMutations = [];
    newOrbit.tags = [];
    newOrbit.enums = [];
    newOrbit.nodeRelationships = [];
    newOrbit.relationships = [];
    newOrbit.paths = [];

    const newDataOrbits = [
        ...data.objects,
        ...data.interfaces,
        ...data.scripts,
        ...data.customMutations,
        ...data.tags,
        ...data.enums,
        ...data.nodeRelationships,
        newOrbit
    ];

    // format object in memory with specific Node Relationship attributes
    if (type === "nodeRelationship") {
        newOrbit["relation"] = {
            rel: {
                _id: newOrbit._id,
                x: newOrbit.x,
                y: newOrbit.y,
                displayName: newOrbit.displayName,
                name: newOrbit.name,
                isAction: newOrbit["isAction"],
                isResource: newOrbit["isResource"],
                isVC: newOrbit["isVC"],
                simplePath: newOrbit["simplePath"],
                simplePathCreate: newOrbit["simplePathCreate"],
                simplePathRead: newOrbit["simplePathRead"],
                simplePathUpdate: newOrbit["simplePathUpdate"],
                simplePathDelete: newOrbit["simplePathDelete"],
                __typename: newOrbit["__typename"],
                fields: newOrbit.fields,
            }
        }
    }

    const newData: iData = {
        ...data,
        customMutations: type === "customMutation" ? ([...data.customMutations, newOrbit] as typeof data.customMutations) : data.customMutations,
        nodeRelationships: type === "nodeRelationship" ? ([...data.nodeRelationships, newOrbit] as typeof data.nodeRelationships) : data.nodeRelationships,
        scripts: type === "script" ? ([...data.scripts, newOrbit] as typeof data.scripts) : data.scripts,
        objects: type === "object" ? ([...data.objects, newOrbit] as typeof data.objects) : data.objects,
        tags: type === "tag" ? ([...data.tags, newOrbit] as typeof data.tags) : data.tags,
        enums: type === "enum" ? ([...data.enums, newOrbit] as typeof data.enums) : data.enums,
        interfaces: type === "interface" ? ([...data.interfaces, newOrbit] as typeof data.interfaces) : data.interfaces
    };

    if (type === "nodeRelationship" && injectData) {
        newData.objects = newData.objects.map((obj) => {
            if (obj._id === injectData.objFrom._id) {
                obj.nodeRelationships.push(injectData as NodeRelationShip);
            }

            return obj;
        });
    }

    const orbitShown: any = getShown([
        ...newDataOrbits
    ]);

    d3Data.g
        .selectAll(".orbit")
        .data(orbitShown, (node: { _id: string }) => node._id)
        .enter()
        .append("g")
        .call(enters.enterOrbit)
        .on("click", d3Data.zoomOnNodesInstance);

    d3Data.simulation.nodes(orbitShown);
    d3Data.simulation.restart();

    return newData;
};
