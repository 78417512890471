/* eslint-disable react/react-in-jsx-scope */
import { toastError } from "@3edges/utils/dist/toastify";
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType, EUpdateType } from "components/PrimGraphicalCanvas/types";
import { useUpdateD3Items } from "components/PrimGraphicalCanvas/updateD3Items";
import { useData } from "contexts/dataContext";
import { GraphQLError } from "graphql";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "ui-components/styleds";
import { RP_DELETE_TAG, RP_GET_TAG, RP_UPDATE_TAG } from "./gql";
import { Divider, Form, LoadingIconStyled, RightPanelDeleteIconStyled, RightPanelLoadingStyled } from "./styled";

interface niamTag {
    displayName: string;
    name: string;
    __typename: string;
    _id: string;
}
interface ITagData {
    niamTag: niamTag;
    updateNiamTag?: niamTag;
}

interface IResProps {
    data?: ITagData;
    errors: readonly GraphQLError[];
}

function RpTag({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (value: boolean) => void }): ReactElement {
    const { server, setItemSelected, itemSelected  } = useData();
    const onDelete = useDeleteD3Items();
    const onUpdate = useUpdateD3Items();
    const [getData, { data, loading }] = useLazyQuery<ITagData>(RP_GET_TAG, { fetchPolicy: "no-cache" });

    const [name, setName] = useState<string>(undefined);
    const [displayName, setDisplayName] = useState<string>(undefined);

    const { t } = useTranslation();
    const [updateTag, { loading: updLoading }] = useMutation(RP_UPDATE_TAG);
    const [deleteTag] = useMutation(RP_DELETE_TAG);

    const isComponentUnMounted = useRef(false);

    useEffect(() => {
        // standard procedure for processing componentDidUnmount action
        // here, we set isComponentUnMounted.current flag to true
        return () => {
            isComponentUnMounted.current = true;
        }
    }, []);

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { tagID: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    const turnToUnsaved = (): void => {
        setItemSelected({ ...itemSelected, hasSaved: false })
    };

    useEffect(() => {
        if (data && !loading) {
            setName(data.niamTag.name);
            setDisplayName(data.niamTag.displayName);
        }
    }, [loading, data]);

    const handleOnBlurEvent = (event: any): void => {
        if (isOpen && !itemSelected.hasSaved) {
            updateTag({
                variables: {
                    serverID: server._id,
                    tagID: data.niamTag._id,
                    params: {
                        displayName,
                        name
                    }
                }
            }).then(({ errors, data: dataUpdated }: any) => {
                if (errors) {
                    for (const e of errors)
                    {
                        if (e.message.includes("NAME_ALREADY_IN_USE"))
                        {
                            toastError(t(`validations:${e.message}`) + displayName);
                            setName(itemSelected.data.displayName)
                        } else
                        {
                            toastError(t(`validations:${e.message}`));
                        }
                    }

                    return;
                }

                onUpdate({
                    id: data.niamTag._id,
                    type: EUpdateType.TAG,
                    newData: dataUpdated.updateNiamTag,
                    rPData: itemSelected.data
                });

                if (isComponentUnMounted.current === false) {
                    setName(dataUpdated.updateNiamTag.name);
                    setDisplayName(dataUpdated.updateNiamTag.displayName);

                    itemSelected.data.displayName = dataUpdated.updateNiamTag.displayName;
                    setItemSelected({ ...itemSelected, hasSaved: true });
                }
            });
        }
    }

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading || updLoading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <Form>
                <div data-cy="cypressHiddenDiv" />

                <TextField
                    data-cy="rpTagPage_name"
                    label={t("rightPanel:tag.input.name.label")}
                    onChange={(e) => {
                        setName(e.target.value);
                        setDisplayName(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                    value={name || ""}
                />

                <Divider />

                <RightPanelDeleteIconStyled
                    onClick={() => {
                        setIsOpen(false);

                        deleteTag({
                            variables: {
                                tagID: data.niamTag._id
                            }
                        }).then(({ errors }: any) => {
                            if (errors) {
                                for (const e of errors) {
                                    toastError(t(`validations:${e.message}`));
                                }
                                return;
                            }

                            onDelete({
                                id: data.niamTag._id,
                                type: EDeleteType.TAG
                            });
                        });
                    }}
                />
            </Form>
        </>
    );
}

export default RpTag;
