import adminPage from "./adminPage.json";
import auth from "./auth.json";
import createNewUser from "./createNewUser.json";
import customMutation from "./customMutation.json";
import dataserver from "./dataserver.json";
import deploymentManagement from "./deploymentManagement.json";
import filter from "./filter.json";
import interfaceJson from "./interface.json";
import leftPanel from "./leftPanel.json";
import nodeRelationship from "./nodeRelationship.json";
import object from "./object.json";
import panel from "./panel.json";
import profile from "./profile.json";
import rightClick from "./rightClick.json";
import rightPanel from "./rightPanel.json";
import root from "./root.json";
import script from "./script.json";
import serverInfo from "./serverInfo.json";
import sharedPage from "./sharedPage.json";
import tenant from "./tenant.json";
import validations from "./validations.json";
import serviceaccounts from "./serviceaccounts.json";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    sharedPage,
    nodeRelationship,
    rightClick,
    filter,
    deploymentManagement,
    auth,
    panel,
    serviceaccounts,
    root,
    dataserver,
    serverInfo,
    leftPanel,
    rightPanel,
    validations,
    customMutation,
    interfaceJson,
    object,
    script,
    tenant,
    createNewUser,
    profile,
    adminPage
};
