/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useEffect, useState } from "react";

interface State {
    password: string;
    showPassword: boolean;
}

export function PwdField(props: any): React.ReactElement
{
    const { label, value, disabled = undefined, onChange = undefined, inputProps = undefined, autoFocus = undefined, onKeyPress = undefined } = props

    const [values, setValues] = useState<State>({
        password: '',
        showPassword: false,
    });

    useEffect(() =>
    {
        setValues({ ...values, password: value })
    }, [value])

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
        onChange(event)
    };

    const handleClickShowPassword = (): void => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl variant="outlined" style={{ marginBottom: 10, width: '100%' }}>
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>

            <OutlinedInput
                autoFocus={autoFocus}
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                label={label}
                inputProps={inputProps}
                onKeyPress={onKeyPress}
                disabled={disabled}
                data-cy={props["data-cy"] || null}
                onChange={handleChange('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} disabled={disabled} data-cy={props["data-cy"] ? `${props["data-cy"]}_btn` : null} edge="end">
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}
