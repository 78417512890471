import { toastError, toastSuccess } from "@3edges/utils/dist/toastify";
import { formatDate } from "@3edges/utils/dist/utils";
import { useMutation } from "@apollo/client";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import CreatingUserFormShared from "components/CreatingUserForm/CreatingUserFormShared";
import { ConfirmButton } from "components/ModalDataServer/styled";
import { useData } from "contexts/dataContext";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { REMOVE_USER_SHARED } from "../../gql";
import { Pagination } from "./components";
import
    {
        AddUserButton, EmptyList, HeaderTableContainer,
        MainContainer, StyledSearchInput, StyledTable
    } from "./styled";

export function UsersShared ({ data, refetch, paginationData, setPaginationData }: any): React.ReactElement {
    const { t } = useTranslation();
    const { server, currentServerID } = useData();

    const users = data.getUsersByDataServerShared?.users || []
    const totalItems = data.getUsersByDataServerShared?.totalItems || 0

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [removeUserShared] = useMutation(REMOVE_USER_SHARED);

    const searchRequest = debounce((value: string) => {
        setPaginationData({ ...paginationData, searchWord: value, skip: 0, page: 0 });
    }, 500);

    const debouceRequest = useCallback((value: string) => {
        searchRequest(value);
    }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        debouceRequest(e.target.value);
    };

    const delUserShared = (userID): void => {
        void removeUserShared({ variables: { serverID: currentServerID, userID } })
            .then(({ data, errors }: any) =>
            {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            if (data.removeUserShared) {
                toastSuccess(t("sharedPage:message.after.deleted"));
                void refetch();
            } else {
                toastSuccess(t("sharedPage:message.remove.admin"));
            }
        })
    }

    return (
        <MainContainer>
            <AddUserButton
                    onClick={() => {
                        setIsModalOpen(!isModalOpen);
                    }}
                >
                {t("sharedPage:addNewUser.button")}
            </AddUserButton>

            <HeaderTableContainer>
                <Pagination
                    totalItems={totalItems}
                    onChangePage={(e) => {
                        setPaginationData({ ...paginationData, skip: e, page: e });
                    }}
                    onCountChange={(e) => {
                        setPaginationData({ ...paginationData, skip: 0, page: 0, first: e });
                    }}
                    perPage={paginationData.first}
                    pageReset={paginationData.page}
                />

                <StyledSearchInput onChange={onChange} placeholder="Search by email" />
            </HeaderTableContainer>

            {users?.length <= 0
                    ? (
                        <EmptyList>
                            {t("sharedPage:users.notFound")}
                        </EmptyList>
                    )

                    : (
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="left-aligned" width={server ? "10%" : undefined}>
                                        {t("sharedPage:email.header")}
                                    </TableCell>

                                    <TableCell className="right-aligned">
                                        {t("sharedPage:creationDate.header")}
                                    </TableCell>

                                    <TableCell className="right-aligned">
                                        {t("sharedPage:actions.header")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {users?.map((user) => (
                                    <TableRow key={user._id}>
                                        <TableCell className="left-aligned">
                                            {user.email}
                                        </TableCell>

                                        <TableCell className="right-aligned">
                                            {user.creationDate && formatDate(user.creationDate)}
                                        </TableCell>

                                        <TableCell className="right-aligned" width={server ? "10%" : undefined}>
                                            <ConfirmButton color="secondary" onClick={() => delUserShared(user._id)}>
                                                <DeleteOutline style={{ width: 24, height: 24 }} />
                                            </ConfirmButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                    )}

            <CreatingUserFormShared
                serverID={currentServerID}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClose={refetch}
            />

        </MainContainer>
    );
}
