/* eslint-disable react/forbid-component-props */
import PanelHeader from "components/PanelHeader";
import PanelProfile from "components/PanelProfile";
import { PageContainer } from "pages/Canvas/styled";
import React from "react";
import { useHistory } from "react-router-dom";
import SidebarWrapper from "components/SidebarWrapper";

function Profile (): React.ReactElement {
    const history = useHistory();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = (): void => {
        setOpen(true);
    };

    const handleDrawerClose = (): void => {
        setOpen(false);
    };

    return (
        <SidebarWrapper open={open} handleDrawerClose={handleDrawerClose}>
            <PageContainer className="resizeHeader">
                <PanelHeader open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />

                <PanelProfile onModalCancel={() => history.goBack()} />
            </PageContainer>
        </SidebarWrapper>
    );
}

export default Profile;
