import { getStorage, setStorage } from "cache";
import { SwitchBoxStyled, SwitchButton, SwitchLabelStyled } from "components/RightPanel/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BoxAbsolute, BoxRelative } from "./styled";

interface ShowModeProps {
    onChange: (state: boolean) => void;
}

function ShowMode ({ onChange }: ShowModeProps): React.ReactElement {
    const { t } = useTranslation();
    const [isShowModeList, setIsShowModeList] = useState(false);

    useEffect(() => {
        const currentMode = getStorage<boolean>("isShowModeList");

        setIsShowModeList(Boolean(currentMode));
        onChange(Boolean(currentMode));

    }, []);

    const onChangeSwitch = (checked: boolean): void => {
        setStorage("isShowModeList", checked);
        setIsShowModeList(checked);
        onChange(checked);
    };

    return (
        <BoxRelative>
            <BoxAbsolute>
                <SwitchBoxStyled style={{ padding: 0 }}>
                    <SwitchLabelStyled>
                        {t("panel:show.mode.title")}

                        <b>
                            {!isShowModeList ? t("panel:show.mode.card") : t("panel:show.mode.list")}
                        </b>
                    </SwitchLabelStyled>

                    <SwitchButton
                        checked={isShowModeList}
                        onChange={(event) => {
                            onChangeSwitch(event.target.checked);
                        }}
                    />
                </SwitchBoxStyled>
            </BoxAbsolute>
        </BoxRelative>
    );
}

export default ShowMode;
