/* eslint-disable max-params */
import
    {
        Simulation,
        SimulationNodeDatum,
        forceCenter,
        forceCollide,
        forceLink,
        forceManyBody,
        forceSimulation
    } from "d3";
import { NiamImplementElement, NiamSystemItem, iD3Data } from "./types";

export const restartSwiftSimulation = (simulation: iD3Data["simulation"], alpha?: number): void => {
    simulation.alpha(alpha || 0.1).restart();
};

export const setNewDataForSimulation = (
    d3Data: iD3Data,
    nodes?: unknown[],
    implementLinks?: NiamImplementElement[]
): void => {
    if (nodes) {
        d3Data.simulation.nodes(nodes);
    }
    if (implementLinks) {
        setImplementLinks(d3Data.simulation, implementLinks);
    }
};

export const setupSimulation = (
    nodes: NiamSystemItem[] | SimulationNodeDatum[],
    links: NiamImplementElement[]
): Simulation<unknown, undefined> => {
    return forceSimulation(nodes as unknown[]).force("links", forceLink(links || []).id((d) => (d as NiamImplementElement)._id))
}

export const updateSimulation = (
    simulation: iD3Data["simulation"],
    nodes: NiamSystemItem[],
    links: NiamImplementElement[]
): void => {
    simulation.nodes(nodes).force("links", forceLink(links || []).id((d) => (d as NiamImplementElement)._id))
    simulation.alpha(0.1).restart();
};

export const updateSimulationWithPhysics = (
    simulation: iD3Data["simulation"],
    nodes: NiamSystemItem[],
    links: NiamImplementElement[],
    saveOrbitCollectionPositions: any,
    setEnablePhysicsEffect?: any,
    enablePhysicsEffect?: boolean
): void => {
    simulation.stop();

    simulation.nodes(nodes)
        .force("links",forceLink(links || []).id((d) => (d as NiamImplementElement)._id))
        .force("collide",forceCollide(200))
        .force("charge", forceManyBody())
        .force("center", forceCenter(window.innerWidth / 2, window.innerHeight / 2))
        .on("end", () => {
            const orbits = nodes.map( node => ({
                    orbitID: node['_id'],
                    x: node['x'],
                    y: node['y'],
            }));

            nodes.length > 0 && saveOrbitCollectionPositions({
                variables: {
                    orbitsCollection: orbits
                }
            })

            setEnablePhysicsEffect(false)
        });

    simulation.alpha(0.1).alphaDecay(0.128).restart();
};

const setImplementLinks = (
    simulation: Simulation<unknown, undefined>,
    implementLinks: NiamImplementElement[]
): Simulation<unknown, undefined> => simulation.nodes([]);
