import { useQuery } from "@apollo/client";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import CreatingUserForm from "components/CreatingUserForm/CreatingUserForm";
import PanelProfile from "components/PanelProfile";
import { GET_ALL_TENANTS } from "components/PanelTenant/gql";
import { useData } from "contexts/dataContext";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import
    {
        GetAllTenants,
        adminGetUsersByTenant_adminGetUsersByTenant_users as TenantUsers,
        adminGetUsers_adminGetUsers_users as Users
    } from "types/operation-result-types";
import { Modal } from "ui-components/modal";
import { formatDate } from "@3edges/utils/dist/utils";
import { ActionButtons, Pagination } from "./components";
import { Edit, StyledTooltip } from "./components/ActionButtons/styled";
import
    {
        AddUserButton, CloseCircleIconStyled, EmptyList, HeaderTableContainer,
        MainContainer, StyledSearchInput, StyledTable, StyledTableCell, StyledTableRow
    } from "./styled";

export function UsersTable ({ data, tenantID, refetch, paginationData, setPaginationData }: any): React.ReactElement {
    const { t } = useTranslation();
    const { tenant, currentTenantID } = useData();
    let users = []
    let totalItems = 0

    if (tenantID) {
        users = data.adminGetUsersByTenant?.users;
        totalItems = data.adminGetUsersByTenant?.totalItems;
    } else {
        users = data.adminGetUsers?.users;
        totalItems = data.adminGetUsers?.totalItems;
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<(TenantUsers | Users)>();
    const [editMode, setEditMode] = useState(false);
    const { data: dataTenants } = useQuery<GetAllTenants>(GET_ALL_TENANTS);

    const searchRequest = debounce((value: string) => {
        setPaginationData({ ...paginationData, searchWord: value, skip: 0, page: 0 });
    }, 500);

    const debouceRequest = useCallback((value: string) => {
        searchRequest(value);
    }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        debouceRequest(e.target.value);
    };

    return (
        <MainContainer>
            <AddUserButton
                    onClick={() => {
                        setIsModalOpen(!isModalOpen);
                    }}
                >
                {t("adminPage:addNewUser.button")}
            </AddUserButton>

            <HeaderTableContainer>
                <Pagination
                    totalItems={totalItems}
                    onChangePage={(e) => {
                        setPaginationData({ ...paginationData, skip: e, page: e  });
                    }}
                    onCountChange={(e) => {
                        setPaginationData({ ...paginationData, skip: 0, first: e });
                    }}
                    perPage={paginationData.first}
                    pageReset={paginationData.page}
                />

                <StyledSearchInput data-cy="usersTablePage_searchByEmail" onChange={onChange} placeholder="Search by email" />
            </HeaderTableContainer>

            { users?.length <= 0
                ? (
                    <EmptyList>
                        {t("adminPage:users.notFound")}
                    </EmptyList>
                )

                : (
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell className="left-aligned" width={tenant ? "10%" : undefined}>
                                    {t("adminPage:email.header")}
                                </TableCell>

                                <TableCell>
                                    {t("adminPage:last.access")}
                                </TableCell>

                                <TableCell>
                                    {t("adminPage:account.header")}
                                </TableCell>

                                {!tenant &&
                                    <TableCell>
                                        {t("adminPage:organization.header")}
                                    </TableCell>
                                }

                                <TableCell>
                                    {t("adminPage:role.header")}
                                </TableCell>

                                <TableCell className="right-aligned">
                                    {t("adminPage:actions.header")}
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {users?.map((user) => (
                                <TableRow key={user._id}>
                                    <TableCell className="left-aligned">
                                        {user.email}
                                    </TableCell>

                                    <TableCell>
                                        {user.timestamp && formatDate(user.timestamp)}
                                    </TableCell>

                                    <TableCell className="isVerified">
                                        {user.isVerified
                                            ? (
                                                <StyledTooltip title="Edit user" placement="top" arrow>
                                                    <Edit
                                            style={{
                                                cursor: "pointer",
                                                width: 24,
                                                height: 24
                                            }}
                                            onClick={() => {
                                                setUserEdit(user);
                                                setEditMode(true);
                                            }}
                                        />
                                                </StyledTooltip>
                                            )
                                            : (
                                                <Tooltip title={t("adminPage:isVerified.false")} arrow>
                                                    <CloseCircleIconStyled />
                                                </Tooltip>
                                            )}
                                    </TableCell>

                                    {!tenant && (
                                        <TableCell>
                                            {(user as Users).tenants.length > 0
                                                ? (user as Users).tenants.map((u) => (
                                                    <Table key={u.tenantID}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <StyledTableCell $border>
                                                                    {u.name}
                                                                </StyledTableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                ))
                                                : "-"}
                                        </TableCell>
                                    )}

                                    <TableCell>
                                        {tenant
                                            ? user.role
                                            : (user as Users).tenants.length > 0
                                                ? (user as Users).tenants.map((u) => (
                                                    <Table key={u.tenantID}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <StyledTableCell $border>
                                                                    {u.role}
                                                                </StyledTableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                ))
                                                : user.role ? user.role : "-"}
                                    </TableCell>

                                    <TableCell className="right-aligned" width={tenant ? "10%" : undefined}>
                                        {(user as Users)?.tenants?.length > 0
                                            ? (user as Users).tenants.map((u) => (
                                                <Table key={u.tenantID}>
                                                    <TableBody>
                                                        <StyledTableRow>
                                                            <StyledTableCell $border>
                                                                <ActionButtons
                                                                    tenants={dataTenants?.niamTenants}
                                                                    refetchUsers={refetch}
                                                                    user={user}
                                                                    role={u.role}
                                                                />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableBody>
                                                </Table>
                                            ))

                                            : (
                                                <Table>
                                                    <TableBody>
                                                        <StyledTableRow>
                                                            <StyledTableCell $border>
                                                                <ActionButtons
                                                                    tenants={dataTenants?.niamTenants}
                                                                    refetchUsers={refetch}
                                                                    user={user}
                                                                    role={user.role}
                                                                />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableBody>
                                                </Table>
                                            )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </StyledTable>
            )}

            <CreatingUserForm
                tenants={dataTenants?.niamTenants}
                tenantID={currentTenantID}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClose={refetch}
            />

            <Modal
                isOpen={editMode}
                onClose={() => {
                    setEditMode(false);
                }}
                style={{ width: "600px" }}
            >
                <PanelProfile
                    onModalCancel={() => setEditMode(false)}
                    refetchUsers={refetch}
                    user={userEdit}
                    adminPage
                />
            </Modal>

        </MainContainer>
    );
}
