/* eslint-disable no-loop-func */
import { toastError } from "@3edges/utils/dist/toastify";
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { setDefaultName } from "components/PrimGraphicalCanvas/d3UseEffects/draw";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType, EUpdateType } from "components/PrimGraphicalCanvas/types";
import { useUpdateD3Items } from "components/PrimGraphicalCanvas/updateD3Items";
import { useCanvasContext } from "contexts/canvasContext";
import { useData } from "contexts/dataContext";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    NiamFieldTypeEnum,
    rPAddFieldToInterface as RPAddFieldToInterface,
    rPInterfaceGet as RPInterfaceGet,
    rPUpdateInterface as RPUpdateInterface
} from "types/operation-result-types";
import { TextField } from "ui-components/styleds";
import RPPropertyBox from "./RpPropertyBox";
import { RP_ADD_FIELD_TO_INTERFACE, RP_DELETE_INTERFACE, RP_GET_INTERFACE, RP_UPDATE_INTERFACE } from "./gql";
import {
    Divider,
    Form,
    LoadingIconStyled,
    RPHeader,
    RPTitle,
    RightPanelAddIconStyled,
    RightPanelDeleteIconStyled,
    RightPanelLoadingStyled
} from "./styled";

interface RPInterfaceProps {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}

function RPInterface({ isOpen, setIsOpen }: RPInterfaceProps): React.ReactElement {
    const { data: dataContext, setData } = useCanvasContext();
    const { server, setItemSelected, itemSelected } = useData();
    const onDelete = useDeleteD3Items();
    const onUpdate = useUpdateD3Items();
    const [getData, { data, loading }] = useLazyQuery<RPInterfaceGet>(RP_GET_INTERFACE, { fetchPolicy: "no-cache" });

    const [name, setName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [fields, setFields] = useState<RPInterfaceGet["niamInterface"]["fields"]>([]);

    const { t } = useTranslation();
    const [updateInterface, { loading: updLoading }] = useMutation<RPUpdateInterface>(RP_UPDATE_INTERFACE);
    const [addFieldToInterface] = useMutation<RPAddFieldToInterface>(RP_ADD_FIELD_TO_INTERFACE);
    const [deleteInterface] = useMutation(RP_DELETE_INTERFACE);

    const isComponentUnMounted = useRef(false);

    useEffect(() => {
        // standard procedure for processing componentDidUnmount action
        // here, we set isComponentUnMounted.current flag to true
        return () => {
            isComponentUnMounted.current = true;
        }
    }, []);

    const turnToUnsaved = (): void => {
        setItemSelected({ ...itemSelected, hasSaved: false })
    };

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { interfaceID: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    useEffect(() => {
        if (data && !loading) {
            setName(data.niamInterface.name);
            setDisplayName(data.niamInterface.displayName);
            setFields(data.niamInterface.fields);
        }
    }, [loading, data]);

    const handleOnBlurEvent = (event: any): void => {
        if (isOpen && !itemSelected.hasSaved) {
            updateInterface({
                variables: {
                    serverID: server._id,
                    interfaceID: data.niamInterface._id,
                    params: { displayName, name }
                }
            }).then(({ errors, data: dataUpdated }: any) => {
                if (errors) {
                    for (const e of errors)
                    {
                        if (e.message.includes("NAME_ALREADY_IN_USE"))
                        {
                            toastError(t(`validations:${e.message}`) + displayName);
                            setName(itemSelected.data.displayName)
                        } else
                        {
                            toastError(t(`validations:${e.message}`));
                        }
                    }

                    return;
                }

                onUpdate({
                    id: data.niamInterface._id,
                    type: EUpdateType.OBJECT,
                    newData: dataUpdated.updateNiamInterface,
                    rPData: itemSelected.data
                });
                // No need to reset useState variables when this component has unmounted, namely when isComponentUnMounted.current flag is true
                if (isComponentUnMounted.current === false) {
                    setName(dataUpdated.updateNiamInterface.name);
                    setDisplayName(dataUpdated.updateNiamInterface.displayName);
                    setFields(dataUpdated.updateNiamInterface.fields);

                    itemSelected.data.displayName = dataUpdated.updateNiamInterface.displayName
                    setItemSelected({ ...itemSelected, hasSaved: true })
                }
            });
        }
    }

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading || updLoading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <Form>
                <div data-cy="cypressHiddenDiv" />

                <TextField
                    data-cy="rpInterfacePage_name"
                    label={t("rightPanel:interface.input.name.label")}
                    onChange={(e) => {
                        setName(e.target.value);
                        setDisplayName(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                    value={name || ""}
                />

                <Divider />

                <RPHeader>
                    <RPTitle>
                        Properties
                    </RPTitle>

                    <RightPanelAddIconStyled
                        data-cy="rpInterfacePage_btnAddField"
                        onClick={() => {
                            const { defaultName } = setDefaultName(`I${data.niamInterface.name}`, `Field`, fields);

                            void addFieldToInterface({
                                variables: {
                                    niamInterfaceID: itemSelected.data._id,
                                    newNiamFieldInput: {
                                        name: defaultName,
                                        displayName: defaultName,
                                        fieldType: NiamFieldTypeEnum.String,
                                        isArray: false,
                                        isNaming: false,
                                        isSecret: false,
                                        isDisplay: false,
                                        isRequired: false
                                    }
                                }
                            }).then((res) => {
                                setFields([...fields, res.data.addNewNiamFieldToNiamInterface]);

                                let newDataInterfaces = [...dataContext.interfaces]

                                newDataInterfaces = newDataInterfaces.map(item => {
                                    if (item._id === itemSelected.data._id) {
                                        item.fields.push(res.data.addNewNiamFieldToNiamInterface)
                                    }

                                    return item
                                })

                                const newData = {
                                    ...dataContext,
                                    interfaces: newDataInterfaces
                                }

                                setData(newData)
                            });
                        }}
                    />
                </RPHeader>

                {fields.map((field) => {
                    if (field.fieldType === "Relationship") {
                        return null;
                    }
                    return (
                        <RPPropertyBox
                            canDelete
                            field={field}
                            key={`InterfaceField_${itemSelected.data._id}_${field._id}`}
                            orbitData={{
                                __typename: itemSelected.data.__typename,
                                _id: itemSelected.data._id,
                                displayName: itemSelected.data.displayName,
                                type: "Interface",
                                x: 0,
                                y: 0
                            }}
                            refetch={({ id }) => {
                                const nFields = [...fields.filter((f) => f._id !== id)];

                                setFields(nFields);
                            }}
                        />
                    );
                })}

                <Divider />

                <RightPanelDeleteIconStyled
                    onClick={() => {
                        setIsOpen(false);

                        deleteInterface({
                            variables: {
                                interfaceID: data.niamInterface._id
                            }
                        }).then(({ errors }: any) => {
                            if (errors) {
                                for (const e of errors) {
                                    toastError(t(`validations:${e.message}`));
                                }
                                return;
                            }

                            onDelete({
                                id: data.niamInterface._id,
                                type: EDeleteType.INTERFACE
                            });
                        });
                    }}
                />
            </Form>
        </>
    );
}

export default RPInterface;
