import React, { useRef } from "react";
import styled from "styled-components";

const Container = styled.div<{ $item: string }>`
    display: flex;
    flex-direction: column;
    width: 125px;
    height: 61px;
    padding: 8px;
    margin: 8px 0;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    z-index: 2;
    :hover {
        #colorable {
            fill: ${({ theme, $item }) =>
                theme.colors.colorOrbit[$item.replace("LPD-", "Niam")]
                    ? `${theme.colors.colorOrbit[$item.replace("LPD-", "Niam")] as string}75`
                    : theme.colors.primary};
        }
        p {
            color: ${({ theme, $item }) =>
                theme.colors.colorOrbit[$item.replace("LPD-", "Niam")]
                    ? `${theme.colors.colorOrbit[$item.replace("LPD-", "Niam")] as string}75`
                    : theme.colors.primary};
        }
    }
`;

// eslint-disable-next-line react/prop-types
function DraggableContainer({ children, id }: React.HTMLProps<HTMLDivElement>): React.ReactElement {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <Container
            draggable
            id={id}
            onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
                e.dataTransfer.setData("type", id);
            }}
            ref={ref}
            $item={id}
        >
            {children}
        </Container>
    );
}

export default DraggableContainer;
