/* eslint-disable react/react-in-jsx-scope */
import { toastError, toastSuccess } from "@3edges/utils/dist/toastify";
import { useMutation } from "@apollo/client";
import { MenuItem } from "@material-ui/core";
import { SelectFieldStyled } from "components/RightPanel/styled";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GetAllTenants_niamTenants as NiamTenant } from "types/operation-result-types";
import { Modal } from "ui-components/modal";
import { ADMIN_ADD_USER_TO_TENANT } from "../../../../gql";
import { MainContainer } from "./styles";

interface ModalProps {
    tenants: NiamTenant[];
    userId: string;
    isModalTenatsOpen: boolean;
    onClose: () => void;
}

interface AddUserResponse {
    adminAddUser: boolean;
}

export default function ModalAddToTenant ({ tenants, isModalTenatsOpen, onClose, userId }: ModalProps): ReactElement {
    const { t } = useTranslation();

    const [addToTenant, { data: addUserData, error: addUserError }] =
        useMutation<AddUserResponse>(ADMIN_ADD_USER_TO_TENANT);

    useEffect(() => {
        if (addUserData?.adminAddUser && !addUserError) {
            toastSuccess(t("validations:user.added.success"));
        }

        if (addUserError) {
            toastError(t(`validations:${addUserError.message}`));
        }

    }, [addUserData, addUserError]);

    return (
        <Modal
            isOpen={isModalTenatsOpen}
            onClose={() => {
                onClose();
            }}
            style={{ width: "600px" }}
        >
            <MainContainer>
                <div className="title">
                    {t("adminPage:chooseTenant.option")}
                    :
                </div>

                <SelectFieldStyled
                        value=""
                        label={t("rightPanel:property.input.fieldType.label")}
                        onChange={(event) => {
                            const _id = event.target.value;

                            void addToTenant({ variables: { tenantID: _id, userID: userId } }).then(({ errors }: any) =>
                            {
                                if (errors) {
                                    for (const e of errors) {
                                        toastError(t(`validations:${e.message}`));
                                    }
                                    return;
                                }

                                onClose();
                            });
                        }}
                    >
                    {tenants.map(({ _id, name }) => (
                        <MenuItem key={`niamTenant-${_id}`} value={_id}>
                            {name}
                        </MenuItem>
                        ))}
                </SelectFieldStyled>

            </MainContainer>
        </Modal>
    );
}
