import { SwitchBoxStyled, SwitchButton, SwitchLabelStyled } from "components/RightPanel/styled";
import React, { useState } from "react";
import AceEditor from "react-ace-builds";
import "react-ace-builds/webpack-resolver-min";
import { useTranslation } from "react-i18next";
import { Modal } from "ui-components/modal";
import { ButtonStyled } from "ui-components/styleds";
import "./styles.css";

interface ModalEditorProps {
    scriptText: string;
    onChangeScript: (value: string) => void;
    onBlurScript?: (value: string) => void;
}

function ModalEditor({ scriptText, onChangeScript, onBlurScript }: ModalEditorProps): React.ReactElement {
    const [isOpen, setIsOpen] = useState(false);
    const [isWrapped, setIsWrapped] = useState(false);
    const { t } = useTranslation();

    const onChange = (vlr: string): void => {
        onChangeScript(vlr);
    };

    const onBlur = (vlr: string): void => {
        onBlurScript(vlr);
    };

    return (
        <>
            <ButtonStyled
                data-cy="rpModalCodePage_btnOpen"
                $isPrimary
                style={{
                    width: "100%",
                    minHeight: 63
                }}
                onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(true);
                }}
            >
                {t("rightPanel:btn.code.editor")}
            </ButtonStyled>

            <Modal
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                className="modalCodeEditor"
                style={{
                    maxHeight: "80vh"
                }}
            >
                <>
                    <AceEditor
                        mode="javascript"
                        theme="monokai"
                        onChange={onChange}
                        onBlur={onBlur}
                        fontSize={16}
                        width="100%"
                        focus
                        showGutter
                        wrapEnabled={isWrapped}
                        highlightActiveLine
                        value={scriptText}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true
                        }}
                    />

                    <SwitchBoxStyled>
                        <SwitchLabelStyled>{t("rightPanel:code.editor.iswrapped")}</SwitchLabelStyled>

                        <SwitchButton
                            checked={isWrapped || false}
                            onChange={(event) => {
                                setIsWrapped(event.target.checked);
                            }}
                        />
                    </SwitchBoxStyled>
                </>
            </Modal>
        </>
    );
}

export default ModalEditor;
