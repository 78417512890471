import { useData } from "contexts/dataContext";
import { ContextMenuContext } from "contexts/rightClickContext";
import React, { useContext } from "react";

export const useOnPageLeftClick = (): ((e: React.MouseEvent) => void) => {
    const contextMenuState = useContext(ContextMenuContext);
    const { setItemSelected } = useData();

    return (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setItemSelected(null)

        contextMenuState.setContextMenuState({
            visibility: false
        });
    };
};
