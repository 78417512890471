import { toastError } from "@3edges/utils/dist/toastify";
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MenuItem } from "@material-ui/core";
import ModalEditor from "components/ModalEditor";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType, EUpdateType, NodeTypename } from "components/PrimGraphicalCanvas/types";
import { useUpdateD3Items } from "components/PrimGraphicalCanvas/updateD3Items";
import { useData } from "contexts/dataContext";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    NiamFieldTypeEnum,
    rPScriptGet as RPScriptGet,
    rPUpdateScript as RPUpdateScript
} from "types/operation-result-types";
import { TextField } from "ui-components/styleds";
import { RP_DELETE_SCRIPT, RP_GET_SCRIPT, RP_UPDATE_SCRIPT } from "./gql";
import { Divider, Form, LoadingIconStyled, RightPanelDeleteIconStyled, RightPanelLoadingStyled, SelectFieldStyled } from "./styled";

interface RPScriptProps {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}

function RPScript({ isOpen, setIsOpen }: RPScriptProps): React.ReactElement {
    const { server, setItemSelected, itemSelected  } = useData();
    const onDelete = useDeleteD3Items();
    const onUpdate = useUpdateD3Items();
    const [getData, { data, loading }] = useLazyQuery<RPScriptGet>(RP_GET_SCRIPT, { fetchPolicy: "no-cache" });

    const [name, setName] = useState<string>();
    const [displayName, setDisplayName] = useState<string>();
    const [funcName, setFuncName] = useState<string>();
    const [returnType, setReturnType] = useState<string>();
    const [scriptText, setScriptText] = useState<string>();

    const { t } = useTranslation();
    const [updateScript, { loading: updLoading }] = useMutation<RPUpdateScript>(RP_UPDATE_SCRIPT);
    const [deleteScript] = useMutation(RP_DELETE_SCRIPT);

    const isComponentUnMounted = useRef(false);

    useEffect(() => {
        // standard procedure for processing componentDidUnmount action
        // here, we set isComponentUnMounted.current flag to true
        return () => {
            isComponentUnMounted.current = true;
        }
    }, []);

    const turnToUnsaved = (): void => {
        setItemSelected({ ...itemSelected, hasSaved: false })
    };

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { scriptID: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    useEffect(() => {
        if (data && !loading) {
            setName(data.niamScript.name);
            setDisplayName(data.niamScript.displayName);
            setFuncName(data.niamScript.funcName);
            setReturnType(data.niamScript.returnType);

            if (isNotEmpty(data.niamScript.scriptText)) {
                setScriptText(atob(data.niamScript.scriptText));
            } else {
                setScriptText("");
            }
        }
    }, [loading, data]);

    const handleOnBlurEvent = (event: any): void => {
        if (isOpen && !itemSelected.hasSaved) {
            updateScript({
                variables: {
                    serverID: server._id,
                    scriptID: data.niamScript._id,
                    params: {
                        displayName,
                        name,
                        funcName,
                        returnType,
                        scriptText: btoa(scriptText)
                    }
                }
            }).then(({ errors, data: dataUpdated }: any) => {
                if (errors)
                {
                    for (const e of errors)
                    {
                        if (e.message.includes("NAME_ALREADY_IN_USE"))
                        {
                            toastError(t(`validations:${e.message}`) + displayName);
                            setName(itemSelected.data.displayName)
                        } else
                        {
                            toastError(t(`validations:${e.message}`));
                        }
                    }

                    return;
                }

                onUpdate({
                    id: data.niamScript._id,
                    type: EUpdateType.SCRIPT,
                    newData: dataUpdated.updateNiamScript,
                    rPData: itemSelected.data
                });

                if (isComponentUnMounted.current === false) {
                    setName(dataUpdated.updateNiamScript.name);
                    setDisplayName(dataUpdated.updateNiamScript.displayName);
                    setFuncName(dataUpdated.updateNiamScript.funcName);
                    setReturnType(dataUpdated.updateNiamScript.returnType);

                    if (isNotEmpty(dataUpdated.updateNiamScript.scriptText)) {
                        setScriptText(atob(dataUpdated.updateNiamScript.scriptText));
                    } else {
                        setScriptText("");
                    }

                    itemSelected.data.displayName = dataUpdated.updateNiamScript.displayName
                    setItemSelected({ ...itemSelected, hasSaved: true });
                }
            });
        }
    }

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading || updLoading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <Form>
                <div data-cy="cypressHiddenDiv" />

                <TextField
                    data-cy="rpScriptPage_name"
                    label={t("rightPanel:script.input.name.label")}
                    onChange={(e) => {
                        setName(e.target.value);
                        setDisplayName(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                    value={name || ""}
                />

                <TextField
                    label={t("rightPanel:script.input.funcName.label")}
                    onChange={(e) => {
                        setFuncName(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                    value={funcName || ""}
                />

                <SelectFieldStyled
                    value={returnType || "String"}
                    label={t("rightPanel:script.input.returnType.label")}
                    onChange={(event) => {
                        setReturnType(event.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                >
                    {Object.keys(NiamFieldTypeEnum)
                        .filter((ft) => ft !== "Relationship" && ft !== NodeTypename.NIAM_ENUM && ft !== "Object")
                        .map((niamFieldType) => (
                            <MenuItem key={`niamFieldType-${niamFieldType}`} value={niamFieldType}>
                                {niamFieldType}
                            </MenuItem>
                        ))}
                </SelectFieldStyled>

                <ModalEditor
                    scriptText={scriptText || ""}
                    onChangeScript={(vlr) => {
                        setScriptText(vlr);
                        turnToUnsaved();
                    }}
                    onBlurScript={(e) => {
                        handleOnBlurEvent(e);
                    }}
                />

                <Divider />

                <RightPanelDeleteIconStyled
                    onClick={() => {
                        setIsOpen(false);

                        deleteScript({
                            variables: {
                                scriptID: data.niamScript._id
                            }
                        }).then(({ errors }: any) => {
                            if (errors) {
                                for (const e of errors) {
                                    toastError(t(`validations:${e.message}`));
                                }
                                return;
                            }

                            onDelete({
                                id: data.niamScript._id,
                                type: EDeleteType.SCRIPT
                            });
                        });
                    }}
                />
            </Form>
        </>
    );
}

export default RPScript;
