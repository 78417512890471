import styled from "styled-components";

export const BoxRelative = styled.div`
    position: relative;
    height: 65;
`;

export const BoxAbsolute = styled.div`
    position: absolute;
    right: 0;
    z-index: 1;
`;
