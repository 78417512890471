/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-empty */
import { useLazyQuery } from '@apollo/client';
import awsIcon from "assets/images/aws-icon.png";
import gcpIcon from "assets/images/gcp-icon.png";
import { StatusLightBulb } from "components/ModalDataServer/styledSup";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloudServiceAccounts, saEnum } from 'types/operation-result-types';
import { DelDataServerIconStyled, TextBox } from "ui-components/PanelCard/styled";
import { ALL_STATUS_SA } from './gql';
import { Card, StatusLightIconStyled } from "./styled";

function CardSA ({ sa, onDelete, onSelect, setIsModalOpen }: any): React.ReactElement {
    const { t } = useTranslation();

    const [saStatus, setSAStatus] = useState(saEnum.PROCESSING)
    const [getAllStatusSA, { data: dataAllStatusSA }] = useLazyQuery(ALL_STATUS_SA, { fetchPolicy: "no-cache" });

    useEffect(() => {
        let isMounted = true;
        async function fetchStatus() {
            if (sa) {
                try {
                    if (isMounted) {
                        getAllStatusSA({ variables: { serviceAccountID: sa._id } })
                    }
                } catch {}
            }
        }

        fetchStatus();

        return () => {
            isMounted = false
        };
    }, [sa]);

    useEffect(() => {
        if (dataAllStatusSA) {
            const { getAllStatusSA } = dataAllStatusSA

            if (getAllStatusSA.isProcessing) {
                setSAStatus(saEnum.PROCESSING)
            } else {
                setSAStatus(getAllStatusSA.saStatus)
            }
        }
    }, [dataAllStatusSA])

    return (
        <div style={{ position: "relative", zIndex: 1 }} key={`card-service-account-key-${sa._id}`}>
            <StatusLightIconStyled>
                <StatusLightBulb $isActive={saStatus === saEnum.VALID} $isProcessing={saStatus === saEnum.PROCESSING} title={saStatus} />
            </StatusLightIconStyled>

            <DelDataServerIconStyled>
                <span title={t("serviceaccounts:Modal.deleteBtn")} onClick={() => onDelete(sa._id)} className="material-icons">delete</span>
            </DelDataServerIconStyled>

            <Card $isAdd={false} onClick={() => { setIsModalOpen(true); onSelect(sa) }}>
                {sa.cloud === CloudServiceAccounts.AWS && <img src={awsIcon} alt="" style={{ width: 100, paddingTop: 10, paddingBottom: 13, marginTop: 25, marginBottom: 15 }} />}
                {sa.cloud === CloudServiceAccounts.GCP && <img src={gcpIcon} alt="" style={{ width: 100, marginTop: 25, marginBottom: 15 }} />}

                <TextBox isAdd={false} $smallCard={false} style={{ fontWeight: "bold" }}>
                    {sa.cloud}
                </TextBox>

                <TextBox fontSize="12px" style={{ color: "#AAA" }}>
                    {sa.owner}
                </TextBox>
            </Card>
        </div>
    )
}

export default CardSA
