/* eslint-disable no-loop-func */
import { toastError } from "@3edges/utils/dist/toastify";
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { EUpdateType } from "components/PrimGraphicalCanvas/types";
import { useUpdateD3Items } from "components/PrimGraphicalCanvas/updateD3Items";
import { useData } from "contexts/dataContext";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import
    {
        rPObjectGet as RPObjectGet,
        rPUpdateObject as RPUpdateObject
    } from "types/operation-result-types";
import { RP_GET_OBJECT, RP_UPDATE_OBJECT } from "./gql";
import
    {
        Form, LoadingIconStyled,
        RightPanelLoadingStyled, SwitchBoxStyled, SwitchButton, SwitchLabelStyled
    } from "./styled";

interface RPAuthorizationProps {
    isOpen: boolean;
}

function RpAuthorization({ isOpen }: RPAuthorizationProps): React.ReactElement {
    const { server, setItemSelected, itemSelected } = useData();
    const onUpdate = useUpdateD3Items();
    const [getData, { data, loading }] = useLazyQuery<RPObjectGet>(RP_GET_OBJECT, { fetchPolicy: "no-cache" });

    const [selfRegistration, setSelfRegistration] = useState<boolean>(undefined);

    const { t } = useTranslation();
    const [updateObject, { loading: updLoading }] = useMutation<RPUpdateObject>(RP_UPDATE_OBJECT);

    const isComponentUnMounted = useRef(false);

    useEffect(() => {
        // standard procedure for processing componentDidUnmount action
        // here, we set isComponentUnMounted.current flag to true
        return () => {
            isComponentUnMounted.current = true;
        }
    }, []);

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { objectID: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    const turnToUnsaved = (): void => {
        setItemSelected({ ...itemSelected, hasSaved: false })
    };

    useEffect(() => {
        if (data && !loading) {
            setSelfRegistration(data.niamObject.selfRegistration);
        }
    }, [loading, data]);

    const handleOnBlurEvent = (event: any): void => {
        if (isOpen && !itemSelected.hasSaved) {
            updateObject({
                variables: {
                    serverID: server._id,
                    objectID: data.niamObject._id,
                    params: { displayName: data.niamObject.displayName, name: data.niamObject.name, selfRegistration }
                }
            }).then(({ errors, data: dataUpdated }: any) => {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }

                    return;
                }

                onUpdate({
                    id: data.niamObject._id,
                    type: EUpdateType.OBJECT,
                    newData: dataUpdated.updateNiamObject,
                    rPData: itemSelected.data
                });

                if (isComponentUnMounted.current === false) {
                    setSelfRegistration(dataUpdated.updateNiamObject.selfRegistration);

                    setItemSelected({ ...itemSelected, hasSaved: true });
                }
            });
        }
    }

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading || updLoading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <Form>
                <div data-cy="cypressHiddenDiv" />

                <SwitchBoxStyled>
                    <SwitchLabelStyled>
                        {t("rightPanel:property.input.selfRegistration.label")}
                    </SwitchLabelStyled>

                    <SwitchButton
                        data-cy="rpAuthorization_selfRegistration"
                        checked={selfRegistration || false}
                        onChange={(event) => {
                            setSelfRegistration(event.target.checked);
                            turnToUnsaved();
                        }}
                        onBlur={(e) => {
                            handleOnBlurEvent(e);
                        }}
                    />
                </SwitchBoxStyled>

            </Form>
        </>
    );
}

export default RpAuthorization;
