/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button, Menu, MenuItem } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { AccountCircle, ExitToApp, SettingsOutlined } from "@material-ui/icons";
import MenuIcon from '@material-ui/icons/Menu';
import logoPink from "assets/images/logo-pink.png";
import logo from "assets/images/logo.png";
import { clearStorage, getStorage } from "cache";
import clsx from 'clsx';
import { ConfigurationActions } from "components/ConfigurationActions";
import ModalDS from "components/ModalDataServer";
import { MainNavBarItem } from "components/PrimGraphicalCanvas/styled";
import { useCookie } from "contexts/cookieContext";
import { useData } from "contexts/dataContext";
import { REACT_ENV } from "environmentVariables";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { titleCase } from "utils";
import { OIDC_Logout } from "utilsFn";
import { Header } from "./styled";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    }
  }),
);

function PanelHeader({ refetchPrimInfo, showServerOptions = false, open, handleDrawerClose, handleDrawerOpen }: any): React.ReactElement {
    const { t } = useTranslation();
    const { server, tenant } = useData();
    const { getIDTokenCookie, removeAllCookies } = useCookie();
    const currentCookieIDToken = getIDTokenCookie();

    const history = useHistory();
    const currentUser = getStorage<any>("user");
    const isSocial = getStorage<any>(REACT_ENV.REACT_APP_SOCIAL_PROVIDER_LOCAL_STORAGE_NAME);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editDataServerID, setEditDataServerID] = useState<any | null>();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => {
        if (isModalOpen) {
            setEditDataServerID(server._id);
        }
    }, [isModalOpen]);

    if (!currentUser) {
        clearStorage();
        removeAllCookies();
        window.location.href = "/login"
        return
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void =>
    {
        setAnchorEl(null);
    };

    return (
        <Header>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => open ? handleDrawerClose(false) : handleDrawerOpen(true)}
                edge="start"
                // eslint-disable-next-line react/forbid-component-props
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
            </IconButton>

            <img
                src={logo}
                data-cy="headerPage_btnLogo"
                alt={t("root:project.logo")}
                height="40"
                style={{ cursor: "pointer" }}
                onKeyPress={() => {}}
                onClick={() =>
                {
                    if (server) {
                        if (server && tenant) {
                            history.push(`/${tenant._id}/servers`);
                        } else {
                            history.push("/servers");
                        }
                    } else {
                        history.push("/");
                    }
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.src = logoPink;
                }}
                onMouseOut={(e) => {
                    e.currentTarget.src = logo;
                }}
                onBlur={(e) => {
                    e.currentTarget.src = logo;
                }}
                width="auto"
            />

            {server && showServerOptions && (
                <div style={{ display: 'flex', width: '100%', marginLeft: 15  }}>
                    <ConfigurationActions refetchPrimInfo={refetchPrimInfo} dataCollection={server} openConfig={() => setIsModalOpen(true)} />

                    <MainNavBarItem
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    >
                        {server.name}
                    </MainNavBarItem>

                    <ModalDS
                        refetchPrimInfo={refetchPrimInfo}
                        apiServerID={editDataServerID}
                        isModalOpen={isModalOpen}
                        tenantID={tenant?._id}
                        onClose={(dataSaved?: any, closeModal?: boolean) => {
                            if (!dataSaved || closeModal) {
                                setIsModalOpen(false)
                            }
                        }}
                    />

                </div>
            )}

            <Button onClick={handleClick} data-cy="headerPage_btnSettings" style={{ minWidth: 'auto' }}>
                {/* showing both username and email with a space in between*/}
                <span id="username">
                    {titleCase(currentUser.name)}&nbsp;

                    ({titleCase(currentUser.email)})
                </span>

                <AccountCircle style={{ margin: "0 0 0 16px" }} />
            </Button>

            {/* placing the anchor on the bottom right corner of the button so that the drop down menu shows up directly below the button*/}
            <Menu anchorEl={anchorEl} style={{ top: 28, left:2.7 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'bottom', horizontal: 'right' }} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        history.push("/profile");
                    }}
                >
                    <SettingsOutlined style={{ margin: "0 16px 0 0" }} />

                    {t("auth:profile.title")}
                </MenuItem>

                <MenuItem
                    onClick={async () => {
                        if(isSocial) {
                            handleClose();
                            clearStorage();
                            removeAllCookies();
                            window.location.href = "/login"
                        } else {
                            handleClose();
                            await OIDC_Logout(currentCookieIDToken);
                        }
                    }}
                >
                    <ExitToApp style={{ margin: "0 16px 0 0" }} />

                    {t("auth:sign-out.title")}
                </MenuItem>
            </Menu>
        </Header>
    );
}

export default PanelHeader;
