/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable unicorn/explicit-length-check */
import { certToPEM, rsaPublicKeyToPEM } from "@3edges/utils/dist/cert/utils";
import axios from "axios";
import { getCookie } from "contexts/cookieContext";
import Cookies from "js-cookie";
import { getCookieDomain } from "utils";
import { JwksError, SigningKeyNotFoundError } from "./types/customErrors";
import { JwksOptions, SigningKey } from "./types/types";

export class JwksClient {
    private readonly options: JwksOptions;

    public constructor (options: JwksOptions) {
        this.options = {
            strictSsl: true,
            ...options
        };
    }

    public async getSigningKey (kid: string): Promise<SigningKey> {
        const keys = await this.getSigningKeys();

        const signingKey = keys.find((k) => k.kid === kid);
        if (!signingKey) {
            throw new SigningKeyNotFoundError(`Unable to find a signing key that matches '${kid}'`);
        }

        return signingKey;
    }

    private async getSigningKeys (): Promise<SigningKey[]> {
        const keys = await this.getKeys();

        const signingKeys = keys
            .filter((k) => k.kty === "RSA" && k.kid && (k.x5c && k.x5c.length || k.n && k.e))
            .map((k) => {
                const key: Record<string, string> = { kid: k.kid, nbf: k.nbf };
                if (k.x5c && k.x5c.length > 0) {
                    key.publicKey = certToPEM(k.x5c[0]);
                } else {
                    key.rsaPublicKey = rsaPublicKeyToPEM(k.n, k.e);
                }

                return key;
            });

        if (signingKeys.length === 0) {
                throw new JwksError("The JWKS endpoint did not contain any signing keys");
        }

        return signingKeys;
    }

    private async getKeys () {
        const cookiePKey = getCookie(this.options.REACT_APP_PKEY_COOKIE_NAME)

        try {
            if (!cookiePKey) {

                const { data } = await axios.get(`${this.options.idpUrl}${this.options.jwksUri}`,
                {
                    params: {
                        // strictSSL: this.options.strictSsl
                    },
                    headers: {
                        "Content-Type": "application/json",
                     }
                });

                if (!data.keys || !data?.keys?.length) {
                    throw new JwksError("The JWKS endpoint did not contain any keys");
                }

                if (data)
                {
                    const now = new Date();
                    now.setTime(now.getTime() + 60 * 60 * 1000); // 1 hour

                    let value;

                    if (typeof value !== "string") {
                        value = JSON.stringify(data.keys);
                    }

                    value = value.trim();

                    Cookies.set(this.options.REACT_APP_PKEY_COOKIE_NAME, value, {
                        path: "/",
                        expires: now,
                        domain: getCookieDomain(),
                        secure: window.location.protocol === 'https:' ? true : false,
                        sameSite: 'strict',
                    });
                }

                return data.keys;
            } else {
                const decodeCookie = decodeURIComponent(cookiePKey);
                const parsedCookie = JSON.parse(decodeCookie)
                return parsedCookie
            }

        } catch {
            throw new JwksError("Failed to get certificate keys from oidc provider");
        }
    }
}
