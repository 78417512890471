import { TableCell } from "@material-ui/core";
import styled from "styled-components";

export const TableCellStyled = styled(TableCell)`
    && {
        font-weight: bold;
    }
    .actions {
        display: flex;
        justify-content: flex-end;

        svg {
            min-width: 28px;
            height: 28px;
        }
    }
`;
