/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { base64_encode } from "@3edges/utils/dist/utils";
import { setStorage } from "cache";
import { useCookie } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import Cookies from "js-cookie";
import JWT from 'jsonwebtoken';
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { checkToken } from "utilsFn";
import { generateNonceAndStateOIDC, getCookieDomain } from "../../utils";

export function GoogleAuth (): React.ReactElement {
    const search = useLocation().search;
    const { setCookie } = useCookie();

    useEffect(() =>
    {
        authSocialGoogle()
    }, []);

    const authSocialGoogle = async () => {
        const access_token = new URLSearchParams(search).get('access');
        const id_token = new URLSearchParams(search).get('id');
        const refresh_token = new URLSearchParams(search).get('refresh');

        if(!id_token || !access_token || !refresh_token) {
            window.location.replace(`${window.location.origin}/login`);
            return;
        }

        const { payload } = JWT.decode(id_token, { complete: true });
        const { nonce } = await generateNonceAndStateOIDC()

        setStorage("user", {
            ...payload
        });

        const now = Number.parseInt((Date.now() / 1000).toString());
        const minutes = Math.round(payload.exp - now) / 60;

        const nowNonce = new Date();
        nowNonce.setTime(nowNonce.getTime() + 5 * 60 * 1000); // 5 minutes

        Cookies.set(REACT_ENV.REACT_APP_NONCE_COOKIE_NAME, nonce, {
            path: "/",
            expires: now,
            domain: getCookieDomain(),
            secure: window.location.protocol === 'https:' ? true : false,
            sameSite: 'strict',
        });

        await checkToken(id_token)
        setCookie(REACT_ENV.REACT_APP_ID_TOKEN_COOKIE_NAME, base64_encode(id_token), minutes);
        setCookie(REACT_ENV.REACT_APP_ACCESS_TOKEN_COOKIE_NAME, access_token, minutes);
        setStorage(REACT_ENV.REACT_APP_REFRESH_TOKEN_LOCAL_STORAGE_NAME, refresh_token);
        setStorage(REACT_ENV.REACT_APP_SOCIAL_PROVIDER_LOCAL_STORAGE_NAME, true);

        window.location.replace(window.location.origin);
    }

    return null;
}
