/* eslint-disable unicorn/prefer-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/no-autofocus */
import { toastError, toastSuccess } from "@3edges/utils/dist/toastify";
import { isEmpty } from "@3edges/utils/dist/utils";
import { useMutation } from "@apollo/client";
import { TextFieldProps } from "@material-ui/core";
import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "ui-components/modal";
import { TextField } from "ui-components/styleds";
import { ADD_NEW_PRIM_TENANT, UPDATE_PRIM_TENANT } from "../PanelTenant/gql";
import { GraphQLError } from "graphql";

interface ModalTenantProps {
    isModalOpen: boolean;
    tenantToEdit: {
        _id: string;
        name: string;
    };
    onClose: (dataSaved?: any, closeModal?: boolean) => void;
}

function ModalTenant ({ isModalOpen, tenantToEdit, onClose }: ModalTenantProps): React.ReactElement {
    const { t } = useTranslation();
    const [name, setName] = useState("");

    const [saving, setSaving] = useState(true);
    const [addNewPrimTenant] = useMutation(ADD_NEW_PRIM_TENANT);
    const [updateNiamTenant] = useMutation(UPDATE_PRIM_TENANT);

    useEffect(() => {
        setSaving(false)

        if (!isModalOpen) {
            setName("");
        } else if (tenantToEdit) {
            setName(tenantToEdit.name);
        }
    }, [isModalOpen, tenantToEdit]);

    const showErrors = (errors: readonly GraphQLError[]): void => {
        for (const e of errors) {
            toastError(t(`validations:${e.message}`));
        }

        return;
    };

    const onSuccess = (dataSaved = null, closeModal = false) => {
        toastSuccess(t("rightPanel:alert.save.success"));

        if (dataSaved) {
            onClose(dataSaved, closeModal)
        }
    }

    const onConfirm = async () => {
        setSaving(true)

        let res: any;

        if (tenantToEdit) {
            res = await updateNiamTenant({ variables: { tenantID: tenantToEdit._id, name } })
        } else {
            res = await addNewPrimTenant({ variables: { name } })
        }

        if (res.errors) {
            setSaving(false)
            showErrors(res.errors);
            onSuccess(null, false)
        }

        if (res.data?.addNewNiamTenant) {
            onSuccess(res.data?.addNewNiamTenant, true)
        } else {
            onSuccess(res.data?.updateNiamTenant, false)
        }
    };

    const onChange: TextFieldProps["onChange"] = (e) => {
        setName(e.target.value);
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => onClose()}
            style={{ width: "600px" }}
        >
            <h1>
                {t("tenant:ModalTenant.title")}
            </h1>

            <TextField
                label={t("tenant:ModalTenant.name.label")}
                autoFocus
                data-cy="modalOrganizationPage_name"
                placeholder={t("tenant:ModalTenant.name.placeholder")}
                value={name}
                onChange={onChange}
            />

            <ButtonListFlex>
                <ConfirmButton onClick={onConfirm} disabled={saving || isEmpty(name)}>
                    Save
                </ConfirmButton>
            </ButtonListFlex>
        </Modal>
    );
}

export default ModalTenant;
