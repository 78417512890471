import { useData } from "contexts/dataContext";
import { RPData, RPOrbitData } from "typedefs";
import { NodeTypename, RelationshipType } from "../types";
import { DataPoint, UseZoomOnLinksParams } from "./types";

const returnTypeOfOrbit = (__typename: string): RPOrbitData["type"] => {
    switch (__typename) {
        case NodeTypename.NIAM_OBJECT:
            return "Object";
        case NodeTypename.NIAM_SCRIPT:
            return "Script";
        case NodeTypename.NIAM_INTERFACE:
            return "Interface";
        case NodeTypename.NIAM_CUSTOM_MUTATION:
            return "CustomMutation";
        case NodeTypename.NIAM_NODE_RELATIONSHIP:
            return "NodeRelationship";
        case NodeTypename.NIAM_TAG:
            return "Tag";
        case NodeTypename.NIAM_ENUM:
            return "Enum";
    }
};

const UseZoomOnLinks = (): (({
    zoomInstance,
    width,
    height,
    container
}: UseZoomOnLinksParams) => (e: React.DragEvent<SVGSVGElement>, d: DataPoint) => void) => {
    const { setItemSelected } = useData();

    return ({ zoomInstance, width, height, container }: UseZoomOnLinksParams) => (e: React.DragEvent<SVGSVGElement>, d) =>
    {
            e.preventDefault();
            e.stopPropagation();

            let typeProperty: RPData["type"] = "Property";

            if (d.type === RelationshipType.IMPLEMENT) {
                typeProperty = "Implement";
            } else if (d.source.__typename === NodeTypename.NIAM_SCRIPT) {
                typeProperty = "PropertyRelationship";
            }

            if (d.type !== RelationshipType.NODE_RELATION) {
                setItemSelected({
                    data: {
                        type: typeProperty,
                        _id: d._id,
                        parent: {
                            _id: d.source._id,
                            displayName: d.source.displayName,
                            __typename: d.source.__typename,
                            type: returnTypeOfOrbit(d.source.__typename),
                            x: d.source.x,
                            y: d.source.y
                        },
                        relatedTo: {
                            _id: d.target._id,
                            displayName: d.target.displayName,
                            __typename: d.target.__typename,
                            type: returnTypeOfOrbit(d.target.__typename),
                            x: d.target.x,
                            y: d.target.y
                        },
                    },
                    hasSaved: true
                });
            }
        };
};

export default UseZoomOnLinks;
