/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewNiamDataServer
// ====================================================

export interface addNewNiamDataServer_addNewNiamDataServer_cluster {
  __typename: "NiamCluster";
  _id: string;
  clusterID: string | null;
  name: string | null;
  location: string | null;
}

export interface addNewNiamDataServer_addNewNiamDataServer_serviceAccount {
  __typename: "NiamServiceAccount";
  _id: string | null;
  cloud: CloudServiceAccounts | null;
}

export interface addNewNiamDataServer_addNewNiamDataServer_authZServer {
  __typename: "NiamAuthZServer";
  _id: string;
  connectionURL: string | null;
  lastUpdate: any | null;
  defaultOperator: OperatorEnum | null;
  allowByDefault: boolean | null;
  selfRelDepth: number | null;
  isMostPermissive: boolean | null;
  actionPermissionOrder: string | null;
}

export interface addNewNiamDataServer_addNewNiamDataServer_administrators {
  __typename: "User";
  roles: Role[] | null;
  email: string;
}

export interface addNewNiamDataServer_addNewNiamDataServer_owners {
  __typename: "User";
  _id: string;
  email: string;
}

export interface addNewNiamDataServer_addNewNiamDataServer {
  __typename: "NiamDataServer";
  _id: string;
  name: string;
  description: string | null;
  connectionURL: string | null;
  clientNameGCP: string | null;
  CLUSTER_SELECTED: string | null;
  clusterAutopilot: boolean | null;
  clusterLocation: string | null;
  cluster: addNewNiamDataServer_addNewNiamDataServer_cluster | null;
  serviceAccount: addNewNiamDataServer_addNewNiamDataServer_serviceAccount | null;
  autoDeployApiServer: boolean | null;
  dbType: string | null;
  dbVersion: string | null;
  dbHost: string | null;
  dbName: string | null;
  dbUser: string | null;
  dbPass: string | null;
  lastUpdate: any | null;
  NEO4J_POOL_SIZE: string | null;
  NEO4J_MAX_CONNECTION_LIFETIME: string | null;
  NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS: string | null;
  NEO4J_CONNECTION_TIMEOUT: string | null;
  EXTERNAL_PROVIDER: boolean | null;
  OIDC_URL: string | null;
  CLAIMS_ARRAY: string | null;
  AUTHN_QUERY: string | null;
  EXTERNAL_OIDC_URL: string | null;
  OIDC_TOKEN_ENDPOINT: string | null;
  OIDC_TOKEN_INTROSPECTION_ENDPOINT: string | null;
  ACCESS_TOKEN_TYPE: string | null;
  OIDC_JWKS_URI: string | null;
  EXTERNAL_PROVIDER_NAME: string | null;
  OIDC_ME_ENDPOINT: string | null;
  OIDC_AUTHORIZE_ENDPOINT: string | null;
  OIDC_LOGOUT_ENDPOINT: string | null;
  OIDC_PROVIDER_CLAIMS_MAPPING: string | null;
  OIDC_CLIENT_ID: string | null;
  OIDC_CLIENT_PWD: string | null;
  authZServer: addNewNiamDataServer_addNewNiamDataServer_authZServer | null;
  administrators: (addNewNiamDataServer_addNewNiamDataServer_administrators | null)[] | null;
  owners: (addNewNiamDataServer_addNewNiamDataServer_owners | null)[] | null;
  authzCSPConnectionURL: string | null;
  dashboardServerURL: string | null;
  lastReleaseDate: string | null;
}

export interface addNewNiamDataServer {
  addNewNiamDataServer: addNewNiamDataServer_addNewNiamDataServer;
}

export interface addNewNiamDataServerVariables {
  name: string;
  description?: string | null;
  serviceAccountID: string;
  clusterID?: string | null;
  AUTHN_QUERY?: string | null;
  CLAIMS_ARRAY?: string | null;
  connectionURL?: string | null;
  randomName: string;
  CLUSTER_SELECTED: string;
  clusterAutopilot?: boolean | null;
  clusterLocation?: string | null;
  autoDeployApiServer?: boolean | null;
  dbParams: DBParams;
  authzParams: AuthzParams;
  authzCSPConnectionURL?: string | null;
  oidcParams: OIDCParams;
  dashboardParams: DashboardParams;
  lastReleaseDate?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewNiamTenantDataServer
// ====================================================

export interface addNewNiamTenantDataServer_addNewNiamTenantDataServer_serviceAccount {
  __typename: "NiamServiceAccount";
  _id: string | null;
  cloud: CloudServiceAccounts | null;
}

export interface addNewNiamTenantDataServer_addNewNiamTenantDataServer_cluster {
  __typename: "NiamCluster";
  _id: string;
  clusterID: string | null;
  name: string | null;
  location: string | null;
}

export interface addNewNiamTenantDataServer_addNewNiamTenantDataServer_authZServer {
  __typename: "NiamAuthZServer";
  _id: string;
  connectionURL: string | null;
  lastUpdate: any | null;
  defaultOperator: OperatorEnum | null;
  allowByDefault: boolean | null;
  selfRelDepth: number | null;
  isMostPermissive: boolean | null;
  actionPermissionOrder: string | null;
}

export interface addNewNiamTenantDataServer_addNewNiamTenantDataServer_administrators {
  __typename: "User";
  roles: Role[] | null;
  email: string;
}

export interface addNewNiamTenantDataServer_addNewNiamTenantDataServer_owners {
  __typename: "User";
  _id: string;
  email: string;
}

export interface addNewNiamTenantDataServer_addNewNiamTenantDataServer {
  __typename: "NiamDataServer";
  _id: string;
  name: string;
  description: string | null;
  connectionURL: string | null;
  clientNameGCP: string | null;
  CLUSTER_SELECTED: string | null;
  clusterAutopilot: boolean | null;
  clusterLocation: string | null;
  serviceAccount: addNewNiamTenantDataServer_addNewNiamTenantDataServer_serviceAccount | null;
  cluster: addNewNiamTenantDataServer_addNewNiamTenantDataServer_cluster | null;
  autoDeployApiServer: boolean | null;
  dbType: string | null;
  dbVersion: string | null;
  dbHost: string | null;
  dbName: string | null;
  dbUser: string | null;
  dbPass: string | null;
  lastUpdate: any | null;
  NEO4J_POOL_SIZE: string | null;
  NEO4J_MAX_CONNECTION_LIFETIME: string | null;
  NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS: string | null;
  NEO4J_CONNECTION_TIMEOUT: string | null;
  EXTERNAL_PROVIDER: boolean | null;
  OIDC_URL: string | null;
  CLAIMS_ARRAY: string | null;
  AUTHN_QUERY: string | null;
  EXTERNAL_OIDC_URL: string | null;
  OIDC_TOKEN_ENDPOINT: string | null;
  OIDC_TOKEN_INTROSPECTION_ENDPOINT: string | null;
  ACCESS_TOKEN_TYPE: string | null;
  OIDC_JWKS_URI: string | null;
  EXTERNAL_PROVIDER_NAME: string | null;
  OIDC_ME_ENDPOINT: string | null;
  OIDC_AUTHORIZE_ENDPOINT: string | null;
  OIDC_LOGOUT_ENDPOINT: string | null;
  OIDC_PROVIDER_CLAIMS_MAPPING: string | null;
  OIDC_CLIENT_ID: string | null;
  OIDC_CLIENT_PWD: string | null;
  authZServer: addNewNiamTenantDataServer_addNewNiamTenantDataServer_authZServer | null;
  administrators: (addNewNiamTenantDataServer_addNewNiamTenantDataServer_administrators | null)[] | null;
  owners: (addNewNiamTenantDataServer_addNewNiamTenantDataServer_owners | null)[] | null;
  authzCSPConnectionURL: string | null;
  dashboardServerURL: string | null;
  lastReleaseDate: string | null;
}

export interface addNewNiamTenantDataServer {
  addNewNiamTenantDataServer: addNewNiamTenantDataServer_addNewNiamTenantDataServer;
}

export interface addNewNiamTenantDataServerVariables {
  name: string;
  description?: string | null;
  serviceAccountID: string;
  clusterID?: string | null;
  AUTHN_QUERY?: string | null;
  CLAIMS_ARRAY?: string | null;
  connectionURL?: string | null;
  randomName: string;
  CLUSTER_SELECTED: string;
  clusterAutopilot?: boolean | null;
  clusterLocation?: string | null;
  autoDeployApiServer?: boolean | null;
  dbParams: DBParams;
  authzParams: AuthzParams;
  authzCSPConnectionURL?: string | null;
  oidcParams: OIDCParams;
  dashboardParams: DashboardParams;
  tenantID: string;
  lastReleaseDate?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSchemaDB
// ====================================================

export interface deleteSchemaDB {
  deleteSchemaDB: any | null;
}

export interface deleteSchemaDBVariables {
  serverID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateNiamDataServer
// ====================================================

export interface updateNiamDataServer_updateNiamDataServer_serviceAccount {
  __typename: "NiamServiceAccount";
  _id: string | null;
  cloud: CloudServiceAccounts | null;
}

export interface updateNiamDataServer_updateNiamDataServer_cluster {
  __typename: "NiamCluster";
  _id: string;
  clusterID: string | null;
  name: string | null;
  location: string | null;
}

export interface updateNiamDataServer_updateNiamDataServer_authZServer {
  __typename: "NiamAuthZServer";
  _id: string;
  connectionURL: string | null;
  lastUpdate: any | null;
  defaultOperator: OperatorEnum | null;
  allowByDefault: boolean | null;
  selfRelDepth: number | null;
  isMostPermissive: boolean | null;
  actionPermissionOrder: string | null;
}

export interface updateNiamDataServer_updateNiamDataServer_administrators {
  __typename: "User";
  roles: Role[] | null;
  email: string;
}

export interface updateNiamDataServer_updateNiamDataServer_owners {
  __typename: "User";
  _id: string;
  email: string;
}

export interface updateNiamDataServer_updateNiamDataServer {
  __typename: "NiamDataServer";
  _id: string;
  name: string;
  description: string | null;
  connectionURL: string | null;
  clientNameGCP: string | null;
  CLUSTER_SELECTED: string | null;
  clusterAutopilot: boolean | null;
  clusterLocation: string | null;
  serviceAccount: updateNiamDataServer_updateNiamDataServer_serviceAccount | null;
  cluster: updateNiamDataServer_updateNiamDataServer_cluster | null;
  autoDeployApiServer: boolean | null;
  dbType: string | null;
  dbVersion: string | null;
  dbHost: string | null;
  dbName: string | null;
  dbUser: string | null;
  dbPass: string | null;
  lastUpdate: any | null;
  MAX_TROTTLE_COST: string | null;
  NEO4J_POOL_SIZE: string | null;
  NEO4J_MAX_CONNECTION_LIFETIME: string | null;
  NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS: string | null;
  NEO4J_CONNECTION_TIMEOUT: string | null;
  EXTERNAL_PROVIDER: boolean | null;
  OIDC_URL: string | null;
  CLAIMS_ARRAY: string | null;
  AUTHN_QUERY: string | null;
  EXTERNAL_OIDC_URL: string | null;
  OIDC_TOKEN_ENDPOINT: string | null;
  OIDC_TOKEN_INTROSPECTION_ENDPOINT: string | null;
  ACCESS_TOKEN_TYPE: string | null;
  OIDC_JWKS_URI: string | null;
  EXTERNAL_PROVIDER_NAME: string | null;
  OIDC_ME_ENDPOINT: string | null;
  OIDC_AUTHORIZE_ENDPOINT: string | null;
  OIDC_LOGOUT_ENDPOINT: string | null;
  OIDC_PROVIDER_CLAIMS_MAPPING: string | null;
  OIDC_CLIENT_ID: string | null;
  OIDC_CLIENT_PWD: string | null;
  authZServer: updateNiamDataServer_updateNiamDataServer_authZServer | null;
  administrators: (updateNiamDataServer_updateNiamDataServer_administrators | null)[] | null;
  owners: (updateNiamDataServer_updateNiamDataServer_owners | null)[] | null;
  authzCSPConnectionURL: string | null;
  dashboardServerURL: string | null;
}

export interface updateNiamDataServer {
  updateNiamDataServer: updateNiamDataServer_updateNiamDataServer;
}

export interface updateNiamDataServerVariables {
  removeOldCluster?: boolean | null;
  serverID: string;
  name: string;
  description?: string | null;
  serviceAccountID: string;
  clusterID?: string | null;
  authzCSPConnectionURL?: string | null;
  AUTHN_QUERY?: string | null;
  CLAIMS_ARRAY?: string | null;
  connectionURL?: string | null;
  randomName: string;
  CLUSTER_SELECTED: string;
  clusterAutopilot?: boolean | null;
  clusterLocation?: string | null;
  tenantID?: string | null;
  dbParams: DBParams;
  authzParams: AuthzParams;
  oidcParams: OIDCParams;
  dashboardParams: DashboardParams;
  MAX_TROTTLE_COST?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkUrlHealth
// ====================================================

export interface checkUrlHealth_checkUrlHealth {
  __typename: "CheckUrlHealthResponse";
  status: boolean | null;
}

export interface checkUrlHealth {
  checkUrlHealth: checkUrlHealth_checkUrlHealth;
}

export interface checkUrlHealthVariables {
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkConnectionURL
// ====================================================

export interface checkConnectionURL_checkConnectionURL_dataservers {
  __typename: "NiamDataServer";
  _id: string;
  name: string;
}

export interface checkConnectionURL_checkConnectionURL {
  __typename: "CheckConnectionURLResponse";
  status: boolean | null;
  dataservers: (checkConnectionURL_checkConnectionURL_dataservers | null)[] | null;
}

export interface checkConnectionURL {
  checkConnectionURL: checkConnectionURL_checkConnectionURL;
}

export interface checkConnectionURLVariables {
  connectionURL?: string | null;
  serverID?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkDatabaseConnection
// ====================================================

export interface checkDatabaseConnection {
  checkDatabaseConnection: boolean;
}

export interface checkDatabaseConnectionVariables {
  dbParams: DBParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: loadSchemaDB
// ====================================================

export interface loadSchemaDB {
  loadSchemaDB: boolean;
}

export interface loadSchemaDBVariables {
  serverID: string;
  withProperties?: boolean | null;
  withRelProperties?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkDNS
// ====================================================

export interface checkDNS_checkDNS {
  __typename: "CheckDNSResponse";
  status: boolean;
}

export interface checkDNS {
  checkDNS: checkDNS_checkDNS;
}

export interface checkDNSVariables {
  connectionURL?: string | null;
  cloud?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDataServerSchema
// ====================================================

export interface updateDataServerSchema_updateDataServerSchema {
  __typename: "ServerStatus";
  lastUpdate: any | null;
  status: ClusterEnum | null;
  connectionURL: string | null;
}

export interface updateDataServerSchema {
  updateDataServerSchema: updateDataServerSchema_updateDataServerSchema | null;
}

export interface updateDataServerSchemaVariables {
  serverID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: importConfigVC
// ====================================================

export interface importConfigVC {
  importConfigVC: boolean | null;
}

export interface importConfigVCVariables {
  serverID: string;
  VP: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateToReleasedEachApis
// ====================================================

export interface updateToReleasedEachApis {
  updateToReleasedEachApis: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateToReleasedSpecificWorkload
// ====================================================

export interface updateToReleasedSpecificWorkload_updateToReleasedSpecificWorkload {
  __typename: "UpdateToReleasedSpecificWorkloadResponse";
  status: boolean | null;
}

export interface updateToReleasedSpecificWorkload {
  updateToReleasedSpecificWorkload: updateToReleasedSpecificWorkload_updateToReleasedSpecificWorkload;
}

export interface updateToReleasedSpecificWorkloadVariables {
  clientNameGCP: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProfile
// ====================================================

export interface updateProfile_updateProfile {
  __typename: "UserProfileResponse";
  _id: string;
  company: string | null;
  name: string | null;
  address: string | null;
  country: string | null;
  mobilePhone: string | null;
}

export interface updateProfile {
  updateProfile: updateProfile_updateProfile | null;
}

export interface updateProfileVariables {
  userID: string;
  params: updateProfileParamsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getListOfApisRunning
// ====================================================

export interface getListOfApisRunning_getListOfApisRunning {
  __typename: "GetListOfApisRunningResponse";
  serverID: string;
  clientNameGCP: string;
  tenantID: string | null;
  tenantName: string | null;
}

export interface getListOfApisRunning {
  getListOfApisRunning: (getListOfApisRunning_getListOfApisRunning | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEmail
// ====================================================

export interface updateEmail {
  updateEmail: boolean | null;
}

export interface updateEmailVariables {
  userID: string;
  params: updateEmailParamsInput;
  emailOptions: emailOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePWD
// ====================================================

export interface updatePWD {
  updatePWD: boolean | null;
}

export interface updatePWDVariables {
  userID: string;
  params: updatePWDParamsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAccount
// ====================================================

export interface deleteAccount {
  deleteAccount: boolean | null;
}

export interface deleteAccountVariables {
  userID: string;
  params: deleteAccountParamsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminDeleteUser
// ====================================================

export interface adminDeleteUser {
  adminDeleteUser: boolean;
}

export interface adminDeleteUserVariables {
  userID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllDataServers
// ====================================================

export interface GetAllDataServers_niamDataServers_serviceAccount {
  __typename: "NiamServiceAccount";
  _id: string | null;
  cloud: CloudServiceAccounts | null;
}

export interface GetAllDataServers_niamDataServers_cluster {
  __typename: "NiamCluster";
  _id: string;
  clusterID: string | null;
  name: string | null;
  location: string | null;
}

export interface GetAllDataServers_niamDataServers_authZServer {
  __typename: "NiamAuthZServer";
  _id: string;
  connectionURL: string | null;
  lastUpdate: any | null;
  defaultOperator: OperatorEnum | null;
  allowByDefault: boolean | null;
  selfRelDepth: number | null;
  isMostPermissive: boolean | null;
  actionPermissionOrder: string | null;
}

export interface GetAllDataServers_niamDataServers_administrators {
  __typename: "User";
  roles: Role[] | null;
  email: string;
}

export interface GetAllDataServers_niamDataServers_owners {
  __typename: "User";
  _id: string;
  email: string;
}

export interface GetAllDataServers_niamDataServers {
  __typename: "NiamDataServer";
  _id: string;
  name: string;
  description: string | null;
  CLUSTER_SELECTED: string | null;
  clientNameGCP: string | null;
  autoDeployApiServer: boolean | null;
  serviceAccount: GetAllDataServers_niamDataServers_serviceAccount | null;
  cluster: GetAllDataServers_niamDataServers_cluster | null;
  authZServer: GetAllDataServers_niamDataServers_authZServer | null;
  administrators: (GetAllDataServers_niamDataServers_administrators | null)[] | null;
  owners: (GetAllDataServers_niamDataServers_owners | null)[] | null;
}

export interface GetAllDataServers {
  niamDataServers: GetAllDataServers_niamDataServers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sendEmailToClients
// ====================================================

export interface sendEmailToClients {
  sendEmailToClients: any | null;
}

export interface sendEmailToClientsVariables {
  local: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: shutdownSpecificApi
// ====================================================

export interface shutdownSpecificApi_shutdownSpecificApi {
  __typename: "ShutdownSpecificApiResponse";
  status: boolean | null;
}

export interface shutdownSpecificApi {
  shutdownSpecificApi: shutdownSpecificApi_shutdownSpecificApi;
}

export interface shutdownSpecificApiVariables {
  clientNameGCP: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: reDeploySpecificApi
// ====================================================

export interface reDeploySpecificApi_reDeploySpecificApi {
  __typename: "ReDeploySpecificApiResponse";
  status: boolean | null;
}

export interface reDeploySpecificApi {
  reDeploySpecificApi: reDeploySpecificApi_reDeploySpecificApi;
}

export interface reDeploySpecificApiVariables {
  clientNameGCP: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateStartStop
// ====================================================

export interface updateStartStop_updateStartStop {
  __typename: "podResponse";
  status: podEnum | null;
}

export interface updateStartStop {
  updateStartStop: updateStartStop_updateStartStop;
}

export interface updateStartStopVariables {
  serverID: string;
  start: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCredentialsInfo
// ====================================================

export interface getCredentialsInfo_getCredentialsInfo_niamSvcAcctClient {
  __typename: "NiamSvcAcctClient";
  client_id: string | null;
  client_secret: string | null;
  ENC_KEY: string | null;
}

export interface getCredentialsInfo_getCredentialsInfo_niamSvcAcct {
  __typename: "NiamSvcAcct";
  username: string | null;
  pwd: string | null;
}

export interface getCredentialsInfo_getCredentialsInfo {
  __typename: "getCredentialsInfoResponse";
  apiKey: string | null;
  apiEnabled: boolean | null;
  superUserID: string | null;
  authz3edgesID: string | null;
  authz3edgesPWD: string | null;
  proxy3edgesID: string | null;
  proxy3edgesPWD: string | null;
  authzClientID: string | null;
  authzClientPWD: string | null;
  proxyClientID: string | null;
  proxyClientPWD: string | null;
  niamSvcAcctClient: getCredentialsInfo_getCredentialsInfo_niamSvcAcctClient | null;
  niamSvcAcct: getCredentialsInfo_getCredentialsInfo_niamSvcAcct | null;
}

export interface getCredentialsInfo {
  getCredentialsInfo: getCredentialsInfo_getCredentialsInfo;
}

export interface getCredentialsInfoVariables {
  serverID?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllStatus
// ====================================================

export interface getAllStatus_getAllStatus {
  __typename: "getAllStatusResponse";
  podStatus: podEnum | null;
  clusterStatus: ClusterEnum | null;
  lastUpdate: any | null;
  isProcessing: boolean | null;
  cached: boolean | null;
  niamLastReleaseDate: string | null;
  lastReleaseDate: string | null;
  podError: string | null;
  clientNameGCP: string | null;
}

export interface getAllStatus {
  getAllStatus: getAllStatus_getAllStatus;
}

export interface getAllStatusVariables {
  serverID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllDataServersByTenant
// ====================================================

export interface getAllDataServersByTenant_niamDataServers_serviceAccount {
  __typename: "NiamServiceAccount";
  _id: string | null;
  cloud: CloudServiceAccounts | null;
}

export interface getAllDataServersByTenant_niamDataServers_cluster {
  __typename: "NiamCluster";
  _id: string;
  clusterID: string | null;
  name: string | null;
  location: string | null;
}

export interface getAllDataServersByTenant_niamDataServers_authZServer {
  __typename: "NiamAuthZServer";
  _id: string;
  connectionURL: string | null;
  lastUpdate: any | null;
  defaultOperator: OperatorEnum | null;
  allowByDefault: boolean | null;
  selfRelDepth: number | null;
  isMostPermissive: boolean | null;
  actionPermissionOrder: string | null;
}

export interface getAllDataServersByTenant_niamDataServers_administrators {
  __typename: "User";
  roles: Role[] | null;
  email: string;
}

export interface getAllDataServersByTenant_niamDataServers_owners {
  __typename: "User";
  _id: string;
  email: string;
}

export interface getAllDataServersByTenant_niamDataServers {
  __typename: "NiamDataServer";
  _id: string;
  name: string;
  description: string | null;
  CLUSTER_SELECTED: string | null;
  clientNameGCP: string | null;
  autoDeployApiServer: boolean | null;
  serviceAccount: getAllDataServersByTenant_niamDataServers_serviceAccount | null;
  cluster: getAllDataServersByTenant_niamDataServers_cluster | null;
  authZServer: getAllDataServersByTenant_niamDataServers_authZServer | null;
  lastReleaseDate: string | null;
  administrators: (getAllDataServersByTenant_niamDataServers_administrators | null)[] | null;
  owners: (getAllDataServersByTenant_niamDataServers_owners | null)[] | null;
}

export interface getAllDataServersByTenant {
  niamDataServers: getAllDataServersByTenant_niamDataServers[];
}

export interface getAllDataServersByTenantVariables {
  tenantID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteNiamDataServer
// ====================================================

export interface deleteNiamDataServer {
  deleteNiamDataServer: boolean | null;
}

export interface deleteNiamDataServerVariables {
  serverID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: saveApiKeyDisabledEnabled
// ====================================================

export interface saveApiKeyDisabledEnabled {
  saveApiKeyDisabledEnabled: any | null;
}

export interface saveApiKeyDisabledEnabledVariables {
  serverID: string;
  apiEnabled?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllTenants
// ====================================================

export interface GetAllTenants_niamTenants_administrators {
  __typename: "User";
  roles: Role[] | null;
  email: string;
}

export interface GetAllTenants_niamTenants_owners {
  __typename: "User";
  _id: string;
  email: string;
}

export interface GetAllTenants_niamTenants {
  __typename: "NiamTenant";
  _id: string;
  name: string;
  administrators: GetAllTenants_niamTenants_administrators[] | null;
  owners: (GetAllTenants_niamTenants_owners | null)[] | null;
}

export interface GetAllTenants {
  niamTenants: GetAllTenants_niamTenants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTenantByID
// ====================================================

export interface GetTenantByID_niamTenant_administrators {
  __typename: "User";
  roles: Role[] | null;
  email: string;
}

export interface GetTenantByID_niamTenant_owners {
  __typename: "User";
  _id: string;
  email: string;
}

export interface GetTenantByID_niamTenant {
  __typename: "NiamTenantResponse";
  _id: string;
  name: string;
  administrators: GetTenantByID_niamTenant_administrators[] | null;
  owners: (GetTenantByID_niamTenant_owners | null)[] | null;
}

export interface GetTenantByID {
  niamTenant: GetTenantByID_niamTenant | null;
}

export interface GetTenantByIDVariables {
  tenantID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteTenant
// ====================================================

export interface deleteTenant {
  deleteNiamTenant: boolean | null;
}

export interface deleteTenantVariables {
  tenantID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddNewNiamTenant
// ====================================================

export interface AddNewNiamTenant_addNewNiamTenant_administrators {
  __typename: "User";
  roles: Role[] | null;
  email: string;
}

export interface AddNewNiamTenant_addNewNiamTenant_owners {
  __typename: "User";
  _id: string;
  email: string;
}

export interface AddNewNiamTenant_addNewNiamTenant {
  __typename: "NiamTenant";
  _id: string;
  name: string;
  administrators: AddNewNiamTenant_addNewNiamTenant_administrators[] | null;
  owners: (AddNewNiamTenant_addNewNiamTenant_owners | null)[] | null;
}

export interface AddNewNiamTenant {
  addNewNiamTenant: AddNewNiamTenant_addNewNiamTenant;
}

export interface AddNewNiamTenantVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateNiamTenant
// ====================================================

export interface updateNiamTenant_updateNiamTenant {
  __typename: "NiamTenant";
  _id: string;
  name: string;
}

export interface updateNiamTenant {
  updateNiamTenant: updateNiamTenant_updateNiamTenant;
}

export interface updateNiamTenantVariables {
  tenantID: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: exportConfig
// ====================================================

export interface exportConfig_exportConfig_nodes_data_properties_NiamTag {
  __typename: "NiamTag";
  name: string;
  displayName: string | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamObject {
  __typename: "NiamObject";
  name: string;
  description: string | null;
  displayName: string | null;
  icon: string | null;
  isSubject: boolean | null;
  isAction: boolean | null;
  isVC: boolean | null;
  isHolder: boolean | null;
  isAndOperator: boolean | null;
  isOrOperator: boolean | null;
  isIssuer: boolean | null;
  isClaim: boolean | null;
  isResource: boolean | null;
  selfRegistration: boolean | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamEnum {
  __typename: "NiamEnum";
  name: string;
  displayName: string | null;
  enumerates: string[] | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamInterface {
  __typename: "NiamInterface";
  name: string;
  displayName: string | null;
  icon: string | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamField {
  __typename: "NiamField";
  name: string;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  relatedToName: string | null;
  isArray: boolean;
  icon: string | null;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  maxLength: number | null;
  minLength: number | null;
  relLabelName: string | null;
  direction: Direction | null;
  accessibleOnOppositeSide: boolean | null;
  oppositeSideFieldName: string | null;
  regex: string | null;
  isUnique: boolean | null;
  isDisplay: boolean | null;
  customValidationFunc: string | null;
  properties: any | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamCustomMutation {
  __typename: "NiamCustomMutation";
  name: string;
  displayName: string | null;
  args: any | null;
  mutatuinName: string | null;
  inputSchema: string | null;
  returnType: string | null;
  script: string | null;
  isResource: boolean | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamScript {
  __typename: "NiamScript";
  name: string;
  displayName: string | null;
  returnType: string | null;
  funcName: string | null;
  scriptText: string | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_objFrom {
  __typename: "NiamObject";
  name: string;
  displayName: string | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_objTo {
  __typename: "NiamObject";
  name: string;
  displayName: string | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_rel {
  __typename: "NiamNodeRelationship";
  name: string;
  displayName: string | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_relFrom {
  __typename: "NiamNodeRelationshipRel";
  name: string;
  displayName: string | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_relTo {
  __typename: "NiamNodeRelationshipRel";
  name: string;
  displayName: string | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_script {
  __typename: "NiamScript";
  name: string;
  displayName: string | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_relScript {
  __typename: "NiamNodeRelationshipRel";
  name: string;
  displayName: string | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation {
  __typename: "ResponseNodeRelationship";
  objFrom: exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_objFrom | null;
  objTo: exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_objTo | null;
  rel: exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_rel | null;
  relFrom: exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_relFrom | null;
  relTo: exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_relTo | null;
  script: exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_script | null;
  relScript: exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation_relScript | null;
}

export interface exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship {
  __typename: "NiamNodeRelationship";
  name: string;
  displayName: string | null;
  description: string | null;
  isResource: boolean | null;
  fieldType: NiamFieldTypeEnum;
  relation: exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship_relation | null;
}

export type exportConfig_exportConfig_nodes_data_properties = exportConfig_exportConfig_nodes_data_properties_NiamTag | exportConfig_exportConfig_nodes_data_properties_NiamObject | exportConfig_exportConfig_nodes_data_properties_NiamEnum | exportConfig_exportConfig_nodes_data_properties_NiamInterface | exportConfig_exportConfig_nodes_data_properties_NiamField | exportConfig_exportConfig_nodes_data_properties_NiamCustomMutation | exportConfig_exportConfig_nodes_data_properties_NiamScript | exportConfig_exportConfig_nodes_data_properties_NiamNodeRelationship;

export interface exportConfig_exportConfig_nodes_data {
  __typename: "ConfigGraphNode";
  labels: string[];
  id: string;
  name: string;
  x: number | null;
  y: number | null;
  properties: exportConfig_exportConfig_nodes_data_properties | null;
}

export interface exportConfig_exportConfig_nodes {
  __typename: "ConfigGraphNodeData";
  data: exportConfig_exportConfig_nodes_data;
}

export interface exportConfig_exportConfig_edges_data_properties {
  __typename: "EdgeFields";
  name: string | null;
  displayName: string | null;
  relatedTo: string | null;
  fieldType: string | null;
  eventType: string | null;
}

export interface exportConfig_exportConfig_edges_data {
  __typename: "ConfigGraphEdge";
  source: string;
  target: string;
  name: string;
  id: string;
  properties: exportConfig_exportConfig_edges_data_properties | null;
}

export interface exportConfig_exportConfig_edges {
  __typename: "ConfigGraphEdgeData";
  data: exportConfig_exportConfig_edges_data;
}

export interface exportConfig_exportConfig {
  __typename: "ConfigGraph";
  nodes: (exportConfig_exportConfig_nodes | null)[];
  edges: (exportConfig_exportConfig_edges | null)[];
}

export interface exportConfig {
  exportConfig: exportConfig_exportConfig;
}

export interface exportConfigVariables {
  serverID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: importConfig
// ====================================================

export interface importConfig {
  importConfig: boolean | null;
}

export interface importConfigVariables {
  serverID: string;
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: saveOrbitPosition
// ====================================================

export interface saveOrbitPosition {
  saveOrbitPosition: any | null;
}

export interface saveOrbitPositionVariables {
  orbitID: string;
  x: number;
  y: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: saveOrbitCollectionPositions
// ====================================================

export interface saveOrbitCollectionPositions {
  saveOrbitCollectionPositions: any | null;
}

export interface saveOrbitCollectionPositionsVariables {
  orbitsCollection: (saveOrbitPositionInput | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTenantByApiID
// ====================================================

export interface GetTenantByApiID_getTenantByApiID_tenant_administrators {
  __typename: "User";
  roles: Role[] | null;
  email: string;
}

export interface GetTenantByApiID_getTenantByApiID_tenant_owners {
  __typename: "User";
  _id: string;
  email: string;
}

export interface GetTenantByApiID_getTenantByApiID_tenant {
  __typename: "NiamTenant";
  _id: string;
  administrators: GetTenantByApiID_getTenantByApiID_tenant_administrators[] | null;
  owners: (GetTenantByApiID_getTenantByApiID_tenant_owners | null)[] | null;
}

export interface GetTenantByApiID_getTenantByApiID {
  __typename: "NiamDataServer";
  _id: string;
  name: string;
  description: string | null;
  tenant: GetTenantByApiID_getTenantByApiID_tenant | null;
}

export interface GetTenantByApiID {
  getTenantByApiID: GetTenantByApiID_getTenantByApiID;
}

export interface GetTenantByApiIDVariables {
  serverID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPrimGInfo
// ====================================================

export interface getPrimGInfo_niamDataServer_tenant {
  __typename: "NiamTenant";
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_simplePath {
  __typename: "SimplePath";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_simplePathCreate {
  __typename: "SimplePathCreate";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_simplePathRead {
  __typename: "SimplePathRead";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_simplePathUpdate {
  __typename: "SimplePathUpdate";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_simplePathDelete {
  __typename: "SimplePathDelete";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_paths {
  __typename: "Paths";
  targetID: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_fields {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  isVC: boolean | null;
  isAction: boolean | null;
}

export interface getPrimGInfo_niamDataServer_niamObjects_interfaces {
  __typename: "NiamInterface";
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_objFrom {
  __typename: "NiamObject";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_objTo {
  __typename: "NiamObject";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_simplePath {
  __typename: "SimplePath";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_simplePathCreate {
  __typename: "SimplePathCreate";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_simplePathRead {
  __typename: "SimplePathRead";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_simplePathUpdate {
  __typename: "SimplePathUpdate";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_simplePathDelete {
  __typename: "SimplePathDelete";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_fields {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
}

export interface getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel {
  __typename: "NiamNodeRelationship";
  _id: string;
  name: string;
  displayName: string | null;
  x: number | null;
  y: number | null;
  description: string | null;
  isResource: boolean | null;
  isAction: boolean | null;
  isVC: boolean | null;
  simplePath: getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_simplePath | null;
  simplePathCreate: getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_simplePathCreate | null;
  simplePathRead: getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_simplePathRead | null;
  simplePathUpdate: getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_simplePathUpdate | null;
  simplePathDelete: getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_simplePathDelete | null;
  fields: getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel_fields[] | null;
}

export interface getPrimGInfo_niamDataServer_niamObjects_nodeRelationships {
  __typename: "ResponseNodeRelationship";
  objFrom: getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_objFrom | null;
  objTo: getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_objTo | null;
  rel: getPrimGInfo_niamDataServer_niamObjects_nodeRelationships_rel | null;
}

export interface getPrimGInfo_niamDataServer_niamObjects {
  __typename: "NiamObject";
  _id: string;
  x: number | null;
  y: number | null;
  displayName: string | null;
  name: string;
  description: string | null;
  isAction: boolean | null;
  isResource: boolean | null;
  isSubject: boolean | null;
  isVC: boolean | null;
  isHolder: boolean | null;
  isAndOperator: boolean | null;
  isOrOperator: boolean | null;
  isIssuer: boolean | null;
  isClaim: boolean | null;
  simplePath: getPrimGInfo_niamDataServer_niamObjects_simplePath[] | null;
  simplePathCreate: getPrimGInfo_niamDataServer_niamObjects_simplePathCreate[] | null;
  simplePathRead: getPrimGInfo_niamDataServer_niamObjects_simplePathRead[] | null;
  simplePathUpdate: getPrimGInfo_niamDataServer_niamObjects_simplePathUpdate[] | null;
  simplePathDelete: getPrimGInfo_niamDataServer_niamObjects_simplePathDelete[] | null;
  paths: getPrimGInfo_niamDataServer_niamObjects_paths[] | null;
  fields: getPrimGInfo_niamDataServer_niamObjects_fields[];
  interfaces: getPrimGInfo_niamDataServer_niamObjects_interfaces[] | null;
  nodeRelationships: getPrimGInfo_niamDataServer_niamObjects_nodeRelationships[] | null;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_objFrom {
  __typename: "NiamObject";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_objTo {
  __typename: "NiamObject";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_script {
  __typename: "NiamScript";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_relScript {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_relFrom {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_relTo {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_rel_simplePath {
  __typename: "SimplePath";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_rel_simplePathCreate {
  __typename: "SimplePathCreate";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_rel_simplePathRead {
  __typename: "SimplePathRead";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_rel_simplePathUpdate {
  __typename: "SimplePathUpdate";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_rel_simplePathDelete {
  __typename: "SimplePathDelete";
  relatedTo: string;
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_rel_fields {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships_rel {
  __typename: "NiamNodeRelationship";
  _id: string;
  x: number | null;
  y: number | null;
  name: string;
  displayName: string | null;
  isResource: boolean | null;
  isAction: boolean | null;
  isVC: boolean | null;
  simplePath: getPrimGInfo_niamDataServer_niamNodeRelationships_rel_simplePath | null;
  simplePathCreate: getPrimGInfo_niamDataServer_niamNodeRelationships_rel_simplePathCreate | null;
  simplePathRead: getPrimGInfo_niamDataServer_niamNodeRelationships_rel_simplePathRead | null;
  simplePathUpdate: getPrimGInfo_niamDataServer_niamNodeRelationships_rel_simplePathUpdate | null;
  simplePathDelete: getPrimGInfo_niamDataServer_niamNodeRelationships_rel_simplePathDelete | null;
  fields: getPrimGInfo_niamDataServer_niamNodeRelationships_rel_fields[] | null;
}

export interface getPrimGInfo_niamDataServer_niamNodeRelationships {
  __typename: "ResponseNodeRelationship";
  objFrom: getPrimGInfo_niamDataServer_niamNodeRelationships_objFrom | null;
  objTo: getPrimGInfo_niamDataServer_niamNodeRelationships_objTo | null;
  script: getPrimGInfo_niamDataServer_niamNodeRelationships_script | null;
  relScript: getPrimGInfo_niamDataServer_niamNodeRelationships_relScript | null;
  relFrom: getPrimGInfo_niamDataServer_niamNodeRelationships_relFrom | null;
  relTo: getPrimGInfo_niamDataServer_niamNodeRelationships_relTo | null;
  rel: getPrimGInfo_niamDataServer_niamNodeRelationships_rel | null;
}

export interface getPrimGInfo_niamDataServer_niamInterfaces_fields {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
}

export interface getPrimGInfo_niamDataServer_niamInterfaces {
  __typename: "NiamInterface";
  _id: string;
  x: number | null;
  y: number | null;
  name: string;
  displayName: string | null;
  fields: getPrimGInfo_niamDataServer_niamInterfaces_fields[];
}

export interface getPrimGInfo_niamDataServer_niamScripts_fields {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
}

export interface getPrimGInfo_niamDataServer_niamScripts_relationships {
  __typename: "NiamRelationship";
  _id: string;
  name: string;
  displayName: string | null;
  eventType: NiamRelationshipTypeEnum | null;
  relatedTo: string;
  fieldType: string | null;
}

export interface getPrimGInfo_niamDataServer_niamScripts_objects {
  __typename: "NiamObject";
  _id: string;
}

export interface getPrimGInfo_niamDataServer_niamScripts {
  __typename: "NiamScript";
  _id: string;
  x: number | null;
  y: number | null;
  name: string;
  displayName: string | null;
  fields: getPrimGInfo_niamDataServer_niamScripts_fields[] | null;
  relationships: getPrimGInfo_niamDataServer_niamScripts_relationships[] | null;
  objects: getPrimGInfo_niamDataServer_niamScripts_objects[] | null;
}

export interface getPrimGInfo_niamDataServer_niamCustomMutations {
  __typename: "NiamCustomMutation";
  _id: string;
  x: number | null;
  y: number | null;
  isResource: boolean | null;
  name: string;
  displayName: string | null;
  inputSchema: string | null;
  args: any | null;
  mutatuinName: string | null;
  returnType: string | null;
  script: string | null;
}

export interface getPrimGInfo_niamDataServer_niamTags {
  __typename: "NiamTag";
  _id: string;
  x: number | null;
  y: number | null;
  name: string;
  displayName: string | null;
}

export interface getPrimGInfo_niamDataServer_niamEnums {
  __typename: "NiamEnum";
  _id: string;
  x: number | null;
  y: number | null;
  name: string;
  displayName: string | null;
  enumerates: string[] | null;
}

export interface getPrimGInfo_niamDataServer {
  __typename: "NiamDataServer";
  _id: string;
  name: string;
  description: string | null;
  CLUSTER_SELECTED: string | null;
  tenant: getPrimGInfo_niamDataServer_tenant | null;
  niamObjects: getPrimGInfo_niamDataServer_niamObjects[] | null;
  niamNodeRelationships: getPrimGInfo_niamDataServer_niamNodeRelationships[] | null;
  niamInterfaces: getPrimGInfo_niamDataServer_niamInterfaces[] | null;
  niamScripts: getPrimGInfo_niamDataServer_niamScripts[] | null;
  niamCustomMutations: getPrimGInfo_niamDataServer_niamCustomMutations[] | null;
  niamTags: getPrimGInfo_niamDataServer_niamTags[] | null;
  niamEnums: getPrimGInfo_niamDataServer_niamEnums[] | null;
}

export interface getPrimGInfo {
  niamDataServer: getPrimGInfo_niamDataServer | null;
}

export interface getPrimGInfoVariables {
  serverID?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getModalInfo
// ====================================================

export interface getModalInfo_getModalInfo_serviceAccount {
  __typename: "NiamServiceAccount";
  _id: string | null;
  cloud: CloudServiceAccounts | null;
}

export interface getModalInfo_getModalInfo_cluster {
  __typename: "NiamCluster";
  _id: string;
  clusterID: string | null;
  name: string | null;
  location: string | null;
}

export interface getModalInfo_getModalInfo_authZServer {
  __typename: "NiamAuthZServer";
  _id: string;
  connectionURL: string | null;
  lastUpdate: any | null;
  defaultOperator: OperatorEnum | null;
  allowByDefault: boolean | null;
  selfRelDepth: number | null;
  isMostPermissive: boolean | null;
  actionPermissionOrder: string | null;
}

export interface getModalInfo_getModalInfo {
  __typename: "NiamDataServer";
  _id: string;
  name: string;
  description: string | null;
  connectionURL: string | null;
  clientNameGCP: string | null;
  CLUSTER_SELECTED: string | null;
  clusterAutopilot: boolean | null;
  clusterLocation: string | null;
  serviceAccount: getModalInfo_getModalInfo_serviceAccount | null;
  cluster: getModalInfo_getModalInfo_cluster | null;
  autoDeployApiServer: boolean | null;
  dbType: string | null;
  dbVersion: string | null;
  dbHost: string | null;
  dbName: string | null;
  dbUser: string | null;
  dbPass: string | null;
  lastUpdate: any | null;
  MAX_TROTTLE_COST: string | null;
  NEO4J_POOL_SIZE: string | null;
  NEO4J_MAX_CONNECTION_LIFETIME: string | null;
  NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS: string | null;
  NEO4J_CONNECTION_TIMEOUT: string | null;
  EXTERNAL_PROVIDER: boolean | null;
  OIDC_URL: string | null;
  CLAIMS_ARRAY: string | null;
  AUTHN_QUERY: string | null;
  EXTERNAL_OIDC_URL: string | null;
  OIDC_TOKEN_ENDPOINT: string | null;
  OIDC_TOKEN_INTROSPECTION_ENDPOINT: string | null;
  ACCESS_TOKEN_TYPE: string | null;
  OIDC_JWKS_URI: string | null;
  EXTERNAL_PROVIDER_NAME: string | null;
  OIDC_ME_ENDPOINT: string | null;
  OIDC_AUTHORIZE_ENDPOINT: string | null;
  OIDC_LOGOUT_ENDPOINT: string | null;
  OIDC_PROVIDER_CLAIMS_MAPPING: string | null;
  OIDC_CLIENT_ID: string | null;
  OIDC_CLIENT_PWD: string | null;
  authZServer: getModalInfo_getModalInfo_authZServer | null;
  authzCSPConnectionURL: string | null;
  dashboardServerURL: string | null;
  lastReleaseDate: string | null;
  apiKey: string | null;
  apiEnabled: boolean | null;
  superUserID: string | null;
}

export interface getModalInfo {
  getModalInfo: getModalInfo_getModalInfo | null;
}

export interface getModalInfoVariables {
  serverID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewNiamObjectToServer
// ====================================================

export interface addNewNiamObjectToServer_addNewNiamObjectToServer_interfaces {
  __typename: "NiamInterface";
  _id: string;
}

export interface addNewNiamObjectToServer_addNewNiamObjectToServer {
  __typename: "NiamObject";
  _id: string;
  displayName: string | null;
  name: string;
  interfaces: addNewNiamObjectToServer_addNewNiamObjectToServer_interfaces[] | null;
  x: number | null;
  y: number | null;
}

export interface addNewNiamObjectToServer {
  addNewNiamObjectToServer: addNewNiamObjectToServer_addNewNiamObjectToServer;
}

export interface addNewNiamObjectToServerVariables {
  serverID: string;
  name: string;
  displayName?: string | null;
  x?: number | null;
  y?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewNiamInterfaceToNiamDataServer
// ====================================================

export interface addNewNiamInterfaceToNiamDataServer_addNewNiamInterfaceToNiamDataServer {
  __typename: "NiamInterface";
  _id: string;
  name: string;
  displayName: string | null;
  x: number | null;
  y: number | null;
}

export interface addNewNiamInterfaceToNiamDataServer {
  addNewNiamInterfaceToNiamDataServer: addNewNiamInterfaceToNiamDataServer_addNewNiamInterfaceToNiamDataServer;
}

export interface addNewNiamInterfaceToNiamDataServerVariables {
  serverID: string;
  name: string;
  displayName?: string | null;
  x?: number | null;
  y?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewNiamScriptToServer
// ====================================================

export interface addNewNiamScriptToServer_addNewNiamScriptToServer {
  __typename: "NiamScript";
  _id: string;
  name: string;
  displayName: string | null;
  x: number | null;
  y: number | null;
}

export interface addNewNiamScriptToServer {
  addNewNiamScriptToServer: addNewNiamScriptToServer_addNewNiamScriptToServer;
}

export interface addNewNiamScriptToServerVariables {
  serverID: string;
  name: string;
  displayName?: string | null;
  x?: number | null;
  y?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewNiamCustomMutation
// ====================================================

export interface addNewNiamCustomMutation_addNewNiamCustomMutation {
  __typename: "NiamCustomMutation";
  _id: string;
  name: string;
  displayName: string | null;
  inputSchema: string | null;
  args: any | null;
  mutatuinName: string | null;
  returnType: string | null;
  script: string | null;
  x: number | null;
  y: number | null;
}

export interface addNewNiamCustomMutation {
  addNewNiamCustomMutation: addNewNiamCustomMutation_addNewNiamCustomMutation | null;
}

export interface addNewNiamCustomMutationVariables {
  serverID: string;
  name: string;
  displayName?: string | null;
  x?: number | null;
  y?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewNiamTagForNiamDataServer
// ====================================================

export interface addNewNiamTagForNiamDataServer_addNewNiamTagForNiamDataServer {
  __typename: "NiamTag";
  _id: string;
  name: string;
  displayName: string | null;
  x: number | null;
  y: number | null;
}

export interface addNewNiamTagForNiamDataServer {
  addNewNiamTagForNiamDataServer: addNewNiamTagForNiamDataServer_addNewNiamTagForNiamDataServer;
}

export interface addNewNiamTagForNiamDataServerVariables {
  serverID: string;
  name: string;
  displayName?: string | null;
  x?: number | null;
  y?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewNiamEnum
// ====================================================

export interface addNewNiamEnum_addNewNiamEnum {
  __typename: "NiamEnum";
  _id: string;
  name: string;
  displayName: string | null;
  enumerates: string[] | null;
  x: number | null;
  y: number | null;
}

export interface addNewNiamEnum {
  addNewNiamEnum: addNewNiamEnum_addNewNiamEnum;
}

export interface addNewNiamEnumVariables {
  serverID: string;
  name: string;
  displayName?: string | null;
  enumerates?: string[] | null;
  x?: number | null;
  y?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewDefaultPrimNodeRelationship
// ====================================================

export interface addNewDefaultPrimNodeRelationship_addNewDefaultPrimNodeRelationship_rel {
  __typename: "NiamNodeRelationship";
  _id: string;
  name: string;
  displayName: string | null;
  description: string | null;
  x: number | null;
  y: number | null;
}

export interface addNewDefaultPrimNodeRelationship_addNewDefaultPrimNodeRelationship {
  __typename: "ResponseNodeRelationship";
  rel: addNewDefaultPrimNodeRelationship_addNewDefaultPrimNodeRelationship_rel | null;
}

export interface addNewDefaultPrimNodeRelationship {
  addNewDefaultPrimNodeRelationship: addNewDefaultPrimNodeRelationship_addNewDefaultPrimNodeRelationship;
}

export interface addNewDefaultPrimNodeRelationshipVariables {
  serverID: string;
  name: string;
  displayName?: string | null;
  description?: string | null;
  x?: number | null;
  y?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: d3ImplementInterface
// ====================================================

export interface d3ImplementInterface_addNiamInterfaceToNiamObject {
  __typename: "NiamImplement";
  _id: string | null;
  objectID: string;
  interfaceID: string;
}

export interface d3ImplementInterface {
  addNiamInterfaceToNiamObject: d3ImplementInterface_addNiamInterfaceToNiamObject | null;
}

export interface d3ImplementInterfaceVariables {
  niamInterfaceID: string;
  niamObjectID: string;
  serverID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: d3AddPropertyToObject
// ====================================================

export interface d3AddPropertyToObject_addNewFieldToNiamObject {
  __typename: "NiamField";
  _id: string;
  name: string;
  displayName: string | null;
  relatedTo: string | null;
}

export interface d3AddPropertyToObject {
  addNewFieldToNiamObject: d3AddPropertyToObject_addNewFieldToNiamObject;
}

export interface d3AddPropertyToObjectVariables {
  NiamObjectID: string;
  newNiamFieldInput: NiamFieldInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewPathObjectToCustomMutation
// ====================================================

export interface addNewPathObjectToCustomMutation_addNewPathObjectToCustomMutation {
  __typename: "AddPathObjectToCustomMutationResponse";
  _id: string;
  targetID: string;
}

export interface addNewPathObjectToCustomMutation {
  addNewPathObjectToCustomMutation: addNewPathObjectToCustomMutation_addNewPathObjectToCustomMutation | null;
}

export interface addNewPathObjectToCustomMutationVariables {
  objectID: string;
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePathObjectToCustomMutation
// ====================================================

export interface deletePathObjectToCustomMutation {
  deletePathObjectToCustomMutation: boolean | null;
}

export interface deletePathObjectToCustomMutationVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewNodeRelationship
// ====================================================

export interface addNewNodeRelationship_addNewNodeRelationship_objFrom {
  __typename: "NiamObject";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface addNewNodeRelationship_addNewNodeRelationship_objTo {
  __typename: "NiamObject";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface addNewNodeRelationship_addNewNodeRelationship_rel_fields {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
}

export interface addNewNodeRelationship_addNewNodeRelationship_rel {
  __typename: "NiamNodeRelationship";
  _id: string;
  name: string;
  displayName: string | null;
  fields: addNewNodeRelationship_addNewNodeRelationship_rel_fields[] | null;
}

export interface addNewNodeRelationship_addNewNodeRelationship_relFrom {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface addNewNodeRelationship_addNewNodeRelationship_relTo {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface addNewNodeRelationship_addNewNodeRelationship {
  __typename: "ResponseNodeRelationship";
  objFrom: addNewNodeRelationship_addNewNodeRelationship_objFrom | null;
  objTo: addNewNodeRelationship_addNewNodeRelationship_objTo | null;
  rel: addNewNodeRelationship_addNewNodeRelationship_rel | null;
  relFrom: addNewNodeRelationship_addNewNodeRelationship_relFrom | null;
  relTo: addNewNodeRelationship_addNewNodeRelationship_relTo | null;
}

export interface addNewNodeRelationship {
  addNewNodeRelationship: addNewNodeRelationship_addNewNodeRelationship;
}

export interface addNewNodeRelationshipVariables {
  name: string;
  displayName?: string | null;
  serverID: string;
  objFrom: NodeRelationshipNiamObjectInput;
  objTo: NodeRelationshipNiamObjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: d3AddPropertyToScript
// ====================================================

export interface d3AddPropertyToScript_addNewRelationshipToNiamScript {
  __typename: "NiamRelationship";
  _id: string;
  name: string;
  displayName: string | null;
  eventType: NiamRelationshipTypeEnum | null;
  relatedTo: string;
  fieldType: string | null;
}

export interface d3AddPropertyToScript {
  addNewRelationshipToNiamScript: d3AddPropertyToScript_addNewRelationshipToNiamScript;
}

export interface d3AddPropertyToScriptVariables {
  scriptID: string;
  eventType?: NiamRelationshipTypeEnum | null;
  name: string;
  displayName?: string | null;
  relatedTo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: d3AddPropertyToInterface
// ====================================================

export interface d3AddPropertyToInterface_addNewNiamFieldToNiamInterface {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
}

export interface d3AddPropertyToInterface {
  addNewNiamFieldToNiamInterface: d3AddPropertyToInterface_addNewNiamFieldToNiamInterface;
}

export interface d3AddPropertyToInterfaceVariables {
  niamInterfaceID: string;
  newNiamFieldInput: NiamFieldInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSimplePathAuthZLabelToRelationship
// ====================================================

export interface addSimplePathAuthZLabelToRelationship_addSimplePathAuthZLabelToRelationship {
  __typename: "SimplePathResponse";
  _id: string;
}

export interface addSimplePathAuthZLabelToRelationship {
  addSimplePathAuthZLabelToRelationship: addSimplePathAuthZLabelToRelationship_addSimplePathAuthZLabelToRelationship;
}

export interface addSimplePathAuthZLabelToRelationshipVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSimplePathCreateAuthZLabelToRelationship
// ====================================================

export interface addSimplePathCreateAuthZLabelToRelationship_addSimplePathCreateAuthZLabelToRelationship {
  __typename: "SimplePathCreateResponse";
  _id: string;
}

export interface addSimplePathCreateAuthZLabelToRelationship {
  addSimplePathCreateAuthZLabelToRelationship: addSimplePathCreateAuthZLabelToRelationship_addSimplePathCreateAuthZLabelToRelationship;
}

export interface addSimplePathCreateAuthZLabelToRelationshipVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSimplePathReadAuthZLabelToRelationship
// ====================================================

export interface addSimplePathReadAuthZLabelToRelationship_addSimplePathReadAuthZLabelToRelationship {
  __typename: "SimplePathReadResponse";
  _id: string;
}

export interface addSimplePathReadAuthZLabelToRelationship {
  addSimplePathReadAuthZLabelToRelationship: addSimplePathReadAuthZLabelToRelationship_addSimplePathReadAuthZLabelToRelationship;
}

export interface addSimplePathReadAuthZLabelToRelationshipVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSimplePathUpdateAuthZLabelToRelationship
// ====================================================

export interface addSimplePathUpdateAuthZLabelToRelationship_addSimplePathUpdateAuthZLabelToRelationship {
  __typename: "SimplePathUpdateResponse";
  _id: string;
}

export interface addSimplePathUpdateAuthZLabelToRelationship {
  addSimplePathUpdateAuthZLabelToRelationship: addSimplePathUpdateAuthZLabelToRelationship_addSimplePathUpdateAuthZLabelToRelationship;
}

export interface addSimplePathUpdateAuthZLabelToRelationshipVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSimplePathDeleteAuthZLabelToRelationship
// ====================================================

export interface addSimplePathDeleteAuthZLabelToRelationship_addSimplePathDeleteAuthZLabelToRelationship {
  __typename: "SimplePathDeleteResponse";
  _id: string;
}

export interface addSimplePathDeleteAuthZLabelToRelationship {
  addSimplePathDeleteAuthZLabelToRelationship: addSimplePathDeleteAuthZLabelToRelationship_addSimplePathDeleteAuthZLabelToRelationship;
}

export interface addSimplePathDeleteAuthZLabelToRelationshipVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSimplePathAuthZLabelFromRelationship
// ====================================================

export interface removeSimplePathAuthZLabelFromRelationship {
  removeSimplePathAuthZLabelFromRelationship: boolean;
}

export interface removeSimplePathAuthZLabelFromRelationshipVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSimplePathCreateAuthZLabelFromRelationship
// ====================================================

export interface removeSimplePathCreateAuthZLabelFromRelationship {
  removeSimplePathCreateAuthZLabelFromRelationship: boolean;
}

export interface removeSimplePathCreateAuthZLabelFromRelationshipVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSimplePathReadAuthZLabelFromRelationship
// ====================================================

export interface removeSimplePathReadAuthZLabelFromRelationship {
  removeSimplePathReadAuthZLabelFromRelationship: boolean;
}

export interface removeSimplePathReadAuthZLabelFromRelationshipVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSimplePathUpdateAuthZLabelFromRelationship
// ====================================================

export interface removeSimplePathUpdateAuthZLabelFromRelationship {
  removeSimplePathUpdateAuthZLabelFromRelationship: boolean;
}

export interface removeSimplePathUpdateAuthZLabelFromRelationshipVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSimplePathDeleteAuthZLabelFromRelationship
// ====================================================

export interface removeSimplePathDeleteAuthZLabelFromRelationship {
  removeSimplePathDeleteAuthZLabelFromRelationship: boolean;
}

export interface removeSimplePathDeleteAuthZLabelFromRelationshipVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addObjectAuthZLabelToNode
// ====================================================

export interface addObjectAuthZLabelToNode_addObjectAuthZLabelToNode_NiamCustomMutation {
  __typename: "NiamCustomMutation" | "NiamNodeRelationship";
}

export interface addObjectAuthZLabelToNode_addObjectAuthZLabelToNode_NiamObject {
  __typename: "NiamObject";
  _id: string;
}

export type addObjectAuthZLabelToNode_addObjectAuthZLabelToNode = addObjectAuthZLabelToNode_addObjectAuthZLabelToNode_NiamCustomMutation | addObjectAuthZLabelToNode_addObjectAuthZLabelToNode_NiamObject;

export interface addObjectAuthZLabelToNode {
  addObjectAuthZLabelToNode: addObjectAuthZLabelToNode_addObjectAuthZLabelToNode | null;
}

export interface addObjectAuthZLabelToNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeObjectAuthZLabelFromNode
// ====================================================

export interface removeObjectAuthZLabelFromNode {
  removeObjectAuthZLabelFromNode: boolean | null;
}

export interface removeObjectAuthZLabelFromNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSubjectAuthZLabelToNode
// ====================================================

export interface addSubjectAuthZLabelToNode_addSubjectAuthZLabelToNode {
  __typename: "NiamObject";
  _id: string;
}

export interface addSubjectAuthZLabelToNode {
  addSubjectAuthZLabelToNode: addSubjectAuthZLabelToNode_addSubjectAuthZLabelToNode | null;
}

export interface addSubjectAuthZLabelToNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSubjectAuthZLabelFromNode
// ====================================================

export interface removeSubjectAuthZLabelFromNode {
  removeSubjectAuthZLabelFromNode: boolean | null;
}

export interface removeSubjectAuthZLabelFromNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addActionAuthZLabelToNode
// ====================================================

export interface addActionAuthZLabelToNode_addActionAuthZLabelToNode {
  __typename: "NiamObject";
  _id: string;
}

export interface addActionAuthZLabelToNode {
  addActionAuthZLabelToNode: addActionAuthZLabelToNode_addActionAuthZLabelToNode | null;
}

export interface addActionAuthZLabelToNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addVCAuthZLabelToNode
// ====================================================

export interface addVCAuthZLabelToNode_addVCAuthZLabelToNode {
  __typename: "NiamObject";
  _id: string;
}

export interface addVCAuthZLabelToNode {
  addVCAuthZLabelToNode: addVCAuthZLabelToNode_addVCAuthZLabelToNode | null;
}

export interface addVCAuthZLabelToNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addHolderAuthZLabelToNode
// ====================================================

export interface addHolderAuthZLabelToNode_addHolderAuthZLabelToNode {
  __typename: "NiamObject";
  _id: string;
}

export interface addHolderAuthZLabelToNode {
  addHolderAuthZLabelToNode: addHolderAuthZLabelToNode_addHolderAuthZLabelToNode | null;
}

export interface addHolderAuthZLabelToNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addAndOperatorAuthZLabelToNode
// ====================================================

export interface addAndOperatorAuthZLabelToNode_addAndOperatorAuthZLabelToNode {
  __typename: "NiamObject";
  _id: string;
}

export interface addAndOperatorAuthZLabelToNode {
  addAndOperatorAuthZLabelToNode: addAndOperatorAuthZLabelToNode_addAndOperatorAuthZLabelToNode | null;
}

export interface addAndOperatorAuthZLabelToNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addOrOperatorAuthZLabelToNode
// ====================================================

export interface addOrOperatorAuthZLabelToNode_addOrOperatorAuthZLabelToNode {
  __typename: "NiamObject";
  _id: string;
}

export interface addOrOperatorAuthZLabelToNode {
  addOrOperatorAuthZLabelToNode: addOrOperatorAuthZLabelToNode_addOrOperatorAuthZLabelToNode | null;
}

export interface addOrOperatorAuthZLabelToNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addIssuerAuthZLabelToNode
// ====================================================

export interface addIssuerAuthZLabelToNode_addIssuerAuthZLabelToNode {
  __typename: "NiamObject";
  _id: string;
}

export interface addIssuerAuthZLabelToNode {
  addIssuerAuthZLabelToNode: addIssuerAuthZLabelToNode_addIssuerAuthZLabelToNode | null;
}

export interface addIssuerAuthZLabelToNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addClaimAuthZLabelToNode
// ====================================================

export interface addClaimAuthZLabelToNode_addClaimAuthZLabelToNode {
  __typename: "NiamObject";
  _id: string;
}

export interface addClaimAuthZLabelToNode {
  addClaimAuthZLabelToNode: addClaimAuthZLabelToNode_addClaimAuthZLabelToNode | null;
}

export interface addClaimAuthZLabelToNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addVCAuthZLabelToRelationship
// ====================================================

export interface addVCAuthZLabelToRelationship_addVCAuthZLabelToRelationship {
  __typename: "VCRelAuthZResponse";
  _id: string;
}

export interface addVCAuthZLabelToRelationship {
  addVCAuthZLabelToRelationship: addVCAuthZLabelToRelationship_addVCAuthZLabelToRelationship;
}

export interface addVCAuthZLabelToRelationshipVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeActionAuthZLabelFromNode
// ====================================================

export interface removeActionAuthZLabelFromNode {
  removeActionAuthZLabelFromNode: boolean | null;
}

export interface removeActionAuthZLabelFromNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeVCAuthZLabelFromNode
// ====================================================

export interface removeVCAuthZLabelFromNode {
  removeVCAuthZLabelFromNode: boolean | null;
}

export interface removeVCAuthZLabelFromNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeHolderAuthZLabelFromNode
// ====================================================

export interface removeHolderAuthZLabelFromNode {
  removeHolderAuthZLabelFromNode: boolean | null;
}

export interface removeHolderAuthZLabelFromNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeAndOperatorAuthZLabelFromNode
// ====================================================

export interface removeAndOperatorAuthZLabelFromNode {
  removeAndOperatorAuthZLabelFromNode: boolean | null;
}

export interface removeAndOperatorAuthZLabelFromNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeOrOperatorAuthZLabelFromNode
// ====================================================

export interface removeOrOperatorAuthZLabelFromNode {
  removeOrOperatorAuthZLabelFromNode: boolean | null;
}

export interface removeOrOperatorAuthZLabelFromNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeIssuerAuthZLabelFromNode
// ====================================================

export interface removeIssuerAuthZLabelFromNode {
  removeIssuerAuthZLabelFromNode: boolean | null;
}

export interface removeIssuerAuthZLabelFromNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeClaimAuthZLabelFromNode
// ====================================================

export interface removeClaimAuthZLabelFromNode {
  removeClaimAuthZLabelFromNode: boolean | null;
}

export interface removeClaimAuthZLabelFromNodeVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addActionAuthZLabelToRelationship
// ====================================================

export interface addActionAuthZLabelToRelationship_addActionAuthZLabelToRelationship {
  __typename: "ActionRelAuthZResponse";
  _id: string;
}

export interface addActionAuthZLabelToRelationship {
  addActionAuthZLabelToRelationship: addActionAuthZLabelToRelationship_addActionAuthZLabelToRelationship;
}

export interface addActionAuthZLabelToRelationshipVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeActionAuthZLabelFromRelationship
// ====================================================

export interface removeActionAuthZLabelFromRelationship {
  removeActionAuthZLabelFromRelationship: boolean;
}

export interface removeActionAuthZLabelFromRelationshipVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeVCAuthZLabelFromRelationship
// ====================================================

export interface removeVCAuthZLabelFromRelationship {
  removeVCAuthZLabelFromRelationship: boolean;
}

export interface removeVCAuthZLabelFromRelationshipVariables {
  targetID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPObjectDelete
// ====================================================

export interface rPObjectDelete {
  deleteNiamObject: boolean | null;
}

export interface rPObjectDeleteVariables {
  ObjectID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPInterfaceDelete
// ====================================================

export interface rPInterfaceDelete {
  deleteNiamInterface: boolean | null;
}

export interface rPInterfaceDeleteVariables {
  interfaceID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPDeleteField
// ====================================================

export interface rPDeleteField {
  deleteNiamField: boolean | null;
}

export interface rPDeleteFieldVariables {
  fieldID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPDeleteRelationship
// ====================================================

export interface rPDeleteRelationship {
  deleteNiamRel: boolean;
}

export interface rPDeleteRelationshipVariables {
  relID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPDeleteScript
// ====================================================

export interface rPDeleteScript {
  deleteNiamScript: boolean;
}

export interface rPDeleteScriptVariables {
  scriptID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPDeleteCustomMutation
// ====================================================

export interface rPDeleteCustomMutation {
  deleteNiamCustomMutation: boolean | null;
}

export interface rPDeleteCustomMutationVariables {
  customMutationID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPDeleteTag
// ====================================================

export interface rPDeleteTag {
  deleteNiamTag: boolean | null;
}

export interface rPDeleteTagVariables {
  tagID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPDeleteEnum
// ====================================================

export interface rPDeleteEnum_deleteNiamEnum {
  __typename: "deleteEnumResponse";
  deletedFieldsIDs: string[] | null;
}

export interface rPDeleteEnum {
  deleteNiamEnum: rPDeleteEnum_deleteNiamEnum | null;
}

export interface rPDeleteEnumVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPDeleteImplement
// ====================================================

export interface rPDeleteImplement {
  deleteNiamImplement: boolean | null;
}

export interface rPDeleteImplementVariables {
  implementID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPDeleteNodeRelationship
// ====================================================

export interface rPDeleteNodeRelationship {
  deleteNiamNodeRelationship: boolean;
}

export interface rPDeleteNodeRelationshipVariables {
  nodeRelationshipID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPUpdateObject
// ====================================================

export interface rPUpdateObject_updateNiamObject_fields {
  __typename: "NiamField";
  _id: string;
  isArray: boolean;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isDisplay: boolean | null;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  name: string;
}

export interface rPUpdateObject_updateNiamObject {
  __typename: "NiamObject";
  _id: string;
  displayName: string | null;
  name: string;
  description: string | null;
  fields: rPUpdateObject_updateNiamObject_fields[];
  selfRegistration: boolean | null;
}

export interface rPUpdateObject {
  updateNiamObject: rPUpdateObject_updateNiamObject | null;
}

export interface rPUpdateObjectVariables {
  objectID: string;
  params: NiamObjectParamsInput;
  serverID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPUpdateRelationship
// ====================================================

export interface rPUpdateRelationship_updateNiamRel {
  __typename: "NiamRelationship";
  _id: string;
  name: string;
  displayName: string | null;
  eventType: NiamRelationshipTypeEnum | null;
}

export interface rPUpdateRelationship {
  updateNiamRel: rPUpdateRelationship_updateNiamRel;
}

export interface rPUpdateRelationshipVariables {
  relID: string;
  scriptID: string;
  relatedTo: string;
  params: NiamRelationshipParamsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPUpdateInterface
// ====================================================

export interface rPUpdateInterface_updateNiamInterface_fields {
  __typename: "NiamField";
  _id: string;
  isArray: boolean;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isDisplay: boolean | null;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  name: string;
}

export interface rPUpdateInterface_updateNiamInterface {
  __typename: "NiamInterface";
  _id: string;
  displayName: string | null;
  name: string;
  fields: rPUpdateInterface_updateNiamInterface_fields[];
}

export interface rPUpdateInterface {
  updateNiamInterface: rPUpdateInterface_updateNiamInterface;
}

export interface rPUpdateInterfaceVariables {
  interfaceID: string;
  serverID: string;
  params: niamInterfaceParamsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPUpdateCustomMutation
// ====================================================

export interface rPUpdateCustomMutation_updateNiamCustomMutation {
  __typename: "NiamCustomMutation";
  _id: string;
  displayName: string | null;
  name: string;
  inputSchema: string | null;
  args: any | null;
  mutatuinName: string | null;
  returnType: string | null;
  script: string | null;
}

export interface rPUpdateCustomMutation {
  updateNiamCustomMutation: rPUpdateCustomMutation_updateNiamCustomMutation | null;
}

export interface rPUpdateCustomMutationVariables {
  customMutationID: string;
  serverID: string;
  params: CustomMutationParamsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPUpdateTag
// ====================================================

export interface rPUpdateTag_updateNiamTag {
  __typename: "NiamTag";
  _id: string;
  displayName: string | null;
  name: string;
}

export interface rPUpdateTag {
  updateNiamTag: rPUpdateTag_updateNiamTag | null;
}

export interface rPUpdateTagVariables {
  tagID: string;
  serverID: string;
  params: TagParamsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPUpdateEnum
// ====================================================

export interface rPUpdateEnum_updateNiamEnum {
  __typename: "NiamEnum";
  _id: string;
  displayName: string | null;
  name: string;
  enumerates: string[] | null;
}

export interface rPUpdateEnum {
  updateNiamEnum: rPUpdateEnum_updateNiamEnum;
}

export interface rPUpdateEnumVariables {
  _id: string;
  name?: string | null;
  displayName?: string | null;
  icon?: string | null;
  niamID?: string | null;
  serverID: string;
  enumerates?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPUpdateScript
// ====================================================

export interface rPUpdateScript_updateNiamScript {
  __typename: "NiamScript";
  _id: string;
  name: string;
  displayName: string | null;
  funcName: string | null;
  returnType: string | null;
  scriptText: string | null;
}

export interface rPUpdateScript {
  updateNiamScript: rPUpdateScript_updateNiamScript;
}

export interface rPUpdateScriptVariables {
  scriptID: string;
  params: NiamScriptParamsInput;
  serverID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPUpdateNodeRelationship
// ====================================================

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_fields {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_objFrom {
  __typename: "NiamObject";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_objTo {
  __typename: "NiamObject";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_script {
  __typename: "NiamScript";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_relScript {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_relFrom {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_relTo {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_rel_simplePath {
  __typename: "SimplePath";
  relatedTo: string;
  _id: string;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_rel_fields {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_rel {
  __typename: "NiamNodeRelationship";
  _id: string;
  x: number | null;
  y: number | null;
  name: string;
  displayName: string | null;
  isResource: boolean | null;
  isAction: boolean | null;
  isVC: boolean | null;
  simplePath: rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_rel_simplePath | null;
  fields: rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_rel_fields[] | null;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship_relation {
  __typename: "ResponseNodeRelationship";
  objFrom: rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_objFrom | null;
  objTo: rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_objTo | null;
  script: rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_script | null;
  relScript: rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_relScript | null;
  relFrom: rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_relFrom | null;
  relTo: rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_relTo | null;
  rel: rPUpdateNodeRelationship_updateNiamNodeRelationship_relation_rel | null;
}

export interface rPUpdateNodeRelationship_updateNiamNodeRelationship {
  __typename: "NiamNodeRelationship";
  _id: string;
  displayName: string | null;
  name: string;
  description: string | null;
  fields: rPUpdateNodeRelationship_updateNiamNodeRelationship_fields[] | null;
  relation: rPUpdateNodeRelationship_updateNiamNodeRelationship_relation | null;
}

export interface rPUpdateNodeRelationship {
  updateNiamNodeRelationship: rPUpdateNodeRelationship_updateNiamNodeRelationship;
}

export interface rPUpdateNodeRelationshipVariables {
  nodeRelationshipID: string;
  serverID: string;
  objFrom?: string | null;
  objTo?: string | null;
  objFromName?: string | null;
  objToName?: string | null;
  params: AddNewDefaultPrimNodeRelationshipParamsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rPObjectGet
// ====================================================

export interface rPObjectGet_niamObject_fields {
  __typename: "NiamField";
  _id: string;
  isArray: boolean;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isDisplay: boolean | null;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  name: string;
}

export interface rPObjectGet_niamObject_interfaces_fields {
  __typename: "NiamField";
  _id: string;
  isArray: boolean;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isDisplay: boolean | null;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  name: string;
}

export interface rPObjectGet_niamObject_interfaces {
  __typename: "NiamInterface";
  _id: string;
  displayName: string | null;
  fields: rPObjectGet_niamObject_interfaces_fields[];
}

export interface rPObjectGet_niamObject {
  __typename: "NiamObject";
  _id: string;
  displayName: string | null;
  name: string;
  description: string | null;
  fields: rPObjectGet_niamObject_fields[];
  interfaces: rPObjectGet_niamObject_interfaces[] | null;
  selfRegistration: boolean | null;
}

export interface rPObjectGet {
  niamObject: rPObjectGet_niamObject | null;
}

export interface rPObjectGetVariables {
  objectID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rPPropertyGet
// ====================================================

export interface rPPropertyGet_niamField {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  isArray: boolean;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isUnique: boolean | null;
  isDisplay: boolean | null;
  regex: string | null;
  minLength: number | null;
  maxLength: number | null;
  relatedTo: string | null;
}

export interface rPPropertyGet {
  niamField: rPPropertyGet_niamField | null;
}

export interface rPPropertyGetVariables {
  FieldID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rPPropertyRelationshipGet
// ====================================================

export interface rPPropertyRelationshipGet_niamRelationship {
  __typename: "NiamRelationship";
  _id: string;
  displayName: string | null;
  name: string;
  eventType: NiamRelationshipTypeEnum | null;
}

export interface rPPropertyRelationshipGet {
  niamRelationship: rPPropertyRelationshipGet_niamRelationship | null;
}

export interface rPPropertyRelationshipGetVariables {
  relID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rPImplementGet
// ====================================================

export interface rPImplementGet_niamImplement {
  __typename: "NiamImplement";
  _id: string | null;
  objectID: string;
  interfaceID: string;
}

export interface rPImplementGet {
  niamImplement: rPImplementGet_niamImplement | null;
}

export interface rPImplementGetVariables {
  implementID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rPInterfaceGet
// ====================================================

export interface rPInterfaceGet_niamInterface_fields {
  __typename: "NiamField";
  _id: string;
  isArray: boolean;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isDisplay: boolean | null;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  name: string;
}

export interface rPInterfaceGet_niamInterface {
  __typename: "NiamInterface";
  _id: string;
  displayName: string | null;
  name: string;
  fields: rPInterfaceGet_niamInterface_fields[];
}

export interface rPInterfaceGet {
  niamInterface: rPInterfaceGet_niamInterface | null;
}

export interface rPInterfaceGetVariables {
  interfaceID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rPScriptGet
// ====================================================

export interface rPScriptGet_niamScript {
  __typename: "NiamScript";
  _id: string;
  displayName: string | null;
  name: string;
  funcName: string | null;
  returnType: string | null;
  scriptText: string | null;
}

export interface rPScriptGet {
  niamScript: rPScriptGet_niamScript | null;
}

export interface rPScriptGetVariables {
  scriptID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rPCustomMutationGet
// ====================================================

export interface rPCustomMutationGet_niamCustomMutation {
  __typename: "NiamCustomMutation";
  _id: string;
  displayName: string | null;
  name: string;
  inputSchema: string | null;
  args: any | null;
  mutatuinName: string | null;
  returnType: string | null;
  script: string | null;
}

export interface rPCustomMutationGet {
  niamCustomMutation: rPCustomMutationGet_niamCustomMutation | null;
}

export interface rPCustomMutationGetVariables {
  customMutationID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rPTagGet
// ====================================================

export interface rPTagGet_niamTag {
  __typename: "NiamTag";
  _id: string;
  displayName: string | null;
  name: string;
}

export interface rPTagGet {
  niamTag: rPTagGet_niamTag | null;
}

export interface rPTagGetVariables {
  tagID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rPEnumGet
// ====================================================

export interface rPEnumGet_niamEnum {
  __typename: "NiamEnum";
  _id: string;
  displayName: string | null;
  name: string;
  enumerates: string[] | null;
}

export interface rPEnumGet {
  niamEnum: rPEnumGet_niamEnum | null;
}

export interface rPEnumGetVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rPNodeRelationshipGet
// ====================================================

export interface rPNodeRelationshipGet_niamNodeRelationship_fields {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  isRequired: boolean | null;
  isDisplay: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
}

export interface rPNodeRelationshipGet_niamNodeRelationship_relation_objFrom {
  __typename: "NiamObject";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPNodeRelationshipGet_niamNodeRelationship_relation_objTo {
  __typename: "NiamObject";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPNodeRelationshipGet_niamNodeRelationship_relation_script {
  __typename: "NiamScript";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPNodeRelationshipGet_niamNodeRelationship_relation_relScript {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPNodeRelationshipGet_niamNodeRelationship_relation_relFrom {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPNodeRelationshipGet_niamNodeRelationship_relation_relTo {
  __typename: "NiamNodeRelationshipRel";
  _id: string;
  name: string;
  displayName: string | null;
}

export interface rPNodeRelationshipGet_niamNodeRelationship_relation_rel_simplePath {
  __typename: "SimplePath";
  relatedTo: string;
  _id: string;
}

export interface rPNodeRelationshipGet_niamNodeRelationship_relation_rel_fields {
  __typename: "NiamField";
  _id: string;
  displayName: string | null;
  name: string;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
}

export interface rPNodeRelationshipGet_niamNodeRelationship_relation_rel {
  __typename: "NiamNodeRelationship";
  _id: string;
  x: number | null;
  y: number | null;
  name: string;
  displayName: string | null;
  isAction: boolean | null;
  isVC: boolean | null;
  isResource: boolean | null;
  simplePath: rPNodeRelationshipGet_niamNodeRelationship_relation_rel_simplePath | null;
  fields: rPNodeRelationshipGet_niamNodeRelationship_relation_rel_fields[] | null;
}

export interface rPNodeRelationshipGet_niamNodeRelationship_relation {
  __typename: "ResponseNodeRelationship";
  objFrom: rPNodeRelationshipGet_niamNodeRelationship_relation_objFrom | null;
  objTo: rPNodeRelationshipGet_niamNodeRelationship_relation_objTo | null;
  script: rPNodeRelationshipGet_niamNodeRelationship_relation_script | null;
  relScript: rPNodeRelationshipGet_niamNodeRelationship_relation_relScript | null;
  relFrom: rPNodeRelationshipGet_niamNodeRelationship_relation_relFrom | null;
  relTo: rPNodeRelationshipGet_niamNodeRelationship_relation_relTo | null;
  rel: rPNodeRelationshipGet_niamNodeRelationship_relation_rel | null;
}

export interface rPNodeRelationshipGet_niamNodeRelationship {
  __typename: "NiamNodeRelationship";
  _id: string;
  displayName: string | null;
  name: string;
  description: string | null;
  fields: rPNodeRelationshipGet_niamNodeRelationship_fields[] | null;
  relation: rPNodeRelationshipGet_niamNodeRelationship_relation | null;
}

export interface rPNodeRelationshipGet {
  niamNodeRelationship: rPNodeRelationshipGet_niamNodeRelationship | null;
}

export interface rPNodeRelationshipGetVariables {
  nodeRelationshipID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPAddFieldToObject
// ====================================================

export interface rPAddFieldToObject_addNewFieldToNiamObject {
  __typename: "NiamField";
  _id: string;
  isArray: boolean;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isDisplay: boolean | null;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  name: string;
}

export interface rPAddFieldToObject {
  addNewFieldToNiamObject: rPAddFieldToObject_addNewFieldToNiamObject;
}

export interface rPAddFieldToObjectVariables {
  NiamObjectID: string;
  newNiamFieldInput: NiamFieldInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPAddFieldToNodeRelationship
// ====================================================

export interface rPAddFieldToNodeRelationship_addNewFieldToNiamNodeRelationship {
  __typename: "NiamField";
  _id: string;
  isArray: boolean;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isDisplay: boolean | null;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  name: string;
}

export interface rPAddFieldToNodeRelationship {
  addNewFieldToNiamNodeRelationship: rPAddFieldToNodeRelationship_addNewFieldToNiamNodeRelationship;
}

export interface rPAddFieldToNodeRelationshipVariables {
  nodeRelationshipID: string;
  newNiamFieldInput: NiamFieldInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPUpdateNiamField
// ====================================================

export interface rPUpdateNiamField_updateNiamField {
  __typename: "NiamField";
  _id: string;
  name: string;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isArray: boolean;
  isUnique: boolean | null;
  isDisplay: boolean | null;
  maxLength: number | null;
  minLength: number | null;
  regex: string | null;
  relatedTo: string | null;
}

export interface rPUpdateNiamField {
  updateNiamField: rPUpdateNiamField_updateNiamField;
}

export interface rPUpdateNiamFieldVariables {
  fieldID: string;
  parentID: string;
  params: NiamFieldInput;
  serverID: string;
  parentType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rPAddFieldToInterface
// ====================================================

export interface rPAddFieldToInterface_addNewNiamFieldToNiamInterface {
  __typename: "NiamField";
  _id: string;
  isArray: boolean;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isDisplay: boolean | null;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  name: string;
}

export interface rPAddFieldToInterface {
  addNewNiamFieldToNiamInterface: rPAddFieldToInterface_addNewNiamFieldToNiamInterface;
}

export interface rPAddFieldToInterfaceVariables {
  niamInterfaceID: string;
  newNiamFieldInput: NiamFieldInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: setAccountAsVerified
// ====================================================

export interface setAccountAsVerified_setAccountAsVerified {
  __typename: "UserExistsResponse";
  isVerified: boolean | null;
  _id: string | null;
}

export interface setAccountAsVerified {
  setAccountAsVerified: setAccountAsVerified_setAccountAsVerified | null;
}

export interface setAccountAsVerifiedVariables {
  email: string;
  randomCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: setAccountAsVerifiedByAdmin
// ====================================================

export interface setAccountAsVerifiedByAdmin_setAccountAsVerifiedByAdmin {
  __typename: "UserExistsResponse";
  isVerified: boolean | null;
  _id: string | null;
}

export interface setAccountAsVerifiedByAdmin {
  setAccountAsVerifiedByAdmin: setAccountAsVerifiedByAdmin_setAccountAsVerifiedByAdmin | null;
}

export interface setAccountAsVerifiedByAdminVariables {
  email: string;
  verificationCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addExistingUserShared
// ====================================================

export interface addExistingUserShared {
  addExistingUserShared: boolean;
}

export interface addExistingUserSharedVariables {
  email: string;
  serverID: string;
  tenantID?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addNewUserShared
// ====================================================

export interface addNewUserShared_addNewUserShared {
  __typename: "AdminUserResponse";
  _id: string;
}

export interface addNewUserShared {
  addNewUserShared: addNewUserShared_addNewUserShared | null;
}

export interface addNewUserSharedVariables {
  email: string;
  serverID: string;
  emailOptions: emailOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeUserShared
// ====================================================

export interface removeUserShared {
  removeUserShared: boolean;
}

export interface removeUserSharedVariables {
  serverID: string;
  userID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUsersByDataServerShared
// ====================================================

export interface getUsersByDataServerShared_getUsersByDataServerShared_users {
  __typename: "AdminUserResponse";
  _id: string;
  email: string;
  name: string | null;
  createDateTime: any | null;
  isVerified: boolean;
  role: string | null;
  address: string | null;
  company: string | null;
  country: string | null;
  mobilePhone: string | null;
  creationDate: any | null;
}

export interface getUsersByDataServerShared_getUsersByDataServerShared {
  __typename: "UsersByDataServerSharedResponse";
  users: getUsersByDataServerShared_getUsersByDataServerShared_users[];
  totalItems: number;
}

export interface getUsersByDataServerShared {
  getUsersByDataServerShared: getUsersByDataServerShared_getUsersByDataServerShared;
}

export interface getUsersByDataServerSharedVariables {
  serverID: string;
  first?: number | null;
  skip?: number | null;
  searchWord?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminExistingUser
// ====================================================

export interface adminExistingUser {
  adminExistingUser: boolean;
}

export interface adminExistingUserVariables {
  email: string;
  tenantID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminAddNewUser
// ====================================================

export interface adminAddNewUser_adminAddNewUser {
  __typename: "AdminUserResponse";
  _id: string;
  email: string;
  name: string | null;
  role: string | null;
  company: string | null;
  address: string | null;
  country: string | null;
  mobilePhone: string | null;
}

export interface adminAddNewUser {
  adminAddNewUser: adminAddNewUser_adminAddNewUser | null;
}

export interface adminAddNewUserVariables {
  email: string;
  tenantID?: string | null;
  emailOptions: emailOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminChangeUserRole
// ====================================================

export interface adminChangeUserRole {
  adminChangeUserRole: boolean;
}

export interface adminChangeUserRoleVariables {
  tenantID?: string | null;
  userID: string;
  role: TenantRole;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminRemoveUser
// ====================================================

export interface adminRemoveUser {
  adminRemoveUser: boolean;
}

export interface adminRemoveUserVariables {
  tenantID: string;
  userID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminAddUser
// ====================================================

export interface adminAddUser {
  adminAddUser: boolean;
}

export interface adminAddUserVariables {
  tenantID: string;
  userID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetUsersByTenant
// ====================================================

export interface adminGetUsersByTenant_adminGetUsersByTenant_users {
  __typename: "AdminUserResponse";
  _id: string;
  email: string;
  name: string | null;
  createDateTime: any | null;
  role: string | null;
  address: string | null;
  company: string | null;
  country: string | null;
  mobilePhone: string | null;
  isVerified: boolean;
  isExpiredVerifyCode: boolean | null;
  verificationCode: string | null;
  timestamp: any | null;
}

export interface adminGetUsersByTenant_adminGetUsersByTenant {
  __typename: "AdminTenantUsersResponse";
  users: adminGetUsersByTenant_adminGetUsersByTenant_users[];
  totalItems: number;
}

export interface adminGetUsersByTenant {
  adminGetUsersByTenant: adminGetUsersByTenant_adminGetUsersByTenant;
}

export interface adminGetUsersByTenantVariables {
  tenantID: string;
  first?: number | null;
  skip?: number | null;
  searchWord?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetUsers
// ====================================================

export interface adminGetUsers_adminGetUsers_users_tenants {
  __typename: "AdminUserTenantResponse";
  role: string;
  tenantID: string;
  createDateTime: any | null;
  name: string;
  niamID: string | null;
}

export interface adminGetUsers_adminGetUsers_users {
  __typename: "AdminUserWithTenantsResponse";
  _id: string;
  email: string;
  name: string | null;
  createDateTime: any | null;
  roles: Role[] | null;
  address: string | null;
  company: string | null;
  country: string | null;
  mobilePhone: string | null;
  timestamp: any | null;
  tenants: adminGetUsers_adminGetUsers_users_tenants[];
  isVerified: boolean;
  isExpiredVerifyCode: boolean | null;
  verificationCode: string | null;
}

export interface adminGetUsers_adminGetUsers {
  __typename: "AdminAllUsersResponse";
  users: adminGetUsers_adminGetUsers_users[];
  totalItems: number;
}

export interface adminGetUsers {
  adminGetUsers: adminGetUsers_adminGetUsers;
}

export interface adminGetUsersVariables {
  first: number;
  skip: number;
  searchWord?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminSetPwd
// ====================================================

export interface adminSetPwd {
  adminSetPwd: boolean;
}

export interface adminSetPwdVariables {
  pwd: string;
  userID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addUser
// ====================================================

export interface addUser_addNewUser {
  __typename: "UserResponse";
  _id: string;
  email: string;
  randomCode: string;
}

export interface addUser {
  addNewUser: addUser_addNewUser | null;
}

export interface addUserVariables {
  email: string;
  name?: string | null;
  password: string;
  ip: string;
  emailOptions?: emailOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkIfUserAlreadyExists
// ====================================================

export interface checkIfUserAlreadyExists_checkIfUserAlreadyExists_tenants {
  __typename: "NiamTenant";
  tenantID: string;
}

export interface checkIfUserAlreadyExists_checkIfUserAlreadyExists {
  __typename: "UserExistsResponse";
  _id: string | null;
  isVerified: boolean | null;
  isExpiredVerifyCode: boolean | null;
  tenants: (checkIfUserAlreadyExists_checkIfUserAlreadyExists_tenants | null)[] | null;
}

export interface checkIfUserAlreadyExists {
  checkIfUserAlreadyExists: checkIfUserAlreadyExists_checkIfUserAlreadyExists;
}

export interface checkIfUserAlreadyExistsVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNewVerificationCode
// ====================================================

export interface getNewVerificationCode_getNewVerificationCode {
  __typename: "UserResponse";
  randomCode: string;
}

export interface getNewVerificationCode {
  getNewVerificationCode: getNewVerificationCode_getNewVerificationCode;
}

export interface getNewVerificationCodeVariables {
  email: string;
  emailOptions?: emailOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetYourPWD
// ====================================================

export interface resetYourPWD {
  resetYourPWD: boolean | null;
}

export interface resetYourPWDVariables {
  email: string;
  emailOptions: emailOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetPWD
// ====================================================

export interface resetPWD {
  resetPWD: boolean | null;
}

export interface resetPWDVariables {
  email: string;
  randomCode: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getServiceAccounts
// ====================================================

export interface getServiceAccounts_getServiceAccounts {
  __typename: "NiamServiceAccount";
  _id: string | null;
  cloud: CloudServiceAccounts | null;
  owner: string | null;
  serviceAccountGCP: string | null;
  serviceAccountAWSClientID: string | null;
  serviceAccountAWSClientSecret: string | null;
}

export interface getServiceAccounts {
  getServiceAccounts: (getServiceAccounts_getServiceAccounts | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getServiceAccountsByUser
// ====================================================

export interface getServiceAccountsByUser_getServiceAccountsByUser {
  __typename: "NiamServiceAccount";
  _id: string | null;
  cloud: CloudServiceAccounts | null;
  owner: string | null;
}

export interface getServiceAccountsByUser {
  getServiceAccountsByUser: (getServiceAccountsByUser_getServiceAccountsByUser | null)[];
}

export interface getServiceAccountsByUserVariables {
  serverID?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: collectClustersByServiceAccount
// ====================================================

export interface collectClustersByServiceAccount_collectClustersByServiceAccount {
  __typename: "NiamClusterCollectedResponse";
  id: string | null;
  name: string | null;
  location: string | null;
}

export interface collectClustersByServiceAccount {
  collectClustersByServiceAccount: (collectClustersByServiceAccount_collectClustersByServiceAccount | null)[];
}

export interface collectClustersByServiceAccountVariables {
  serviceAccountID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClustersByServiceAccount
// ====================================================

export interface getClustersByServiceAccount_getClustersByServiceAccount {
  __typename: "NiamCluster";
  _id: string;
  clusterID: string | null;
  name: string | null;
  location: string | null;
}

export interface getClustersByServiceAccount {
  getClustersByServiceAccount: (getClustersByServiceAccount_getClustersByServiceAccount | null)[];
}

export interface getClustersByServiceAccountVariables {
  serviceAccountID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteServiceAccount
// ====================================================

export interface deleteServiceAccount {
  deleteServiceAccount: boolean | null;
}

export interface deleteServiceAccountVariables {
  serviceAccountID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addServiceAccount
// ====================================================

export interface addServiceAccount_addServiceAccount {
  __typename: "NiamServiceAccount";
  _id: string | null;
  cloud: CloudServiceAccounts | null;
  owner: string | null;
  serviceAccountGCP: string | null;
  serviceAccountAWSClientID: string | null;
  serviceAccountAWSClientSecret: string | null;
}

export interface addServiceAccount {
  addServiceAccount: addServiceAccount_addServiceAccount | null;
}

export interface addServiceAccountVariables {
  cloud: CloudServiceAccounts;
  serviceAccountGCP?: string | null;
  serviceAccountAWSClientID?: string | null;
  serviceAccountAWSClientSecret?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: editServiceAccount
// ====================================================

export interface editServiceAccount_editServiceAccount {
  __typename: "NiamServiceAccount";
  _id: string | null;
  cloud: CloudServiceAccounts | null;
  owner: string | null;
  serviceAccountGCP: string | null;
  serviceAccountAWSClientID: string | null;
  serviceAccountAWSClientSecret: string | null;
}

export interface editServiceAccount {
  editServiceAccount: editServiceAccount_editServiceAccount | null;
}

export interface editServiceAccountVariables {
  serviceAccountID?: string | null;
  cloud: CloudServiceAccounts;
  serviceAccountGCP?: string | null;
  serviceAccountAWSClientID?: string | null;
  serviceAccountAWSClientSecret?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllStatusSA
// ====================================================

export interface getAllStatusSA_getAllStatusSA {
  __typename: "getAllStatusSAResponse";
  saStatus: saEnum | null;
}

export interface getAllStatusSA {
  getAllStatusSA: getAllStatusSA_getAllStatusSA;
}

export interface getAllStatusSAVariables {
  serviceAccountID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FieldsFields
// ====================================================

export interface FieldsFields {
  __typename: "NiamField";
  _id: string;
  isArray: boolean;
  isRequired: boolean | null;
  isNaming: boolean | null;
  isSecret: boolean | null;
  isDisplay: boolean | null;
  displayName: string | null;
  fieldType: NiamFieldTypeEnum;
  relatedTo: string | null;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CloudServiceAccounts {
  AWS = "AWS",
  AZURE = "AZURE",
  GCP = "GCP",
}

export enum ClusterEnum {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  PROCESSING = "PROCESSING",
}

export enum Direction {
  IN = "IN",
  OUT = "OUT",
}

export enum NiamFieldTypeEnum {
  Boolean = "Boolean",
  Enum = "Enum",
  Float = "Float",
  ID = "ID",
  Int = "Int",
  NiamEnum = "NiamEnum",
  NodeRelationship = "NodeRelationship",
  Object = "Object",
  Relationship = "Relationship",
  String = "String",
}

export enum NiamRelationshipTypeEnum {
  onCreate = "onCreate",
  onDelete = "onDelete",
  onRead = "onRead",
  onUpdate = "onUpdate",
  postCreate = "postCreate",
  postDelete = "postDelete",
  postRead = "postRead",
  postUpdate = "postUpdate",
}

export enum OperatorEnum {
  AND = "AND",
  OR = "OR",
}

export enum Role {
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
  USER = "USER",
}

export enum TenantRole {
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum podEnum {
  PROCESSING = "PROCESSING",
  RUNNING = "RUNNING",
  STOPPED = "STOPPED",
}

export enum propertyTypes {
  Boolean = "Boolean",
  Float = "Float",
  Int = "Int",
  Object = "Object",
  String = "String",
}

export enum saEnum {
  INVALID = "INVALID",
  PROCESSING = "PROCESSING",
  VALID = "VALID",
}

export interface AddNewDefaultPrimNodeRelationshipParamsInput {
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
}

export interface AuthzParams {
  _id?: string | null;
  connectionURL?: string | null;
  defaultOperator?: OperatorEnum | null;
  lastUpdate?: any | null;
  allowByDefault?: boolean | null;
  selfRelDepth?: number | null;
  isMostPermissive?: boolean | null;
  actionPermissionOrder?: string | null;
}

export interface CustomMutationParamsInput {
  name: string;
  displayName?: string | null;
  mutatuinName?: string | null;
  args?: any | null;
  returnType?: string | null;
  inputSchema?: string | null;
  script?: string | null;
  x?: number | null;
  y?: number | null;
}

export interface DBParams {
  dbType: string;
  dbVersion: string;
  dbHost: string;
  dbName: string;
  dbUser: string;
  dbPass: string;
  NEO4J_POOL_SIZE?: string | null;
  NEO4J_MAX_CONNECTION_LIFETIME?: string | null;
  NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS?: string | null;
  NEO4J_CONNECTION_TIMEOUT?: string | null;
}

export interface DashboardParams {
  connectionURL?: string | null;
}

export interface NiamFieldInput {
  name?: string | null;
  displayName?: string | null;
  fieldType?: NiamFieldTypeEnum | null;
  relatedTo?: string | null;
  relLabelName?: string | null;
  direction?: Direction | null;
  accessibleOnOppositeSide?: boolean | null;
  oppositeSideName?: string | null;
  isArray?: boolean | null;
  icon?: string | null;
  isRequired: boolean;
  isNaming: boolean;
  isSecret?: boolean | null;
  maxLength?: number | null;
  minLength?: number | null;
  regex?: string | null;
  isUnique?: boolean | null;
  isDisplay?: boolean | null;
  customValidationFunc?: string | null;
  properties?: (relationshipPropertyInput | null)[] | null;
}

export interface NiamObjectParamsInput {
  name?: string | null;
  displayName?: string | null;
  icon?: string | null;
  onCreate?: string | null;
  onRead?: string | null;
  onUpdate?: string | null;
  onDelete?: string | null;
  postCreate?: string | null;
  postRead?: string | null;
  postUpdate?: string | null;
  postDelete?: string | null;
  selfRegistration?: boolean | null;
  description?: string | null;
}

export interface NiamRelationshipParamsInput {
  name: string;
  displayName?: string | null;
  eventType?: NiamRelationshipTypeEnum | null;
}

export interface NiamScriptParamsInput {
  name: string;
  displayName?: string | null;
  funcName?: string | null;
  returnType?: string | null;
  scriptText?: string | null;
}

export interface NodeColorsInput {
  orbitColor: string;
  iconColor: string;
}

export interface NodeRelationshipNiamObjectInput {
  _id: string;
  name: string;
}

export interface OIDCParams {
  EXTERNAL_PROVIDER?: boolean | null;
  OIDC_URL?: string | null;
  OIDC_CLIENT_ID?: string | null;
  OIDC_CLIENT_PWD?: string | null;
  OIDC_TOKEN_ENDPOINT?: string | null;
  OIDC_TOKEN_INTROSPECTION_ENDPOINT?: string | null;
  OIDC_ME_ENDPOINT?: string | null;
  OIDC_AUTHORIZE_ENDPOINT?: string | null;
  OIDC_LOGOUT_ENDPOINT?: string | null;
  ACCESS_TOKEN_TYPE?: string | null;
  OIDC_JWKS_URI?: string | null;
  CLAIMS_JSON_PATH?: string | null;
  OIDC_PROVIDER_CLAIMS_MAPPING?: string | null;
  EXTERNAL_PROVIDER_NAME?: string | null;
  CLAIMS_ARRAY?: string | null;
  AUTHN_QUERY?: string | null;
  EXTERNAL_OIDC_URL?: string | null;
}

export interface TagParamsInput {
  name: string;
  displayName?: string | null;
  x?: number | null;
  y?: number | null;
}

export interface deleteAccountParamsInput {
  email: string;
  password: string;
}

export interface emailOptionsInput {
  subject: string;
  html: string;
  text: string;
}

export interface niamInterfaceParamsInput {
  name?: string | null;
  displayName?: string | null;
  niamID?: string | null;
  nodeColors?: NodeColorsInput | null;
}

export interface relationshipPropertyInput {
  type: propertyTypes;
  name: string;
  nestedObject?: relationshipPropertyInput[] | null;
}

export interface saveOrbitPositionInput {
  orbitID: string;
  x: number;
  y: number;
}

export interface updateEmailParamsInput {
  tmpEmail: string;
}

export interface updatePWDParamsInput {
  newPassword: string;
}

export interface updateProfileParamsInput {
  name?: string | null;
  address?: string | null;
  country?: string | null;
  company?: string | null;
  mobilePhone?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
