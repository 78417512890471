export class JwksError extends Error {
    readonly name = "JwksError";
}

export class SigningKeyNotFoundError extends Error {
    readonly name = "SigningKeyNotFoundError";
}

export class JwtError extends Error {
    readonly name = "JwtError";
}

export class AccessDeniedError extends Error {
    readonly name = "AccessDeniedError";
}

export class InternalError extends Error {
    readonly name = "InternalError";
}

export class ValidationError extends Error {
    readonly name = "ValidationError";
}
