import { toastError, toastSuccess, toastWarning } from "@3edges/utils/dist/toastify";
import { isEmpty, isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { COLORS } from "assets/defaultVariables";
import { selectedUser } from "cache";
import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import { Title } from "components/PanelProfile/styled";
import { GraphQLError } from "graphql";
import ModalAddToTenant from "pages/AdminPage/components/UsersTable/components/ModalAddToTenant/ModalAddToTenant";
import { PRIM_ADMIN_ADD_EXISTING_USER, PRIM_ADMIN_CREATE_NEW_USER } from "pages/AdminPage/gql";
import { PRIM_CHECK_IF_USER_ALREADY_EXISTS } from "pages/CreateNewUser/gql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import {
    GetAllTenants_niamTenants as NiamTenant
} from "types/operation-result-types";
import { Modal } from "ui-components/modal";
import { TextField } from "ui-components/styleds";
import { validateEmail } from "utils";

interface ICreatingUserFrom {
    isModalOpen: boolean;
    setIsModalOpen: (state: boolean) => void;
    tenantID: string;
    onClose: () => void;
    tenants?: NiamTenant[];
}

export default function CreatingUserForm ({
    isModalOpen,
    setIsModalOpen,
    tenantID,
    tenants,
    onClose
}: ICreatingUserFrom): React.ReactElement {
    const { t } = useTranslation();
    const [adminExitingUser] = useMutation(PRIM_ADMIN_ADD_EXISTING_USER);
    const [adminAddNewUser] = useMutation(PRIM_ADMIN_CREATE_NEW_USER);
    const [email, setEmail] = useState("");
    const [userCreated, setUserCreated] = useState<string>("");

    // eslint-disable-next-line
    const [isModalTenatsOpen, changeModalTenantsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [checkIfUserAlreadyExists] = useLazyQuery(PRIM_CHECK_IF_USER_ALREADY_EXISTS);

    useEffect(() => {
        if (!isModalOpen) {
            setIsLoading(false);
            setEmail("");
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (!isModalTenatsOpen) {
            setIsModalOpen(false);
        }
    }, [isModalTenatsOpen]);

    const onConfirm = (): void => {
        if (validateEmail(email).length === 0) {

            if (selectedUser().email === email) {
                toastWarning(t("validations:add.yourself"));
                return;
            }

            setIsLoading(true);

            void checkIfUserAlreadyExists({
                variables: {
                    email
                }
            }).then(({ data: dataExists }) => {

                // eslint-disable-next-line no-negated-condition
                if (dataExists.checkIfUserAlreadyExists.isVerified !== null) {

                    if (!tenantID && tenants.length > 0 && dataExists.checkIfUserAlreadyExists.tenants.length === 0) {
                        setUserCreated(dataExists.checkIfUserAlreadyExists._id);
                        changeModalTenantsOpen(true);
                        return;
                    }

                    // Add user to new tenant
                    if (tenantID && tenants.length > 0) {
                        const alreadyExistsOnThisTenant = dataExists.checkIfUserAlreadyExists.tenants.some((f) => f.tenantID === tenantID);

                        if (!alreadyExistsOnThisTenant) {
                            void adminExitingUser({
                                variables: {
                                    email,
                                    tenantID
                                }
                            }).then(({ errors }: any) =>
                            {
                                if (errors) {
                                    for (const e of errors) {
                                        toastError(t(`validations:${e.message}`));
                                    }
                                    return;
                                }

                                toastSuccess(t("validations:user.added.success"));

                                setIsLoading(false);
                                setIsModalOpen(false);
                                setEmail("");
                                onClose();
                            });

                            return;
                        }

                    }

                    // Already added before
                    setIsLoading(false);
                    setIsModalOpen(false);
                    setEmail("");
                    toastSuccess(t("validations:user.already.added"));

                } else {
                    void adminAddNewUser({
                        variables: {
                            email,
                            tenantID,
                            emailOptions: {
                                subject: t("createNewUser:step3.emailOptions.subject"),
                                html: t("createNewUser:step3.emailOptions.html"),
                                text: t("createNewUser:step3.emailOptions.text")
                            }
                        }
                    }).then(({ errors, data }: any) => {

                        if (errors) {
                            const alreadExists = errors.some((e) => e.message.includes("AddUser.ALREADY_EXISTS"));

                            if (tenantID && alreadExists) {
                                void Swal.fire({
                                    icon: "question",
                                    title: t("tenant:add.to.organization.label"),
                                    showConfirmButton: true,
                                    confirmButtonText: t("tenant:try.to.add.yes"),
                                    showCancelButton: true,
                                    cancelButtonText: t("tenant:try.to.add.no"),
                                    confirmButtonColor: COLORS.GREEN,
                                    cancelButtonColor: COLORS.RED
                                }).then((result) => {
                                    if (result.isConfirmed) {

                                        void adminExitingUser({
                                            variables: {
                                                email,
                                                tenantID
                                            }
                                        }).then(({ errors }: any) =>
                                        {
                                            if (errors) {
                                                for (const e of errors) {
                                                    toastError(t(`validations:${e.message}`));
                                                }
                                                return;
                                            }
                                            toastSuccess(t("validations:user.added.success"));

                                            setIsLoading(false);
                                            setIsModalOpen(false);
                                            setEmail("");
                                            onClose();
                                        });

                                    }
                                });

                                return;
                            }

                            for (const e of errors) {
                                toastError(t(`validations:${e.message}`));
                            }

                            setIsLoading(false);
                            return;
                        }

                        setIsLoading(false);

                        if (!tenantID && data && data.adminAddNewUser) {
                            setUserCreated(data.adminAddNewUser._id);
                            changeModalTenantsOpen(true);
                        } else {
                            setIsModalOpen(false);
                            setEmail("");
                        }

                        toastSuccess(t("validations:user.added.success"));
                        onClose();
                    });
                }
            });

        } else {
            toastError(t(`validations:${validateEmail(email)}`));
        }
    };

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    onClose()
                }}
                style={{ width: "600px" }}
            >
                <div>
                    <Title>
                        {t("profile:addUser.title")}
                    </Title>

                    <TextField
                        value={email}
                        autoFocus
                        style={{ marginTop: 20, width: "100%" }}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        label={t("profile:email.label")}
                    />

                    <ButtonListFlex>
                        <ConfirmButton onClick={onConfirm} disabled={isLoading || isEmpty(email)}>
                            {t("profile:addUser.button")}
                        </ConfirmButton>
                    </ButtonListFlex>
                </div>
            </Modal>

            {isModalTenatsOpen && isNotEmpty(userCreated) && (
                <ModalAddToTenant
                    tenants={tenants}
                    isModalTenatsOpen={isModalTenatsOpen}
                    userId={userCreated}
                    onClose={() => {
                        changeModalTenantsOpen(false);
                        onClose()
                    }}
                />
            )}
        </>
    );
}
