/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/forbid-component-props */
import { ChevronRight, DeleteOutline, TimelineOutlined as PolicyOperatorIcon, TextFieldsOutlined, TuneOutlined } from "@material-ui/icons";
import
    {
        BoolIcon, CustomMutationIcon, EnumIcon,
        IntIcon,
        InterfaceIcon,
        NodeRelationshipIcon,
        ObjectIcon, PolicyActionIcon, PolicyArrowIcon,
        PolicyResourceIcon,
        PolicySubjectIcon, PolicyVCIcon, PropertyIcon, ScriptIcon, TagIcon
    } from "assets";

import { MenuOptionsType } from "components/PrimGraphicalCanvas/types";
import { CanvasContext } from "contexts/canvasContext";
import { iContextMenuState } from "contexts/rightClickContext";
import React, { useContext, useState } from "react";
import { SContextMenu, SMenuItem, SMenuItemTitle, SNestedMenu, STitleBar } from "./styled";

export function RightClickMenu ({ title, menuOptions, x, y }: iContextMenuState): React.ReactElement {
    const nestedOpensToRight = x + 568 <= window.innerWidth;
    const [indexOfOpenedNestedMenu, setIndexOfOpenedNestedMenu] = useState(-1);

    const { data } = useContext(CanvasContext);

    menuOptions
        .filter((item) => item.key === "objectRightClickItemInterface")
        .map((item) => {
            // eslint-disable-next-line array-callback-return
            item.nestedMenuOptions = item.nestedMenuOptions.map((opt) => {
                let alreadyExists = false;

                data.implementLinks.forEach((il) => {
                    if (opt.key === `${il.source._id}-${il.target._id}`) {
                        alreadyExists = true;
                    }
                });

                if (!alreadyExists) {
                    return opt;
                }
            });

            return item;
        });

    const menuOptionsADD = menuOptions.filter((item) => item.type === MenuOptionsType.ADD)
    const menuOptionsDEL = menuOptions.filter((item) => item.type === MenuOptionsType.DEL)

    const HR = {
        key: null,
        title: null,
        image: null,
        onClick: null,
        nestedMenuOptions: [],
        type: MenuOptionsType.HR
    }

    let menuOptionsFiltered = []

    if (menuOptionsADD.length > 0) {
        menuOptionsFiltered = [...menuOptionsADD]
    }

    if (menuOptionsADD.length > 0 && menuOptionsDEL.length > 0) {
        menuOptionsFiltered = [...menuOptionsFiltered, HR]
    }

    menuOptionsFiltered = [...menuOptionsFiltered, ...menuOptionsDEL]

    const showIcon = (value, style = {}) =>
    {
        const newStyle = {
            width: 24,
            height: 24,
            margin: 16,
            ObjectFit: 'contain',
            ...style
        }

        switch (value)
        {
            case "Boolean":
            case "BoolIcon":
                return <img src={BoolIcon} style={newStyle} alt="" />
            case "PolicyResourceIcon":
                return <img src={PolicyResourceIcon} style={newStyle} alt="" />
            case "PolicySubjectIcon":
                return <img src={PolicySubjectIcon} style={newStyle} alt="" />
            case "PolicyVCIcon":
            case "PolicyHolderIcon":
            case "PolicyIssuerIcon":
            case "PolicyClaimIcon":
                return <img src={PolicyVCIcon} style={newStyle} alt="" />
            case "PolicyOperatorIcon":
                return <PolicyOperatorIcon style={newStyle} />
            case "PropertyIcon":
                return <img src={PropertyIcon} style={newStyle} alt="" />
            case "Object":
            case "ObjectIcon":
                return <img src={ObjectIcon} style={newStyle} alt="" />
            case "PolicyActionIcon":
                return <img src={PolicyActionIcon} style={newStyle} alt="" />
            case "PolicyArrowIcon":
                return <img src={PolicyArrowIcon} style={newStyle} alt="" />
            case "NodeRelationshipIcon":
                return <img src={NodeRelationshipIcon} style={newStyle} alt="" />
            case "CustomMutationIcon":
                return <img src={CustomMutationIcon} style={newStyle} alt="" />
            case "ScriptIcon":
                return <img src={ScriptIcon} style={newStyle} alt="" />
            case "TagIcon":
                return <img src={TagIcon} style={newStyle} alt="" />
            case "Float":
            case "Int":
            case "IntIcon":
                return <img src={IntIcon} style={newStyle} alt="" />
            case "InterfaceIcon":
                return <img src={InterfaceIcon} style={newStyle} alt="" />
            case "String":
            case "StringIcon":
                return <TextFieldsOutlined style={newStyle} />
            case "EnumIcon":
                return <img src={EnumIcon} style={newStyle} alt="" />
            case "FieldIcon":
                return <TuneOutlined style={newStyle} />
            case "ArrowIcon":
                return <ChevronRight style={newStyle} />
            case "DeleteOutline":
                return <DeleteOutline style={newStyle} />
            default:
                return ""
        }
    }

    return (
        <SContextMenu x={x + 250 <= window.innerWidth ? x : x - 250} y={y}>

            <STitleBar>
                {title}
            </STitleBar>

            {menuOptionsFiltered.map((menuOption, index) =>
            {
                const isDisabled = menuOption.nestedMenuOptions && menuOption.nestedMenuOptions.length === 0;

                if (menuOption.type === MenuOptionsType.HR)
                {
                    return <hr key="hr-submenu" style={{ border: '1px solid #E5E5E5' }} />
                }

                if (!isDisabled) {
                    return (
                        <SMenuItem
                            key={menuOption.key}
                            onClick={
                                menuOption.nestedMenuOptions
                                    ? () => {
                                        setIndexOfOpenedNestedMenu(index);
                                    }
                                    : menuOption.onClick
                            }
                            isDisabled={isDisabled}
                        >
                            {showIcon(menuOption.image, { opacity: isDisabled ? 0.1 : 1 })}

                            <SMenuItemTitle
                                style={{
                                    opacity: isDisabled
                                        ? 0.1
                                        : 1
                                }}
                            >
                                {menuOption.title}
                            </SMenuItemTitle>

                            {menuOption.nestedMenuOptions &&
                                showIcon("ArrowIcon", { opacity: isDisabled ? 0.1 : 1, margin: 0 })
                            }

                            {index === indexOfOpenedNestedMenu && !isDisabled && menuOption.nestedMenuOptions && (
                                <SNestedMenu opensToRight={nestedOpensToRight}>
                                    {menuOption.nestedMenuOptions.map((menuOpt) => (
                                        <SMenuItem
                                            key={menuOpt.key}
                                            onClick={menuOpt.onClick}
                                        >
                                            {showIcon(menuOpt.image)}

                                            <SMenuItemTitle isDisabled={isDisabled}>
                                                {menuOpt.title}
                                            </SMenuItemTitle>

                                            {menuOpt.nestedMenuOptions && showIcon("ArrowIcon", {}) }
                                        </SMenuItem>
                                    ))}
                                </SNestedMenu>
                            )}
                        </SMenuItem>
                    );
                }
            })}
        </SContextMenu>
    );
}
